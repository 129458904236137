import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Entity } from '@proman/services/entity.service';

@Injectable()
export class ParameterResolver implements Resolve<any> {

    constructor(
        @Inject(Entity) private Entity: Entity,
    ) {

    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        return this.Entity.get('parameter').get(
            Object.assign(
                {id: +route.params.parameterId, translate: true, join: ['parameterFunction', 'photo']},
                route.queryParams ? route.queryParams : {},
            )
        );
    }
}
