import { Component, Input, Output, Inject, EventEmitter, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { findByProperty, isDefinedNotNull } from '@proman/utils';
import { Money } from '@proman/interfaces/common.interface';
import { Currency, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { CurrenciesService } from '@proman/services/currencies.service';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getSystemOptions } from '@proman/store/system-options';
import { getCurrUser } from '@proman/store/curr-user';

@Component({
    selector: 'pm-price',
    template: `
        <pm-txt [config]="{ label: config.label, parseNumber: true, debounce: config.debounce, important: config.important }"
                [value]="amount"
                [disabled]="disabled"
                (onChange)="setAmount($event)"
                [attr.data-name]="config.label + '_' + amount"></pm-txt>
        <pro-select fxFlex="50px"
                [value]="currency"
                [options]="currencies"
                [config]="{ label: 'currency', disableSearch: true, displayKey: 'shorthand' }"
                (onChange)="setCurrency($event)"
                [disabled]="disabled || isSimpleVal || config.currencyDisabled"
                [attr.data-name]="attrDataName"></pro-select>
    `,
    styles: [
        ':host { display: inline-flex; }',
        'pm-txt { width: 100%; }'
    ]
})
export class PriceComponent implements OnChanges, OnInit {
    @Input() config: {
        label?: string;
        simpleVal?: boolean;
        absolute?: boolean;
        important?: boolean;
        currencyDisabled?: boolean;
        decimalPlaces?: number;
        debounce?: number;
    } = { label: '' };
    @Input() value: any;
    @Input() disabled: any;
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Output() onChange: EventEmitter<Money|string> = new EventEmitter<Money|string>();
    currencies: Currency[];
    currencyControl: UntypedFormControl = new UntypedFormControl();
    amount: string;
    currency: Currency;
    baseCurrency: Currency;
    systemOptions: SystemOptions;
    currUser: CurrUser;
    attrDataName: string;
    isSimpleVal: boolean;

    constructor(
        private store: Store,
        private Currencies: CurrenciesService,
    ) {
        this.baseCurrency = this.getCurrencyOption(this.Currencies.base);
        this.currencies = Currencies.currencies.map((item: Currency) => this.getCurrencyOption(item));
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        if (this.isSimpleVal) {
            this.value = this.value || '0';
            this.currency = this.baseCurrency;

        } else {
            this.value = this.value || { } as Money ;

            if (
                typeof this.value !== 'string' &&
                (isNaN(+this.value.amount) || !this.value.amount)
            ) {
                this.value = {
                    amount: '0',
                    currency: this.value && this.value.currency || this.baseCurrency.name
                };

            }

        }

        if (this.config.absolute) this.value.amount = Math.abs((+this.value.amount)).toString();

        if (this.disabled) this.control.disable();

        if (this.config.currencyDisabled || this.config.simpleVal || this.disabled) {
            // this.currencyControl.disable();

        }

        this.setLocalPrice(this.value);

        this.attrDataName = this.config.label + '_' + this.currency;
    }

    ngOnChanges(changes: SimpleChanges) {
        const value = changes.value;
        const disabled = changes.disabled;

        if (value && value.currentValue) {
            this.setLocalPrice(changes.value.currentValue);

        }

        if (disabled && disabled.currentValue !== disabled.previousValue) {

            (disabled.currentValue) ?
                this.control.disable() :
                this.control.enable();

        }

        this.checkisSimpleVal();

    }

    getDecimalLength(value: any) {
        let string = value && value.toString() || '';
        let parts = string.replace(/0*$/, '').split('.');

        if (parts[1]) {
            return Math.min(parts[1].length, this.config.decimalPlaces || this.systemOptions.getDefaultDecimalPlaces());

        } else {
            return 0;

        }

    }

    setAmount(value: string|null) {
        if (!value && !this.value) {
            value = '0';
        }

        if (value) {
            if (this.config.absolute) value = Math.abs((+value)).toString();
            this.amount = value.toString().replace(/,/g, '.');

            if (this.currency && this.amount.length || this.isSimpleVal) {
                this.emitUpdate();

            }
        }

    }

    setCurrency(value: any) {
        this.currency = value;

        this.emitUpdate();
    }

    emitUpdate() {

        if (isDefinedNotNull(this.amount) && this.currency) {

            const updatedVal = this.isSimpleVal ?
                this.amount.toString() :
                { amount: this.amount.toString(), currency: this.currency.name };

            this.onChange.emit(updatedVal);

        }

    }

    setLocalPrice(price: any) {
        this.control.enable();

        if (isDefinedNotNull(price)) {

            if (this.isSimpleVal) {
                price = price.toString().replace(/,/g, '.');
                this.amount = parseFloat(price).toFixed(this.getDecimalLength(price));
                const defaultCurrency = this.currUser.defaultCurrency || this.baseCurrency.name;
                this.currency = findByProperty(this.currencies, 'name', defaultCurrency);
            } else {
                price.amount = price.amount && price.amount.toString().replace(/,/g, '.');
                this.amount = parseFloat(price.amount).toFixed(this.getDecimalLength(price.amount));
                this.currency = findByProperty(this.currencies, 'name', price.currency);
            }

        } else {
            this.amount = null;
            this.currency = null;

        }

        this.control.setValue(this.amount, { emitEvent: false });
        if (this.disabled) this.control.disable();

    }

    getCurrencyOption(item: Currency) {
        return Object.assign({}, item);
    }

    checkisSimpleVal() {
        this.isSimpleVal = this.config.simpleVal || typeof this.value === 'string';
    }

}
