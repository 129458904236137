import { Injectable } from '@angular/core';
import { Entity, EntityNameType } from './entity.service';
import { Store } from '@ngrx/store';
import { getStoredData, loadStorableData } from '../cached-requests/store';
import { filter, take } from '@proman/rxjs-common';
import { deepCopy } from '../utils';

declare interface ParameterOptionsSearchParams {
    entity: EntityNameType;
    entityParams?: unknown;
    emptyResponse?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ParametersOptionsService {
    options: any = [];
    queries: any = [];

    constructor(
        private Entity: Entity,
        private store: Store,
    ) {

    }

    search<T = any>(
        requestParams: ParameterOptionsSearchParams): Promise<T[]> {

        requestParams.entityParams = requestParams.entityParams || {};

        let entity = requestParams.entity;

        this.store.dispatch(loadStorableData({ payload: { entity, entityParams: requestParams.entityParams } }));

        return new Promise((resolve) => {
            this.store.select(getStoredData, `${entity}|${JSON.stringify(requestParams.entityParams)}`)
                .pipe(filter((value) => !!value), take(1))
                .subscribe((values) => {
                    resolve(deepCopy(values) as T[]);
                });
        });

    }

    get<T = any>(requestParams: ParameterOptionsSearchParams): Promise<T> {
        return this.search(requestParams).then((response: T[]) => response[0]);
    }

}
