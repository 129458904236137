import { Component, Input, ElementRef, ViewChild, OnChanges, ChangeDetectorRef, OnInit } from '@angular/core';

@Component({
    selector: 'pro-inline-list',
    template: `
        <div #element class="InlineList" tabindex="1">
            <pro-text-simple *ngIf="data.isSearch"
                    [config]="{ label: 'search', autofocus: true, debounce: 0 }"
                    [value]="query"
                    (onChange)="setQuery($event)"></pro-text-simple>
<!--            <pm-progress-bar *ngIf="data.isLoading" style="display: block; width: 100%; min-width: 126px;"></pm-progress-bar>-->
            <div *ngFor="let group of data.items">
                <div class="InlineList-group"
                     *ngIf="group.name">
                    {{ group.name }}
                </div>
                <div class="InlineList-item"
                     *ngFor="let item of group.items | proFilter:query; let $index = index;"
                     (click)="select(item, $index, $event)">
                    {{ item.name }}
                </div>
            </div>
        </div>
    `,
    styles: [`
        .InlineList {
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            position: absolute;
            padding-bottom: 16px;
            display: block;
            z-index: 10002;
            background: #ffffff;
            max-height: 30rem;
            overflow: hidden;
            overflow-y: auto;
        }

        .InlineList pro-text-simple .mat-input-flex {
            padding-left: 20px;
        }

        .InlineList pro-text-simple .mat-input-flex .mat-form-field-label, input {
            text-indent: 16px;
        }

        .InlineList-group, .InlineList-item {
            line-height: 18px;
            padding: 10px 16px 10px 16px;
        }

        .InlineList-item {
            cursor: pointer;
        }

        .InlineList-item:hover {
            background-color: rgb(238, 238, 238);
        }

        .InlineList-group {
            font-weight: 500;
        }
    `]
})

export class InlineListComponent implements OnInit, OnChanges {
    @ViewChild('element', { static: true }) element: ElementRef;
    @Input() data: any;
    query: any;

    constructor(
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.setPosition();
    }

    ngOnChanges() {
        this.cd.markForCheck();
    }

    setPosition() {
        let top = this.data.event.pageY - this.element.nativeElement.offsetHeight / 2;
        let left = this.data.event.pageX - this.element.nativeElement.offsetWidth / 2;
        let scrollTop = window.pageYOffset;

        if (top < scrollTop) {
            top = scrollTop;

        } else if (window.innerHeight + scrollTop < top + this.element.nativeElement.offsetHeight) {
            top = window.innerHeight + scrollTop - this.element.nativeElement.offsetHeight;

        }

        this.element.nativeElement.style.top = top + 'px';
        this.element.nativeElement.style.left = left + 'px';
    }

    select(item: any, $index: any, $event: any) {

        if (!item.groupHeader) {
            // callback with selected item, selected item removal fn, selected $index and $event
            this.data.onSelect(item, () => this.data.items.splice($index, 1), $index, $event);

            if (this.data.closeOnSelect) {
                this.data.close($event);

            }

            if (this.data.closeOnEmpty && this.data.items.length === 0) {
                this.data.close($event);

            }

        }

    }

    setQuery(value: any) {
        this.query = value;
    }
}
