import { Component, Input, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'pm-article-comment-status',
    template: `
        <pro-btn
                (click)="update()"
                icon="check-circle"
                [theme]="value ? 'accent' : 'primary'"
                [tooltip]="(value ? 'confirmed' : 'unconfirmed') | translate"
                proClickStopPropagation></pro-btn>
    `
})

export class ArticleCommentStatusComponent implements OnInit {
    @Input() item: any;
    @Input() config: any;
    value: boolean;

    constructor(private Entity: Entity) {}

    ngOnInit() {
        this.value = this.item[this.config.key];
    }

    update() {
        let data: any = { id: this.item.id };
        this.value = !this.value;

        data[this.config.key] = this.value;
        this.item[this.config.key] = this.value;

        return this.Entity.get('article_operation_production_comment')
            .update(data);
    }

}
