import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[pmNoPadding]'
})

export class NoPaddingDirective {
    constructor(el: ElementRef, renderer: Renderer2) {
        renderer.addClass(el.nativeElement, 'NoPadding');
    }
}
