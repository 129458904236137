import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proQuantFormat' })

export class QuantFormatPipe implements PipeTransform {

    constructor(private Filter: FilterService) {}

    transform(value: any) {
        return this.Filter.quantFormat(value);
    }
}
