import moment from 'moment';
import { utcFormat, isNotUtc } from '@proman/utils';
import { Injectable } from "@angular/core";

const AND = '&';
const OR = '|';
const EQ = '=';
const NULL = '-';
const NOT = '!';
const LTE = '<=';
const LT = '<';
const GTE = '>=';
const GT = '>';

/**
 * `QueryExpression` service
 *
 */
@Injectable()
export class QueryExpressionService {

    orStrict(args: any) {
        if (!args) return;

        return args.map((element: any) => EQ + element).join(OR);
    };

    or(args: any) {
        if (!args) return;

        return args.join(OR);
    };

    eqStrict(arg: any) {
        return EQ + arg;
    };

    notEqStrict(arg: any) {
        return NOT + EQ + arg;
    };

    // sets "from" to start of day, sets "to" to end of day
    dateRange(from: any, to: any, inclusive?: boolean) {
        // convert dates to moment
        if (!from || !from.format) from = moment(from);
        if (!to || !to.format) to = moment(to);
        return this.from(utcFormat(from.startOf('day')), inclusive) + AND + this.to(utcFormat(to.endOf('day')), inclusive);
    };

    // Formats exact datetime values without modification
    dateTimeRange(from: any, to: any, inclusive?: any) {
        let output = '';
        let prefix = '';

        if (from) {
            output += this.from(from, inclusive);
            prefix = AND;
        }

        if (to) {
            output += prefix + this.to(to, inclusive);
        }

        return output;
    };

    from(from: any, inclusive?: boolean) {
        let fromOp;

        if (typeof inclusive === 'undefined') inclusive = true;
        if (isNotUtc(from)) from = utcFormat(moment(from));
        if (typeof from !== 'string' && from.format) from = utcFormat(from);

        fromOp = inclusive ? GTE : GT;

        return fromOp + from;
    };

    to(to: any, inclusive?: any) {

        if (typeof inclusive === 'undefined') inclusive = true;
        if (isNotUtc(to)) to = utcFormat(moment(to))
        if (typeof to !== 'string' && to?.format) to = utcFormat(to);

        return (inclusive ? LTE : LT) + to;
    };

    parseDateRange(expr: any) {
        let parts = expr.split(AND);
        let opRegex = new RegExp('^[' + LT + GT + EQ + ']{1,2}');

        if (parts.length === 2) {
            let from = parts[0].replace(opRegex, '');
            let to = parts[1].replace(opRegex, '');

            return { from, to };

        } else {
            let date = parts[0].replace(opRegex, '');

            if (parts[0].indexOf(GT) > -1) {
                return { from: date }
            } else {
                return { to: date }
            }
        }
    };

    notIn(args: any[]) {
        return args.length ? NOT + args.map((arg: any) => arg).join(OR) : '';
    }

    notInStrict(args: any[]) {
        return args.length ? args.map((arg: any) => NOT + EQ + arg).join(AND) : '';
    };

    in(args: any[]) {
        return args?.length ? args.map(String).join(OR) : '';
    };

    notOrNull(arg: any) {
        return NOT + arg + OR + NULL;
    };

    orNull(arg: any) {
        return arg + OR + NULL;
    };

    notNull() {
        return NOT + NULL;
    };

    isNull() {
        return NULL;
    };
}
