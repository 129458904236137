import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { DialogTitleComponent } from '@proman/common-components/standalone/dialog-title.component';

@Component({
    selector: 'pro-video-preview-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogTitleComponent,
        MatLegacyDialogModule,
    ],
    template: `
        <pro-dialog-title title="video_preview"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <video [autoplay]="true"
                   [loop]="true"
                   [muted]="'muted'"
                   preload="auto"
                   controls
                   #video>
                <source [src]="videoSrc"
                        type="video/mp4">
            </video>
        </div>
    `,
    styles: [`
        video {
            outline: 2px solid #333;
        }

    `]
})

export class VideoPreviewDialogComponent {
    videoSrc: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { src: string },
        private dialogRef: MatLegacyDialogRef<VideoPreviewDialogComponent>,
    ) {
        this.videoSrc = this.data.src;
    }

}
