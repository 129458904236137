import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { prepareRequest } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';

@Component({
    selector: 'pm-entity-remove-dialog',
    template: `
        <pro-dialog-title title="action_approval"></pro-dialog-title>
        <div mat-dialog-content>
            <p [innerHTML]="label"></p>
            <p *ngIf="description"
               [innerHTML]="description"></p>
            <pro-parameter *ngFor="let parameter of parameters"
                          [parameter]="parameter"
                          [config]="getParameterConfig(parameter)"
                          (onChange)="setParameter(parameter, $event)"></pro-parameter>
        </div>
        <div mat-dialog-actions>
            <pro-btn (click)="confirm()"
                    [disabled]="isCallbackDisabled"

                    pattern="remove"></pro-btn>
            <pro-btn (click)="dialogRef.close()"
                    [pattern]="'cancel'"></pro-btn>
        </div>
    `
})

export class EntityRemoveDialogComponent {
    parameters: any;
    Entity: any;
    label: any;
    description: any;
    isCallbackDisabled: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Filter: FilterService,
        Entity: Entity,
        public dialogRef: MatLegacyDialogRef<EntityRemoveDialogComponent>
    ) {

        this.label = this.getLabel();

        if (this.data.entityConfig) {
            this.Entity = Entity.get(this.data.entityConfig);

        } else if (this.data.entity) {
            this.Entity = Entity.get({ name: this.data.entity });

        }

        if (this.data.getDescription) {
            this.data
                .getDescription(this.data.item)
                .then((response: any) => {
                    this.description = response;
                });

        }

        if (this.data.getParameters) {
            this.data
                .getParameters(this.data.item)
                .then((response: any) => {
                    this.parameters = response;
                });

        } else if (this.data.parameters) {
            this.parameters = this.data.parameters;

        }

    }

    getLabel() {

        if (this.data.getLabel) {
            return this.data.getLabel(this.data.item);

        } else if (this.data.item && this.data.item.name) {
            return this.Filter.translate('delete') + ' ' + this.data.item.name + '?';

        } else if (this.data.item && !this.data.item.name) {
            return this.Filter.translate('confirm_delete_record');

        }

    }

    setParameter(parameter: any, value: any) {
        parameter.value = value;
    }

    getParameterConfig(parameter: any) {

        if (typeof parameter.config === 'function') {
            return parameter.config(this.data.item);

        }

        return parameter.config;
    }

    confirm() {
        let method = this.data.method ? this.data.method : (this.data.isAssoc ? 'removeAssociation' : 'remove');
        let data: any = {};
        let ids = [];
        let promise;

        if (Array.isArray(this.data.item)) {

            for (let item of this.data.item) {
                ids.push(item.id);

            }

        } else if (this.data.item) {
            ids = this.data.item.id;

        }

        data.id = ids;

        if (this.parameters) {

            for (let parameter of this.parameters) {
                data[parameter.key] = parameter.value;

            }

        }

        if (this.data.requestParams) {
            data = Object.assign(data, this.data.requestParams);

        }

        if (this.Entity) {

            if (this.data.parent) {
                promise = this.Entity.postItem(this.data.parent, 'remove', prepareRequest(data));

            } else {
                promise = this.Entity[method](prepareRequest(data));

            }

        } else if (this.data.handleRemove)  {
            promise = this.data.handleRemove(data);

        }

        this.isCallbackDisabled = true;

        promise.then(() => {

            this.isCallbackDisabled = false;

            if (this.data.callback) {
                this.data.callback();

            }

            this.dialogRef.close(1);
        });
    }

}
