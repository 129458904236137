import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { WsUpdatesService } from '../services/ws-updates.service';

@Directive({
    selector: '[pmWsUpdate]'
})

export class WsUpdateDirective implements OnInit, OnDestroy {
    @Input('pmWsUpdate') entity: any;
    @Input('entityName') entityName: string;

    constructor(
        private WsUpdate: WsUpdatesService,
        private cd: ChangeDetectorRef,
    ) {
        this.WsUpdate.updateTimeStamp.subscribe(() => this.cd.markForCheck());
    }

    ngOnInit() {
        this.WsUpdate.initEntity(this.entity, this.entityName);
    }

    ngOnDestroy() {
        this.WsUpdate.dropEntity(this.entity, this.entityName);
    }

}
