import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { CONFIG } from '@proman/config';
import { PreferencesService } from '@proman/services/preferences.service';
import { ImagePathService } from '@proman/services/image-path.service';
import { ToastService } from '@proman/services/toast.service';

import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/image_manager.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';

// import 'froala-editor/css/plugins/image.min.css';
// import 'froala-editor/css/plugins/image_manager.min.css';
// import 'froala-editor/css/plugins/table.min.css';
// import 'froala-editor/css/plugins/code_view.css';

import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { UI_DARK_MODE, UiPreferencesService } from '@proman/services/ui-preferences.service';

@Component({
    selector: 'pm-html-txt',
    template: `
        <div>
            <h3 class="Label">{{ config?.label | translate }}</h3>
            <div [froalaEditor]="options"
                 [(froalaModel)]="tmpValue"
                 (froalaModelChange)="handleChange($event)"
                 (froalaInit)="init($event)"></div>

        </div>
    `,
    styles: [ ':host { width: 100%; }'],
})

export class HtmlTxtComponent implements OnInit, OnChanges {
    @Input() value: string;
    @Input() config: any = {};
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    tags: string[] = [
        'p', 'b', 'strong', 'em', 'span', 'a',
        'u', 'br', 'sup', 'sub', 'label', 'i',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'html', 'head', 'body', 'header', 'footer', 'meta', 'style', 'script',
        'div', 'img', 'table', 'tr', 'td', 'tbody', 'iframe',
        'input', 'form', 'select', 'option', 'ul', 'ol', 'li',
        'button', 'code', 'audio', 'col', 'dd', 'details'
    ];
    options: any;
    tmpValue: string;

    constructor(
        private Toast: ToastService,
        private Prefs: PreferencesService,
        private UiPrefs: UiPreferencesService,
        private ImagePath: ImagePathService,
        private LanguageConfig: LanguageConfigService,
        private Cookies: CookiesService,
        private cd: ChangeDetectorRef,
    ) {
        let token = this.Cookies.get(COOKIE_AUTHORIZATION);
        let darkTheme = this.UiPrefs.get(UI_DARK_MODE);

        /* eslint-disable @typescript-eslint/no-this-alias */
        const app = this;
        /* eslint-enable @typescript-eslint/no-this-alias */

        this.options = {
            charCounterCount: false,
            htmlAllowComments: true,
            htmlRemoveTags: [],
            // key: 'xGE6oA4B3C3C11dNSWXf1h1MDb1CF1PLPFf1C1EESFKVlA3C11B8A4D2C4F4G2C3G3==', // v3 key
            // key: 'bMA6aA6A5A2B2C1G2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2C6D6A3A2E4C2A2==', // v3 NEW key
            // Version 3.x & 4.x
        // Versions >= 2.8.0jD2J2A5D4D4C2D2vA1E1I1C2B8C7E7E1E5B4iYRUFVRf1XPLEb1IFd1==
        // Versions < 2.8.0jLAHYKAJOEh1HQDUH==
        //     key: 'XAG4eH3J3A8C9C7D6C-11VKOJ1FGULVKHXDXNDXc1d1Kg1SNdD5B4A4C3H3I3G3B7A4D3==', // v4? key email says v3
            key: 'jUA1yC3A3D1A1G2B1F1qYFa1UQRFQIVc2MSMd1IWPNb1IFd1yD2I2D1C3C7E2E1C5G1A1==', // v3 and v4  - 2022.03.17
            iconsTemplate: 'font_awesome_5r',
            typingTimer: 1000,
            // toolbarButtonsSM: ['undo', 'redo' , 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'insertImage', 'insertTable', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'emoticons', 'print'],
            toolbarButtonsMD: ['undo', 'redo' , 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'insertImage', 'insertTable', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'emoticons', 'clearFormatting', 'print', 'insertLink'],
            toolbarButtons: ['undo', 'redo' , 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'insertImage', 'insertTable', 'html', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'emoticons', 'clearFormatting', 'insertLink'],
            // toolbarButtons: ['undo', 'redo' , 'bold',  'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'html', 'print'],
            // toolbarButtons: ['undo', 'insertImage', 'insertTable', 'html',],
            htmlAllowedTags: this.tags,
            htmlAllowedEmptyTags: this.tags,
            // htmlRemoveTags: ['script'],
            htmlUntouched: true,
            tableResizer: true,
            wordPasteModal: false,
            toolbarBottom: true,
            tableDefaultWidth: null,
            theme: darkTheme ? 'dark' : undefined,
            events: {
                'image.uploaded'(response: any) {

                    function parseResponse(resp: any) {
                        let file = resp.data[0];
                        return app.ImagePath.getFile(file);
                    }

                    let url = `${parseResponse(JSON.parse(response))}`;

                    this.image.insert(url, false, null, this.image.get());

                    return false;

                },
                'image.inserted': (e: Event, editor: any, $img: any, response: any) => {},
                'image.replaced': (e: Event, editor: any, $img: any, response: any) => {},
                'image.error': (error: any, response: any) => {
                    this.Toast.pop('error', error.message);
                }

            },
            // Set the image upload parameter.
            imageUploadParam: 'files[]',

            imageUploadURL: CONFIG.uploadUrl,

            // Additional upload params.
            imageUploadParams: { type: 'misc' },

            // Allow to upload PNG and JPG.
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        };

        this.options.requestHeaders = { Authorization: 'Bearer ' + token };
    }

    ngOnInit() {
        this.tmpValue = this.value;

        this.options.language = this.LanguageConfig.currentLanguage;

    }

    ngOnChanges(changes: SimpleChanges) {
        let value = changes.value;

        if (value) this.tmpValue = this.value;
    }

    handleChange(value: any) {

        function quoteSaver(value: string) {
            return value.split('&#39;').join('\'');
        }

        function ampersandSaver(value: string) {
          return value.replace('&amp;', '&');
        }

        const result = quoteSaver(ampersandSaver(value));

        this.onChange.emit(result);
    }

    init(data: any) {
        data.initialize();
    }

}
