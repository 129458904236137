import { Injectable } from '@angular/core';
import { Entity, EntityInterface, EntityNameType } from './entity.service';
import { QueryExpressionService } from './query-expression.service';
import { DynamicField } from '../interfaces/entity-interfaces';
import { asyncForEach, isDefinedNotNull, omit } from '../utils';

export const DYNAMIC_FIELDS_TABLES = {
    order: 'orders',
    production: 'production',
    sale_opportunity: 'sale_opportunity_project',
    customer: 'customers',
    material: 'materials',
    order_proposal: 'order_proposals',
    employee: 'employee',
    sale_event: 'sale_events',
    supplier: 'suppliers',
    consumer_booking: 'consumer_booking',
    customer_employee: 'customer_employees',
    development_project: 'development_projects',
    system_options: 'system_options',
    invoice: 'invoices',
    product: 'products',
    article: 'articles',
    shipment: 'shipments',
    shipment_container: 'shipment_containers',
    purchase: 'purchase',
    carrier: 'carriers',
    article_test: 'article_tests',
};

@Injectable({ providedIn: 'root' })
export class DynamicFieldsService {

    constructor(
        private Entity: Entity,
        private QueryExpression: QueryExpressionService,
    ) {

    }

    getFieldsForEntity(entity: EntityNameType, requestOptions: any = {}) {
        if (!DYNAMIC_FIELDS_TABLES[entity]) return Promise.resolve([]);
        return this.Entity.get('dynamic_field')
            .search(Object.assign({ tableName: '='+DYNAMIC_FIELDS_TABLES[entity], sort: { position: 'asc' } }, requestOptions));
    }

    createFieldValue(request: {
        entityId: number;
        dynamicField: number;
        value: any;
    }) {
        return this.Entity.get('dynamic_field_value')
            .create(request);
    }

    copyFields(entity: EntityNameType, from: number, to: number): Promise<void> {
        return new Promise((resolve) => {
            this.getFieldsForEntity(entity, { 'entityId': from })
                .then(async (response: DynamicField[]) => {

                    await asyncForEach(response, async (field: DynamicField) => {
                        await this.Entity.get('dynamic_field_value')
                            .get({ entityId: from, join: ['dynamicField'], 'dynamicField.id': field.id })
                            .then((fieldToCopy) => {

                                if (fieldToCopy) {
                                    let value;
                                    const fieldValues = omit(fieldToCopy, ['id', 'validatorHasErrors', 'validatorErrors', 'entityId', 'dynamicField']);

                                    for (let key of Object.keys(fieldValues)) {
                                        if (isDefinedNotNull(fieldValues[key])) {
                                            value = fieldValues[key];
                                        }
                                    }

                                    this.createFieldValue({ entityId: to, dynamicField: field.id, value });
                                }
                            });

                    });

                    resolve();

                });

        });

    }

}
