import { Pipe, PipeTransform } from '@angular/core';
import { treePadding } from '../../../utils';

@Pipe({ name: 'proTreePadding' })

export class TreePaddingPipe implements PipeTransform {

    transform(str: any, level: any, spacer?: any) {
        return treePadding(level || 0, spacer) + str;
    }
}
