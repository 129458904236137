import { NgModule } from '@angular/core';
import { PromanTableModule } from '@proman/table';
import { TagsComponent } from '@proman/tags/tags.component';
import { CountryRegionsComponent } from '@frontend/shared/components/country-regions.component';
import { OrderProductShipmentCellComponent } from '../orders/components/order-product-shipment-cell.component';
import { ClickToCallComponent } from '@frontend/shared/components/click-to-call.component';
import { ArticleCommentStatusComponent } from '../articles/components/article-comment-status.component';
import { ShipmentProviderCalculateCellComponent } from '@frontend/orders/components/shipment-provider-calculate-cell.component';
import { FrontendTableStateParserService } from './frontend-table-state-parser.service';
import { TABLE_STATE_PARSER } from '@proman/table/constants';
import { FRONTEND_ENTITY_TABLE_UI_PREFS_KEY } from '../constants';
import { GlobalOverlayModule } from '@proman/overlay/global-overlay.module';
import { TableCellOverlayComponent } from './table-cell-overlay.component';
import { CommonModule } from '@angular/common';
import { Fa6Module } from '@proman/fa/fa6.module';
import { ParameterViewComponentModule } from '../parameters/parameter-view-component.module';
import { ConfigFormFieldComponent } from '@frontend/shared/components/config-form-field.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        Fa6Module,
        GlobalOverlayModule,
        ParameterViewComponentModule,
        PipesModule,
        PromanTableModule.forRoot({
              directives: {
                  'pro-tags': TagsComponent,
                  'pm-country-regions': CountryRegionsComponent,
                  'pm-order-product-shipments-cell': OrderProductShipmentCellComponent,
                  'pm-click-to-call': ClickToCallComponent,
                  'pm-article-comment-status': ArticleCommentStatusComponent,
                  'pm-config-form-field': ConfigFormFieldComponent,
                  'pm-shipment-provider-calculate-cell': ShipmentProviderCalculateCellComponent,
                  'pm-table-cell-overlay': TableCellOverlayComponent,
              },
              configuration: {
                  lazyLoadIncrements: 100,
                  storagePrefix: FRONTEND_ENTITY_TABLE_UI_PREFS_KEY,
              },
          }),
    ],
  providers: [
    { provide: TABLE_STATE_PARSER, useClass: FrontendTableStateParserService }
  ],
  declarations: [
    TableCellOverlayComponent,
  ],
  exports: [
    PromanTableModule,
  ]
})

export class FrontendTableModule {}
