import {
    AfterViewInit,
    Directive,
    ElementRef, Input,
} from '@angular/core';

@Directive({
    selector: '[proScrollInto]'
})
export class ScrollIntoViewDirective implements AfterViewInit {
    @Input('proScrollInto') timeout: number = 50;
    constructor(private el: ElementRef) {

    }

    ngAfterViewInit() {
        setTimeout(() => this.el.nativeElement.scrollIntoView({ behavior: 'smooth' }), this.timeout); // timeout to allow render components
    }

}
