import { createAction, props } from '@ngrx/store';
import { EntityNameType } from '../../services/entity.service';

export enum ParametersActions {
    LoadData = `[Parameters] LoadData`,
    SetData = `[Parameters] SetData`,
}

export const loadStorableData = createAction(
    ParametersActions.LoadData,
    props<{ payload: { entity: EntityNameType; entityParams?: unknown  } }>()
);

export const setStorableData = createAction(
    ParametersActions.SetData,
    props<{ payload: { key: string; data: unknown } }>()
);
