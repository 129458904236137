import { of as observableOf, timer as observableTimer } from '@proman/rxjs-common';
import { switchMap } from '@proman/rxjs-common';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { EntityInterface, EntityNameType } from '../services/entity.service';
import { isDefined } from '../utils';
import { ToastService } from '../services/toast.service';
import { RequestService } from '../services/request.service';
import { CONFIG } from '../config';

export interface UniqueNameEntityInterface extends EntityInterface {
    check: (data: { input: string; resource: EntityNameType; fieldName: string; identifiers: unknown }) => Promise<{ data: { unique: boolean; suggestion: string } }>;
}

export function UniqueValidator(
    UniqueName: UniqueNameEntityInterface,
    config: { resource: EntityNameType; field: string; onlyWarning: boolean; data: unknown },
    ToastService: ToastService,
    savedConfig: { [key: string]: { isUnique: boolean; suggestion: string } }
): AsyncValidatorFn {

    return (control: AbstractControl) => {
        return observableTimer(150).pipe(switchMap(() => {

            const resource = config.resource;
            const field = config.field;
            const value = control.value;

            if (!control.dirty) {
                return observableOf(null);

            }

            const key = `${resource}_${field}_${value}`;

            if (isDefined(savedConfig[key])) {
                return observableOf(savedConfig[key]);

            } else {
                return UniqueName
                    .check({ input: control.value, resource: config.resource, fieldName: config.field, identifiers: config.data || [] })
                    .then((response) => {
                        const value = response?.data?.unique ?
                            null :
                            { isUnique: true, suggestion: response?.data?.suggestion };

                        savedConfig[key] = value;

                        if (value && config.onlyWarning) {
                            ToastService.pop('error', 'duplicate_value');
                            return null;
                        }

                        return value;

                    });

            }

        }));
    }
}

export function UniqueValidatorPublic(
    UniqueName: UniqueNameEntityInterface,
    config: { resource: EntityNameType; field: string; onlyWarning: boolean; data: unknown },
    ToastService: ToastService,
    savedConfig: { [key: string]: { isUnique: boolean; suggestion: string } },
    Request: RequestService
): AsyncValidatorFn {

    return (control: AbstractControl) => {
        return observableTimer(150).pipe(switchMap(() => {

            const resource = config.resource;
            const field = config.field;
            const value = control.value;

            if (!control.dirty) {
                return observableOf(null);

            }

            const key = `${resource}_${field}_${value}`;

            if (isDefined(savedConfig[key])) {
                return observableOf(savedConfig[key]);

            } else {
                return Request
                    .post(`${CONFIG.root}api/public/unique_name/check`, { input: control.value, resource: config.resource, fieldName: config.field, identifiers: config.data || [] })
                    .then((response) => {
                        const value = response.data.unique ?
                            null :
                            { isUnique: true, suggestion: response.data.suggestion };

                        savedConfig[key] = value;

                        if (value && config.onlyWarning) {
                            ToastService.pop('error', 'duplicate_value');
                            return null;
                        }

                        return value;

                    });

            }

        }));
    }
}
