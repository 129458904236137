import { Pipe, PipeTransform, Inject } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proPrice', pure: false })

export class PricePipe implements PipeTransform {

    constructor(
        private Filter: FilterService,
    ) {

    }

    transform(value: any, decimal?: number): string {
        return this.Filter.price(value, decimal);
    }
}
