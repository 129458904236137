import { Injectable } from '@angular/core';
import { Entity, EntityNameType } from './entity.service';
import { UniqueValidator, UniqueValidatorPublic } from '@proman/validators/unique.validator';
import { ToastService } from './toast.service';
import { RequestService } from './request.service';

@Injectable()
export class UniqueValidatorService {
    entityInstance: any;

    constructor(
        Entity: Entity,
        private ToastService: ToastService,
        private Request: RequestService
    ) {
        this.entityInstance = Entity.get({ name: 'unique_name', post: ['check'] });
    }

    get(resource: EntityNameType, field: string = 'name', onlyWarning: boolean = false, data?: unknown) {
        return UniqueValidator(this.entityInstance, { resource, field, onlyWarning, data }, this.ToastService, {});
    }

    getPublic(resource: EntityNameType, field: string = 'name', onlyWarning: boolean = false, data?: unknown) {
        return UniqueValidatorPublic(this.entityInstance, { resource, field, onlyWarning, data }, this.ToastService, {}, this.Request);
    }
}
