import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pro-confirm-action-dialog',
    template: `
        <pro-dialog-title [title]="data.header || 'action_approval'"></pro-dialog-title>
        <div mat-dialog-content>
            <p>{{ data.question | translate }}</p>
<!--            <pro-parameter *ngFor="let parameter of data.parameters"-->
<!--                          [parameter]="parameter"-->
<!--                          [config]="parameter.config"-->
<!--                          (onChange)="set(parameter, $event)"></pro-parameter>-->
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class ConfirmActionDialogComponent {

    constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any, public dialogRef: MatLegacyDialogRef<ConfirmActionDialogComponent>) {}

    confirm() {

        if (this.data.parameters) {
            const data = this.data.parameters.reduce((accumulator: any, item: any) => {
                accumulator[item.key] = item.value;

                return accumulator;
            }, {});

            this.dialogRef.close(data);
        } else {
            this.dialogRef.close(1);

        }

    }

    set(parameter: any, value: any) {
        parameter.value = value;
    }
}
