import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { OPERATORS_TYPES } from '@proman/utils/expressions_config';

@Component({
    selector: 'pro-expression-simple',
    template: `
        <div>
            <h4>
                <span>
                    {{ 'expression' | translate }}
                </span>
                @for (condition of conditions; track $index) {
                    <span>
                        @if (condition.config?.type === 'parameter') {
                            <span>{{ condition.value?.name }}</span>
                        } @else {
                            <span>{{ condition.value }}</span>
                        }
                </span>
                }
            </h4>
            @for (condition of conditions; track $index; let last = $last) {
                <div fxLayout="row"
                     fxLayoutAlign="start center">

                    @if (condition.value && !condition.isEditMode) {
                        <pro-btn (onClick)="edit(condition)"
                                 icon="edit"
                                 theme="primary"></pro-btn>
                    }
                    
                    @if (condition.value && condition.isEditMode) {
                        <pro-btn (onClick)="confirmEdit(condition)"
                                 icon="check-circle"
                                 theme="primary"></pro-btn>
                        <pro-btn (onClick)="deleteConditionItem(conditions, $index)"
                                 icon="trash"
                                 theme="warn"></pro-btn>
                    }
                    
                    @if (!condition.value || condition.isEditMode) {
                        <pro-select [ngClass]="{ 'width-75' : condition.config?.type === 'numeric' }"
                                    [value]="condition.config"
                                    [config]="{ label: 'type', isNone: true }"
                                    [options]="types"
                                    (onChange)="resetValue(condition, $event)"></pro-select>
                    }
                    
                    @switch (condition.config?.type) {
                        @case ('numeric') {
                            <pro-text-simple [value]="condition.value"
                                             [config]="{}"
                                             (onChange)="set(condition, $event)"
                                             class="width-50"></pro-text-simple>
                        }
                        
                        @case ('parameter') {
                            <pro-select [value]="condition.value"
                                        [config]="{ label: 'value', groupBy: 'context' }"
                                        (onChange)="set(condition, $event)"
                                        [options]="parameterOptions"></pro-select>
                        }
                        
                        @case ('operator') {
                            <pro-select [value]="condition.value"
                                        [config]="{ label: 'operator', key: 'id' }"
                                        [options]="operatorsTypes"
                                        (onChange)="set(condition, $event)"></pro-select>
                        }
                    }
                    
                    @if (last && condition.value) {
                        <pro-btn (onClick)="addNewCondition()"
                                 icon="plus"
                                 theme="accent"></pro-btn>
                    }
                </div>
            }
            <small>
                {{ previewValue }}
            </small>
            <div>
                @if (conditions[0].value) {
                    <pro-btn (onClick)="addExpression()"
                             [label]="'add_expression' | translate"></pro-btn>
                }
            </div>
        </div>

    `,
    styles: [`
        .width-75 { width: 75% }
        pro-select { min-width: 90px; }
    `]
})

export class ExpressionSimpleComponent implements OnInit {
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    operatorsTypes: any;
    conditions: any = [{}];
    types: any;
    previewValue: any;
    parameterOptions: any;

    constructor(
        private Entity: Entity,
        private filter: FilterService,
    ) {
        this.types = [
            {
                name: this.filter.translate('numeric'),
                type: 'numeric'
            },
            {
                name: this.filter.translate('parameter'),
                type: 'parameter'
            },
            {
                name: this.filter.translate('operator'),
                type: 'operator'
            }
        ];

        this.operatorsTypes = OPERATORS_TYPES.map((item: string) => {
            if (item === '=') {
                return {
                    id: '==',
                    name: item,
                }
            }
            return {
                id: item,
                name: item
            }
        });

    }

    ngOnInit() {
        this.Entity.get('parameter')
            .search({})
            .then((response: any) => this.parameterOptions = response);
    }

    formatExpression() {
        const expressionValues = this.conditions;
        let output = '';

        if (expressionValues && expressionValues.length) {
            for (const expressionConditionValue of expressionValues) {
                const config = expressionConditionValue.config;
                const value = expressionConditionValue.value;

                if (expressionConditionValue && config) {
                    if (value && (config.type === 'numeric' || config.type === 'operator')) {
                        output += ' ' + expressionConditionValue.value;

                    } else if (config.type === 'parameter' && value) {
                        output += ' p(' + value.id + ')';

                    }

                }

            }
        }

        return output;
    }

    set = (item: any, value: any) => {
        item.value = value;
    };

    addNewCondition() {
        this.conditions.push({ config: {} });
        this.update()
    }

    edit(item: any) {
        item.isEditMode = true;
        this.update();
    }

    deleteConditionItem(items: any, index: number) {
        items.splice(index, 1);
        this.update();
    }

    confirmEdit(item: any) {
        delete item.isEditMode;
        this.update();
    }

    resetValue(item: any, value: any) {
        delete item.value;
        item.config = value;

        this.update();
    }

    addExpression() {
        const output = this.formatExpression();

        this.onChange.emit(output);
    }

    update() {
        this.previewValue = this.formatExpression();
    }

}
