import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { statsMethods } from '../utils/table-config';
import { TableHelperService } from '../services/table-helper.service';
import { TableCacheService } from '../services/table-cache.service';
import { copyToClipboard, deepCopy, findById, findByProperty, isDefined, omit } from '../../utils';
import { FilterService } from '../../services/filter.service';
import { Entity } from '../../services/entity.service';
import { CONFIG } from '../../config';
import * as $ from 'jquery';
import { UploaderService } from '../../services/uploader.service';
import { UiPreferencesService, UI_TABLE_PAGINATION_TOP } from '../../services/ui-preferences.service';
import { ACL } from '../../services/acl.service';
import { TableDialogComponent } from './table-dialog.component';
import { DynamicFieldsService } from '../../services/dynamic-fields.service';
import { AuthService } from '../../services/auth.service';
import { DynamicField } from '../../interfaces/entity-interfaces';
import { TableField } from '../../interfaces/object-interfaces';
import { ToastService } from '../../services/toast.service';
import { Subject } from '@proman/rxjs-common';
import { DragulaService } from 'ng2-dragula';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

const DOWNLOAD_TYPES = [
    { file: 'xml', icon: 'file-code' },
    { file: 'xls', icon: 'file-excel' },
    { file: 'xlsx', icon: 'file-excel' },
    { file: 'csv', icon: 'table' },
    { file: 'html', icon: 'file-code'},
    { file: 'json' },
    { file: 'ods' }
];

export interface TableDynamicField extends DynamicField {
    enabled: boolean;
}

@Component({
    selector: 'pro-table-settings-dialog',
    template: `
        <pro-dialog-title [title]="dialogTitle"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <div fxLayout="row" fxFlex="noshrink" fxLayoutAlign="space-between center">
                <pro-select *ngIf="data.query?.entity && !this.isListTable"
                        [value]="limit"
                        [config]="{ label: 'show_rows_quantity', disableSearch: true }"
                        (onChange)="setLimit($event)"
                        [options]="rowLimitOptions" fxFlex="200px"></pro-select>
                <pro-checkbox *ngIf="isMultiselectAvailable"
                           (onChange)="set('isMultiselect', $event)"
                           [config]="{ label: '', icon: 'check-double', tooltip: 'multiselect_toggle', tooltipPosition: 'bottom' }"
                           [value]="isMultiselect"></pro-checkbox>
                <pro-checkbox [config]="{ label: '', icon: 'list-ol', tooltip: 'line_numbering_toggle', tooltipPosition: 'bottom' }"
                           (onChange)="set('isLineNumbering', $event)"
                           [value]="isLineNumbering"></pro-checkbox>
                <pro-checkbox [value]="isFilteringDisabled"
                              [config]="{ label: '', icon: 'filter-circle-xmark', tooltip: 'filtering_toggle', tooltipPosition: 'bottom' }"
                              (onChange)="set('isFilteringDisabled', $event)"></pro-checkbox>
                <pro-checkbox [config]="{ label: '', icon: 'arrow-down-arrow-up', tooltip: 'multiple_sorting_toggle', tooltipPosition: 'left' }"
                              [value]="isMultipleSort"
                              (onChange)="set('isMultipleSort', $event)"></pro-checkbox>
            </div>
            <div class="List">
                <div class="List-header">
                    <pro-label>{{ 'columns' | translate }}</pro-label>
                </div>
                <div [dragula]="dragulaId"
                     [(dragulaModel)]="fields"
                >
                    <div class="List-row"
                         *ngFor="let field of fields"
                         fxLayout="row"
                         fxLayoutAlign="start center">

                        <pro-move-handle class="TableFields-MoveHandle"></pro-move-handle>
                        <div>{{ field.name | translate }}</div>
                        <div fxFlex></div>
                        <ng-container *ngIf="field && hasMethods(field) && field._enabled">
                            <pro-checkbox *ngFor="let method of methods"
                                         class="LeftMargin"
                                         [config]="{ label: '', icon: icons[method.name], tooltip: (method.name | translate), iconOnly: true }"
                                         [value]="field.config?.statsConfig[method.name]"
                                         (onChange)="toggleMethod(method, field, $event)"></pro-checkbox>
                        </ng-container>
                        <ng-container *ngIf="field && isDateField(field)">
                            <pro-checkbox class="LeftMargin"
                                         [config]="{ label: '', icon: 'clock', tooltip: ('show_time_filter' | translate), iconOnly: true }"
                                         [value]="!!field.showTime"
                                         (onChange)="toggleTimeFiltering(field, $event)"></pro-checkbox>
                        </ng-container>
                        <pro-checkbox class="LeftMargin"
                                     [config]="{ label: '', icon: 'text-width', tooltip: 'show_full_text' | translate, iconOnly: true }"
                                     [value]="field.config?.showFullResult"
                                     (onChange)="toggleFullResult(field, $event)"></pro-checkbox>
                        <pro-checkbox [config]="{}"
                                   [value]="field._enabled"
                                   (onChange)="setFieldEnabled(field, $event)"></pro-checkbox>


                    </div>
                </div>
            </div>
            <div fxLayout="column" class="Padding" style="margin-bottom: 16px;">
                <pro-checkbox *ngIf="!this.isListTable"
                             [value]="showTopTablePagination"
                             [config]="{ label: 'show_pagination_above_table' }"
                             (onChange)="setTopPagination($event)"></pro-checkbox>
            </div>
            <div fxLayout="row" fxFlex="noshrink">
                <pro-checkbox *ngIf="ACL.check('dynamic.view') && data.query?.entity"
                           [config]="{ label: 'include_dynamic_values' }"
                           (onChange)="setIncludeDynamic($event)"
                           [value]="includeDynamic"></pro-checkbox>
                <fa *ngIf="includeDynamic"
                       name="exclamation-triangle"
                       style="color: #f44336; margin-left: 8px;"
                       [proOverlay]="{ type: 'button', data: ('can_reduce_loading_speed' | translate) }"></fa>
            </div>
            <ng-container *ngIf="dynamicFields && includeDynamic">
                <pro-checkbox *ngFor="let field of dynamicFields"
                           [config]="{ label: field.name }"
                           (onChange)="field.enabled = $event"
                           [value]="field.enabled"
                           class="LeftMargin LeftPadding"
                ></pro-checkbox>
            </ng-container>

            <pro-checkbox [config]="{ label: 'subtotal_values' }"
                       [value]="subtotal.enabled"
                       (onChange)="updateSubtotalOptions($event)"></pro-checkbox>
            <div *ngIf="subtotal.enabled">
                <pro-select *ngIf="subtotal.enabled"
                        [value]="subtotal.groupBy"
                        [config]="{ label: 'subtotal_group_by' }"
                        [options]="subtotal.groupByOptions"
                        (onChange)="setSubtotalProperty('groupBy', $event)"></pro-select>
                <pro-select *ngIf="subtotal.groupBy"
                        [value]="subtotal.groupFields"
                        [config]="{ label: 'subtotal_group_fields', multiple: true }"
                        [options]="subtotal.groupFieldOptions"
                        (onChange)="setSubtotalProperty('groupFields', $event)"></pro-select>
            </div>
            <div class="TopPadding">

                <div fxLayout="row" fxLayoutAlign="end center" >

                    <pro-btn *ngIf="data.query?.entity"
                            [tooltip]="'download' | translate"
                            (onClick)="downloadDefaultType()"
                            icon="file-excel"></pro-btn>

                    <pro-btn [tooltip]="'copy_to_clipboard' | translate"
                            (onClick)="copyTableData()"
                            icon="clipboard"></pro-btn>

                    <pro-btn [tooltip]="'share_link' | translate"
                             [theme]="'accent'"
                            (onClick)="shareLink()"
                            icon="share"></pro-btn>
                </div>

                <hr>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <pro-btn [tooltip]="'reset_table_settings' | translate"
                            [theme]="'warn'"
                            (onClick)="resetSettings()"
                            icon="undo"></pro-btn>
                </div>

            </div>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class TableSettingsDialogComponent implements OnDestroy {
    rowLimitOptions: any;
    isLineNumbering: any;
    isMultiselect: any;
    isMultiselectAvailable: any;
    isListTable: boolean;
    isMultipleSort: boolean;
    isFilteringDisabled: boolean;
    limit: any;
    methods: any;
    subtotal: any;
    fields: any;
    defaultExportType = 'xlsx';
    downloadTypes: any = DOWNLOAD_TYPES;
    icons: any = {
        sum: 'sigma',
        average: 'tachometer-alt-average'
    };
    showTopTablePagination: boolean;
    dialogTitle: string;
    includeDynamic: any;
    dynamicFields: TableDynamicField[];
    dragulaId: string;
    destroyed$: Subject<void> = new Subject();
    tableCacheUrl: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Auth: AuthService,
        public ACL: ACL,
        private Entity: Entity,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<TableSettingsDialogComponent>,
        private Uploader: UploaderService,
        private TableHelper: TableHelperService,
        private TableCache: TableCacheService,
        private UiPrefs: UiPreferencesService,
        private DynamicFields: DynamicFieldsService,
        private MatLegacyDialog: MatLegacyDialog,
        private Toast: ToastService,
        private Dragula: DragulaService,
    ) {
        const params = this.TableCache.get(this.data.tableId);
        this.dialogTitle = `${this.Filter.translate('table_settings')} ` +  (isDefined(this.data.tableId) ? ` '${this.data.tableId}'` : '');

        this.rowLimitOptions = this.TableHelper.getRowLimits()
            .map((item: any) => ({
                id: item,
                name: item
            }));
        this.limit = this.rowLimitOptions[0];
        this.isLineNumbering = this.data.isLineNumbering;
        this.isMultiselect = this.data.isMultiselect;
        this.isMultiselectAvailable = this.data.isMultiselectAvailable;
        this.isMultipleSort = this.data.isMultipleSort;
        this.isFilteringDisabled = this.data.isFilteringDisabled;
        this.methods = statsMethods;
        this.subtotal = deepCopy(this.data.subtotal);
        this.includeDynamic = this.data.includeDynamic;

        if (params && params.limit && params.limit !== this.limit) {
            this.limit = findById(this.rowLimitOptions, { id: params.limit });

        }

        this.fields = this.data.fields;
        this.isListTable = this.data && this.data.config && this.data.config.useListNotSearch;

        // this.updateSubtotalOptions(this.subtotal?.enabled);

        this.showTopTablePagination = (this.UiPrefs.get(UI_TABLE_PAGINATION_TOP));

        this.getDynamicFields();
        this.purgeDuplicateFields();

        this.dragulaId = `fields-${Date.now()}`;
        this.Dragula.createGroup(this.dragulaId, {
          invalid: (el: any, element: HTMLElement) => {
              const isMove = (el: HTMLElement) => el.classList.contains('TableFields-MoveHandle');
              return !(isMove(element) || isMove(element.parentElement) || isMove(element.parentElement.parentElement));
          }
        });

        /*this.Dragula.drag(this.dragulaId).pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {

            console.log('drag', name, el);

        });

        this.Dragula.drop(this.dragulaId).pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
            console.log('drop', name, el);


        });

        console.log('this.Dragula.find(this.dragulaId)', this.Dragula.find(this.dragulaId));*/

        this.generateTableUrl();
    }

    set(property: any, value: any) {
        this[property] = value;
    }

    setIncludeDynamic(value: any) {
        this.includeDynamic = value;
    }

    toggleFullResult(column: any, value: boolean) {
        if (column.config) column.config.showFullResult = value;
    }

    getDynamicFields() {
        if (this.data.config.excludeDynamic) return;
        if (!(this.data.query && this.data.query.entity)) return;
        this.DynamicFields.getFieldsForEntity(this.data.query.entity)
            .then((fields: any[]) => {
                if (fields) fields.forEach((field: any) => {
                    field.enabled = true;

                    if (this.includeDynamic && this.includeDynamic.length) {
                        let item = findByProperty(this.includeDynamic, 'alias', field.alias);

                        if (!item) field.enabled = false;
                    }

                });

                this.dynamicFields = fields;
            });
    }

    setLimit(value: any) {
        this.limit = value;
    }

    toggleMethod(method: any, field: any, value: any) {
        if (!field.config) field.config = { statsConfig: {} };
        if (!field.config.statsConfig) field.config.statsConfig = {};

        field.config.statsConfig[method.name] = value;
    };

    toggleTimeFiltering(field: any, value: boolean) {
        field.showTime = value;
    };

    hasMethods(field: any) {
        return typeof field.stats !== 'undefined';
    };

    isDateField(field: any) {
        return field.formatter === 'dateTime';
    };

    setFieldEnabled(field: TableField, value: boolean) {
        field._enabled = value;
    };

    setSubtotalProperty(property: any, value: any) {
        this.subtotal[property] = value;
    };

    updateSubtotalOptions(value: any) {
        this.subtotal.enabled = value;

        if (value) {
            this.subtotal.groupByOptions = [];
            this.subtotal.groupFieldOptions = [];

            this.fields.forEach((field: any) => {
                let groupField;

                groupField = { id: field.name };

                this.subtotal.groupByOptions.push(Object.assign(groupField, field));

                if ((field.formatter === 'numeric' || field.formatter === 'money' || field.formatter === 'input') && field.key) {
                    this.subtotal.groupFieldOptions.push(Object.assign(groupField, field));

                }

            });

            this.subtotal.groupByOptions.forEach((item: any) => {
                item.name = this.Filter.translate(item.name);
            });

            this.subtotal.groupFieldOptions.forEach((item: any) => {
                item.name = this.Filter.translate(item.name);
            });

        }

    };

    purgeDuplicateFields = () => {
        let newFields: TableField[] = [];
        this.fields.forEach((field: TableField) => {
            if (!newFields.find((newField: TableField) => newField.key === field.key)) {
                newFields.push(field);
            }
        })
        this.fields = newFields;
    }

    confirm() {
        this.dialogRef.close({
            fields: this.fields,
            limit: this.limit?.id,
            isMultiselect: this.isMultiselect,
            isMultipleSort: this.isMultipleSort,
            isLineNumbering: this.isLineNumbering,
            isFilteringDisabled: this.isFilteringDisabled,
            subtotal: this.subtotal,
            includeDynamic: this.includeDynamic ? this.dynamicFields.filter((field: { enabled: boolean }) => field.enabled) : false,
        });
    }

    resetSettings() {
        this.TableCache.reset(this.data.tableId);
        this.TableCache.saveSettings();
        this.dialogRef.close({ settingsReset: true });
    }

    download(responseType: string) {
        const params = Object.assign(
            {},
            this.data.query.params,
            {
                responseType ,
                bearer: this.Auth.getToken(),
                responseDownload: 1,
                limit: []
            }
        );

        const root = this.getExportRoot();

        window.open(`${root}/search?${$.param(params)}`);
    }

    getExportRoot = () => {
        return CONFIG.api + this.data.query.entity + (this.data.dynamicTableId && `/${this.data.dynamicTableId}` || '');
    };

    handleImport = () => {
        this.Uploader
            .init(() => {})
            .show({ url: `${CONFIG.api}${this.data.query.entity}/import` })
            .then(this.handleImportResponse)
            .then((): any => {})
            .catch((): any => {});
    };

    handleExport = () => {
        const params = { bearer: this.Auth.getToken() };

        const root = this.getExportRoot();

        window.open(`${root}/export?${$.param(params)}`, '_blank');
    };

    setTopPagination = (value: boolean) => {
        this.UiPrefs.set(UI_TABLE_PAGINATION_TOP, value);
    };

    handleImportResponse = (response: any) => {

        this.MatLegacyDialog
            .open(TableDialogComponent, {
                data: {
                  header: 'result',
                  config: {
                    entity: 'invoice',
                    aclRoot: 'invoice',
                    hideSettings: true,
                    fields: [
                      {
                        name: 'row',
                        key: 'row',
                        filter: null,
                      },
                      {
                        name: 'error',
                        key: 'error',
                        filter: null,
                      },
                      {
                        name: 'message',
                        key: 'message',
                        filter: null,
                      },
                      {
                        name: 'id',
                        key: 'id',
                        filter: null,
                      },
                      {
                        name: 'new',
                        key: 'new',
                        filter: null,
                      }
                    ],
                    rowMarker: (row: any) => {
                      if (row.error) {
                        return 'red';

                      }

                    },
                  },

                  payload: {
                    data: response,
                    total: response.length
                  }
                }
              });
    };

    downloadDefaultType() {
        let _export: any = {};
        let onExport = this.data.config.onExport;

        let params = Object.assign(
            {},
            this.data.query.params,
            {
                responseType: this.defaultExportType,
                bearer: this.Auth.getToken(),
                responseDownload: 1,
                limit: []
            }
        );

        delete params.paginate;

        this.fields
            .filter((item: any) => item._enabled)
            .forEach((item: any) => {
                _export[item.key] = this.Filter.translate(item.name);
            });

        if (this.includeDynamic) {
            this.dynamicFields
                .filter((field) => !!field.enabled)
                .forEach((field) => {
                   _export[`df_${field.alias}`] = field.name;
                });
        }

        if (onExport) _export = onExport(_export);

        Object.assign(params, { _export });

        window.open(`${this.getExportRoot()}/search?${$.param(params)}`, '_blank');
    }

    copyTableData() {

        const oldTable = document.querySelector('#copiedTableData');
        if (oldTable) {
            oldTable.innerHTML = '';
        } else {
            const copiedTableData = document.createElement('div');
            copiedTableData.setAttribute('id', 'copiedTableData');
            document.body.appendChild(copiedTableData);
        }

        const copiedTableData = document.querySelector('#copiedTableData');
        copiedTableData.append(this.data.htmlElement.querySelector('table').cloneNode(true));

        const filtersRow = copiedTableData.querySelector('tr.Table-Filters');
        if (filtersRow) filtersRow.parentElement.removeChild(filtersRow);

        try {
            const range = document.createRange();
            range.selectNode(copiedTableData.querySelector('table'));
            window.getSelection().addRange(range);
            navigator.clipboard.writeText(range.toString());
            // document.execCommand('copy'); Doesn't work? B.B.

            this.Toast.pop('info', 'copied_to_clipboard', { value: this.Filter.translate('table') });

        } catch (e) {
            // console.log('copy error', e);
        }

    }

    shareLink() {
        copyToClipboard(this.tableCacheUrl);

        this.Toast.pop('info', 'copied_to_clipboard', { value: 'URL' });

    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.Dragula.destroy(this.dragulaId);
    }

    generateTableUrl() {
        if (!this.data.tableId) return;

        const cache = omit(this.data.cache, [
            // 'fields',
            '_lastSave'
        ]);
        const cacheString = encodeURI(JSON.stringify(cache));
        const url = `tableShare=${this.data.tableId}&tableData=${cacheString}`;
        this.tableCacheUrl = `${window.location.href}?${url}`
    }

}
