import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proDecimal' })

export class DecimalPipe implements PipeTransform {

    constructor(
        private Filter: FilterService,
    ) {

    }

    transform(value: any, params?: any) {
        return this.Filter.decimal(value, params);
    }
}
