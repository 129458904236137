import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { LIST_MANAGER_DYNAMIC_TEMPLATES } from "@proman/list-manager/constants";
import { ListManagerComponentsMappings } from "@proman/list-manager/types";
import { isDefinedNotNull } from "@proman/utils";

export abstract class DynamicComponent {
  item: any;
  config?: any;
  actions?: any[];
}

@Component({
  selector: 'pro-dynamic-template',
  template: `
        <ng-template #container></ng-template>
    `
})

export class ListManagerDynamicTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('container', {read: ViewContainerRef, static: true}) container: ViewContainerRef;
  @Input() item: any;
  @Input() type: string;
  @Input() config: any;
  @Input() actions: any[];
  private componentRef: ComponentRef<{}>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, @Inject(LIST_MANAGER_DYNAMIC_TEMPLATES) private mappings: ListManagerComponentsMappings) {
  }

  ngOnInit() {

    if (this.type) {
      const componentType = this.mappings[this.type];
      if (!isDefinedNotNull(componentType)) return;
      const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
      this.componentRef = this.container.createComponent(factory);

      let instance = <DynamicComponent>this.componentRef.instance;
      instance.item = this.item;
      instance.config = this.config;
      instance.actions = this.actions;

    }

  }

  ngOnDestroy() {

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;

    }
  }

}
