import { Inject, Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { DateTimeFormatService } from '@proman/services/date-time-format.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageConfigService } from '@proman/services/language-config.service';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    constructor(
        @Inject(DateTimeFormatService) private DateTimeFormat: DateTimeFormatService,
        @Inject(LanguageConfigService) private LangConfig: LanguageConfigService,
        @Inject(TranslateService) private Translate: TranslateService,
    ) {
        super('lt');
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    format(date: Date): string {
        return moment(date).format(this.DateTimeFormat.get('_date_js'));
    }

    getMonthNames() {
        const months = this.Translate.instant('_month_names_min').split(' ');
        return months.length > 1 ? months : ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    }

    getDayOfWeekNames() {
        const days = this.Translate.instant('_day_names_short').split(' ');

        return days.length > 1 ? days : ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    }

}
