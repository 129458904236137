import { NgModule } from '@angular/core';

import { OverlayDirective } from './directives/overlay.directive';
import { PaddingDirective } from './directives/padding.directive';
import { NoPaddingDirective } from './directives/no-padding.directive';
import { EventEmployeeTimeRangeDirective } from '../events/directives/event-employee-time-range.directive';
import { WsUpdateDirective } from './directives/ws-update.directive';

const DIRECTIVES: any[] = [
  OverlayDirective,
  PaddingDirective,
  NoPaddingDirective,
  EventEmployeeTimeRangeDirective,
  WsUpdateDirective,
];

@NgModule({
  declarations: DIRECTIVES,
  providers: DIRECTIVES,
  exports: DIRECTIVES,
})

export class FrontendDirectivesModule {

}
