import { NgModule } from '@angular/core';
import { PromanSelectComponent } from '../select';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { ParseTimeDirective } from './parse-time.directive';
import { PromanDatepickerComponent } from './proman-datepicker.component';
import { PromanTimeComponent } from './proman-time.component';
import { PromanTimeDropdownComponent } from './proman-time-dropdown.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { PromanTextSimpleComponent } from '../text-simple/proman-text-simple.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanDurationDropdownComponent } from './proman-duration-dropdown.component';
import { PromanClockTimepickerComponent } from '../clock-timepicker/proman-clock-timepicker.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatDatepickerModule,
    MatLegacyInputModule,
    MatNativeDateModule,
    PromanTextSimpleComponent,
    PromanSelectComponent,
    PipesModule,
    FlexLayoutModule,
    PromanClockTimepickerComponent,
    PromanDurationDropdownComponent
  ],
  declarations: [
    ParseTimeDirective,
    PromanDatepickerComponent,
    PromanTimeComponent,
    PromanTimeDropdownComponent
  ],
  exports: [
    PromanDatepickerComponent,
    PromanTimeComponent,
    PromanTimeDropdownComponent,
    PromanDurationDropdownComponent
  ]
})

export class PromanDateTimeModule {

}
