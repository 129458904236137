import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'proReverse' })

export class ReversePipe implements PipeTransform {

    constructor(

    ) {

    }

    transform(array: any[], params?: any) {
        return array.reverse();
    }
}
