import { AbstractControl, ValidatorFn } from '@angular/forms';
import { hasSubstring } from '../utils';

export function NoCommaValidator(control: AbstractControl) {
    let value = control.value;
    const isValid = !hasSubstring(value, ',');

    if (!isValid && value) {
        return { noComma: true };

    }

    return null;
}
