import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[proOnResize]'
})
export class OnResizeDirective  {
    @Output('proOnResize') onResize: EventEmitter<void> = new EventEmitter<void>();

    constructor() {

    }

    @HostListener('window:resize')
    public onResizeEvent() {
        this.onResize.emit();
    }

}
