import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proDate' })

export class DatePipe implements PipeTransform {

    constructor(
        private Filter: FilterService,
    ) {

    }

    transform(value: string, format?: string) {
        return this.Filter.date(value, format);
    }
}
