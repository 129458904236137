import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UiPreferencesService } from '@proman/services/ui-preferences.service';
import { deepCopy, isDefined } from '@proman/utils';

export const ENTITY_FIELDS_KEY = 'entityFields';

@Component({
    selector: 'pm-input',
    template: `
        <ng-container *ngIf="show">
            <ng-container [ngSwitch]="type">

                <pro-autoc *ngSwitchCase="'autoc'"
                          [value]="value"
                          [config]="config"
                          [getOptions]="getOptions"
                          [disabled]="disabled"
                          (onChange)="onChange.emit($event)"></pro-autoc>

                <pm-btn-list *ngSwitchCase="'btn-list'"
                          [value]="value"
                          [config]="config"
                          [disabled]="disabled"
                          (onChange)="onChange.emit($event)"></pm-btn-list>

                <pm-txt *ngSwitchCase="'txt'"
                        [value]="value"
                        [config]="config"
                        [disabled]="disabled"
                        (onChange)="onChange.emit($event)"></pm-txt>

                <pro-select *ngSwitchCase="'sel'"
                        [value]="value"
                        [config]="config"
                        [options]="options"
                        [disabled]="disabled"
                        (onChange)="onChange.emit($event)"></pro-select>

                <pro-checkbox *ngSwitchCase="'switch'"
                           [value]="value"
                           [config]="config"
                           [disabled]="disabled"
                           (onChange)="onChange.emit($event)"></pro-checkbox>

                <pm-price *ngSwitchCase="'price'"
                          [value]="value"
                          [config]="config"
                          [disabled]="disabled"
                          (onChange)="onChange.emit($event)"></pm-price>

                <pro-color *ngSwitchCase="'color'"
                          [value]="value"
                          [config]="config"
                          (onChange)="onChange.emit($event)"></pro-color>

                <pro-checkbox *ngSwitchCase="'checkbox'"
                             [value]="value"
                             [config]="config"
                             (onChange)="onChange.emit($event)"></pro-checkbox>

                <pm-html-txt *ngSwitchCase="'html'"
                          [value]="value"
                          [config]="config"
                          (onChange)="onChange.emit($event)"></pm-html-txt>

                <pro-datepicker *ngSwitchCase="'datepicker'"
                               [value]="value"
                               [config]="config"
                               [disabled]="disabled"
                               (onChange)="onChange.emit($event)"></pro-datepicker>

                <pro-editable-container *ngSwitchCase="'editable'" [label]="label">
                    <ng-content select=".editable"></ng-content>
                </pro-editable-container>

                <ng-container *ngSwitchCase="'transclude'"><ng-content></ng-content></ng-container>

                <ng-container *ngSwitchDefault>NO COMPONENT FOUND</ng-container>

            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class InputComponent implements OnInit {
    @Input() type: 'autoc'|'btn-list'|'txt'|'sel'|'switch'|'price'|'color'|'html'|'datepicker'|'editable'|'transclude'|'checkbox';
    @Input() key: string;
    @Input() value: any;
    @Input() config: any = {};
    @Input() getOptions: (query: string) => Promise<any[]>;
    @Input() options: any[] = [];
    @Input() label: string;
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    show: boolean;

    constructor(
        private UiPrefs: UiPreferencesService,
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        const fields = deepCopy(this.UiPrefs.get(ENTITY_FIELDS_KEY) || {});

        if (isDefined(fields[this.key]) && fields[this.key] === false) {
            this.show = false;

        } else if (this.key && !isDefined(fields[this.key])) {
            fields[this.key] = true;

            this.UiPrefs.set(ENTITY_FIELDS_KEY, fields);

            this.show = true;

        } else {
            this.show = true;

        }

        this.cd.markForCheck();

    }

}
