export const OPERATORS_TYPES = [
    '+',
    '-',
    '*',
    '/',
    '>',
    '<',
    '=',
    'or',
    'and'
];
