import { ApplicationInitStatus, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { GlobalOverlayDirective } from './global-overlay.directive';
import { GlobalOverlayService } from './global-overlay.service';
import { GlobalOverlayComponent } from './global-overlay.component';
import { GlobalOverlayAppendingService } from './global-overlay-appending.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    GlobalOverlayDirective,
    GlobalOverlayComponent,
  ],
  exports: [
    GlobalOverlayDirective,
    GlobalOverlayComponent,
  ]
})

export class GlobalOverlayModule {

  constructor() {

  }


  static forRoot(): ModuleWithProviders<GlobalOverlayModule> {
    return {
      ngModule: GlobalOverlayModule,
      providers: [
        GlobalOverlayService,
        GlobalOverlayAppendingService,
      ]
    };
  }
}
