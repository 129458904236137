import { AbstractControl, ValidatorFn } from '@angular/forms';
export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

export function EmailValidator(control: AbstractControl) {
    let value = control.value;
    let isValid = validateEmail(value);

    if (!isValid && value) {
        return { invalidEmail: true };

    }

    return null;
}
