import { merge as observableMerge, Subscription, Observable, fromEvent, of } from '@proman/rxjs-common';
import { debounceTime, map } from '@proman/rxjs-common';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    OnDestroy,
    ElementRef,
    ViewChild,
    OnInit, AfterViewInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import { utcFormat, twoDigit, getRandomString, isDefinedNotNull } from '../utils';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const DEFAULT_DEBOUNCE = 1500;

@Component({
    selector: 'pro-datepicker',
    template: `
        <div fxLayout="row" fxFlex="noshrink" [ngClass]="{ 'MinWidth-130': !config.hideTime, 'MinWidth-100': config.hideTime }" class="Width100">
            <mat-form-field [floatLabel]="config.floatLabel || 'auto'" [ngClass]="{ 'important-input': config.important && !isDefinedNotNull(value) }"
                            [color]="config.important && !isDefinedNotNull(value) ? 'warn' : 'primary'">
                <input matInput style="width: 100%;"
                       [matDatepicker]="picker"
                       [placeholder]="config.label | translate"
                       [value]="date"
                       [min]="min"
                       [max]="max"
                       [required]="config.required"
                       (click)="picker.open()"
                       (dateChange)="setDate($event.value)"
                       [disabled]="disabled"
                       autocomplete="off"
                >
                <mat-datepicker #picker [touchUi]="(isHandset$ | async)"></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!config.hideTime && ((!config.timeDropdown && !config.clockTimepicker) || (config.timeDropdown && config.clockTimepicker))" style="display: inline-flex;">
                <mat-form-field proParseTime="hours" fxFlex="20px">
                    <input #hoursEl matInput type="text" [formControl]="hoursControl" autocomplete="off">
                </mat-form-field>
                <span style="line-height: 48px;margin-right: 2px;">:</span>
                <mat-form-field proParseTime="minutes"  fxFlex="20px">
                    <input #minutesEl matInput type="text" [formControl]="minutesControl" autocomplete="off">
                </mat-form-field>
            </div>

            <div *ngIf="!config.hideTime && config.timeDropdown && !config.clockTimepicker" style="display: inline-flex;">
                <pro-time-dropdown [value]="hours + ':' + minutes + ':00'"
                                   [config]="{ label: 'time' }"
                                   [minutesPrecise]="config.timeDropdownPrecise"
                                   (onChange)="setDropdownTime($event)"
                ></pro-time-dropdown>
            </div>

            <div *ngIf="!config.hideTime && config.clockTimepicker && !config.timeDropdown" style="display: inline-flex;">
                <pro-clock-timepicker [value]="hours + ':' + minutes" (onChange)="setClockTimepickerTime($event)"></pro-clock-timepicker>
            </div>
        </div>
    `,
    styles: [
        ':host { display: inline-flex; }',
        ':host mat-input-container { width: 22px; margin-left: 2px; }',
        'mat-form-field { flex: auto; }',
        'pro-btn { position: absolute; right: 0; top: 0; }',
        'pro-btn * { box-shadow: none; }',
        '.MinWidth-130 { min-width: 130px }',
        '.MinWidth-100 { min-width: 100px }'
    ]
})

export class PromanDatepickerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('hoursEl') hoursEl: ElementRef;
    @ViewChild('minutesEl') minutesEl: ElementRef;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() config: {
        label: string;
        min?: string;
        max?: string;
        important?: boolean;
        required?: boolean;
        hideTime?: boolean;
        clockTimepicker?: boolean;
        timeDropdown?: boolean;
        timeDropdownPrecise?: boolean;
        emitTimeZoneValue?: boolean;
        disabled?: boolean;
        floatLabel?: 'always' | 'auto';
        debounce?: number;
        preventInvalid?: boolean;
        setCurrentTime?: boolean;
    } = {
        label: ''
    };
    @Input() value: string;
    @Input() disabled: boolean;
    hoursControl: UntypedFormControl = new UntypedFormControl('00');
    minutesControl: UntypedFormControl = new UntypedFormControl('00');
    hours: any = '00';
    minutes: any = '00';
    date: any;
    min: any;
    max: any;

    valueChanges: Subscription;
    hoursValueChanges: Subscription;
    minutesValueChanges: Subscription;
    hourInputClicks: number = 0;
    _autocomplete: string;
    lastEmitedValue: string;

    isHandset$: Observable<boolean> = this.BPObserver
        .observe(Breakpoints.Handset)
        .pipe(map((result: any) => result.matches));

    constructor(
        private BPObserver: BreakpointObserver
    ) {
        this._autocomplete = getRandomString();
    }

    ngOnDestroy() {
        this.hoursValueChanges?.unsubscribe();
        this.minutesValueChanges?.unsubscribe();
        this.valueChanges?.unsubscribe();
    }

    ngOnInit() {

        if (this.value) {
            this.setLocalValue(this.value);

        }

        if (this.config.min) {
            this.min = new Date(this.config.min);

        }

        if (this.config.max) {
            this.max = new Date(this.config.max);

        }

        if (this.config.disabled) {
            this.disabled = this.config.disabled;

        }

        if (this.disabled) {
            this.minutesControl.disable();
            this.hoursControl.disable();

        }

        this.hoursValueChanges = this.hoursControl.valueChanges
            .pipe(
                map((value) => value.length == 2 ? value : ('00' + value).slice(-2)) ,
            )
            .subscribe((value: any) => {
            let val = parseInt(value);

            if (isNaN(val)) val = this.hours;

            if (this.hoursEl && this.hoursEl.nativeElement) this.hoursEl.nativeElement.value = val;
            this.hours = Math.min(val, 23);

            if (this.date) this.date.setHours(this.hours);

            this.hourInputClicks++;

            if (this.hourInputClicks === 2) {
                this.hourInputClicks = 0;
                this.minutesEl?.nativeElement.focus();
                document.execCommand('selectAll', false, '');
            }

            setTimeout(() => this.hourInputClicks = 0, 3000);

        });

        this.minutesValueChanges = this.minutesControl.valueChanges
            .pipe(
                map((value) => value.length > 2 ? value.slice(-2) : value) ,
            )
            .subscribe((value: any) => {
            let val = parseInt(value);

            if (isNaN(val)) val = this.minutes;

            if (this.minutesEl && this.minutesEl.nativeElement) this.minutesEl.nativeElement.value = val;
            this.minutes = Math.min(val, 59);

            if (this.date) this.date.setMinutes(this.minutes);
        });
    }

    ngAfterViewInit() {
        const debounce = this.config.debounce || DEFAULT_DEBOUNCE;

        this.valueChanges = observableMerge(
            this.hoursControl.valueChanges.pipe(debounceTime(debounce)),
            this.minutesControl.valueChanges.pipe(debounceTime(debounce)),
            this.minutesEl ? fromEvent(this.minutesEl.nativeElement, 'blur') :  of()
        )
            .subscribe((value) => {
                if (value) this.handleChange();
            });
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.value) {

            if (
                this.config.min && moment(changes.value.currentValue) < moment(this.config.min) ||
                this.config.max && moment(changes.value.currentValue) > moment(this.config.max)
            ) {
                this.setLocalValue(changes.value.previousValue);

            } else {
                this.setLocalValue(changes.value.currentValue);

            }

        }

        if (changes.min && changes.min.currentValue) {
            this.min = new Date(changes.min.currentValue);

        }

        if (changes.max && changes.max.currentValue) {
            this.max = new Date(changes.max.currentValue);

        }

        if (changes.disabled) {
            const disabled = changes.disabled;

            if (disabled.currentValue) {
                this.hoursControl.disable();
                this.minutesControl.disable();

            } else {
                this.hoursControl.enable();
                this.minutesControl.enable();

            }

        }

    }

    setLocalValue(value: string) {
        if (value) {
            let date = new Date(value);

            if (date instanceof Date && !isNaN(date.valueOf())) {
                this.date = date;
                this.hours = ('0' + this.date.getHours()).slice(-2);
                this.minutes = ('0' + this.date.getMinutes()).slice(-2);
                this.hoursControl.setValue(this.hours, { emitEvent: false });
                this.minutesControl.setValue(this.minutes, { emitEvent: false });

            }

        } else {
            this.date = undefined;

        }

    }

    handleChange = () => {
        let date = moment(this.date);
        let newDate;

        if (date.format() === 'Invalid date') {
            return (this.config.required) ? '' : this.onChange.emit(null);

        }



        newDate = utcFormat(date);

        if (this.config.emitTimeZoneValue !== false) {
            newDate = utcFormat(moment(newDate).add(date.utcOffset(), 'm'));
        }

        if (utcFormat(moment(newDate)) !== utcFormat(moment(this.value)) && this.lastEmitedValue !== newDate) {
            this.lastEmitedValue = newDate;
            this.onChange.emit(newDate);
        }
    };

    setDate(value: Date) {
        let valid: boolean = true;

        if (value) {
            value.setHours(this.hours);
            value.setMinutes(this.minutes);

        }

        if (!this.date && value && this.config.setCurrentTime) {
            const date = moment();

            if (this.hours == '00') {
                const hours = date.hours();
                value.setHours(hours);
                this.hoursControl.setValue(twoDigit(hours), { emitEvent: false });
            }

            if (this.minutes == '00') {
                const minutes = date.minutes();
                value.setMinutes(minutes);
                this.minutesControl.setValue(twoDigit(minutes), { emitEvent: false });
            }

        }

        if (this.min || this.max) {
            let date = moment(value).format();

            if (this.min && date < moment(this.min).format()) {
                this.date = this.min;
                valid = false;

            }

            if (this.max && date > moment(this.max).format()) {
                this.date = this.max;
                valid = false;
            }

        }

        if (valid) {
            if (this.config?.preventInvalid && value === null) {
                this.setLocalValue(null);
            } else {
                this.date = value;

                this.handleChange();
            }

        } else {
            this.setDate(this.date);

        }

    }

    isDefinedNotNull = (value: any) => isDefinedNotNull(value);

    setDropdownTime(time: string) {
        const [hours, minutes, seconds] = time.split(':');

        this.hours = hours;
        this.minutes = minutes;

        const date = moment(this.value)
            .hours(+hours)
            .minutes(+minutes)
            .format();

        this.setDate(new Date(date));
    }

    setClockTimepickerTime(time: string) {
        if (!this.value) this.value = moment().format();

        const [hours, minutes] = time.split(':');

        this.hours = hours;
        this.minutes = minutes;

        const date = moment(this.value)
          .hours(+hours)
          .minutes(+minutes)
          .format();

        this.setDate(new Date(date));
    }

}
