import { Pipe, PipeTransform } from '@angular/core';
import { getWeight, roundNumber } from '../../../utils';
import { Weight } from '../../../interfaces/common.interface';

@Pipe({ name: 'proWeight' })

export class WeightPipe implements PipeTransform {

    constructor(
    ) {

    }

    transform(value: string|number, params?: 'g'|'kg') {
        const weight: Weight = getWeight(value, undefined, params);

        return `${roundNumber(+weight.amount)} ${weight.unit}`;
    }
}
