import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'proZeroPad' })

export class ZeroPadPipe implements PipeTransform {

    constructor() {}

    transform(value: any) {
        return (typeof value !== 'undefined' && value !== null) ?
            value.toString().length === 2 ?
                value
                : '0' + value
            : '';
    }
}
