import { durationFromSeconds } from '../../../utils';

import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proDuration' })

export class DurationPipe implements PipeTransform {

    constructor(private Filter: FilterService) {

    }

    transform(interval: number, units?: any, options?: any): any {
        let durationValue = durationFromSeconds(interval);
        let segments = [];
        const meta = {
            d: 'day_shorthand',
            h: 'hour_shorthand',
            m: 'minute_shorthand',
            s: 'second_shorthand'
        };

        for (let unit in meta) {

            if (units[unit]) {
                if ((options && options.nonZero && durationValue[unit] === 0) ||
                    (units[unit] === 'auto' && !durationValue[unit])) {
                    continue;
                }

                segments.push((durationValue[unit] || 0) + ' ' + this.Filter.translate(meta[unit]));
            }
        }

        return segments.join(' ');

    }
}
