import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MentionModule } from 'angular-mentions';
import { PipesModule } from '../shared/pipes/pipes.module';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { GlobalOverlayModule } from '@proman/overlay/global-overlay.module';
import { PromanSelectComponent } from '@proman/select';
import { PromanCheckboxComponent } from '@proman/checkbox/proman-checkbox.component';
import { PromanTableModule } from '@proman/table';
import { ExpressionHumanizeService } from './expression-humanize.service';
import { EditableExpressionComponent } from './components/editable-expression.component';
import { ExpressionFunctionComponent } from './components/expression-function.component';
import { ExpressionHumanizeComponent } from './components/expression-humanize.component';
import { ExpressionFormatComponent } from './components/expression-format.component';
import { ExpressionConditionComponent } from './components/expression-condition.component';
import { ExpressionSimpleComponent } from './components/expression-simple.component';
import { ExpressionCaseComponent } from './components/expression-case.component';
import { ExpressionEditDialogComponent } from './components/expression-edit.dialog.component';
import { ExpressionSelectDialogComponent } from './components/expression-select-dialog.component';

const COMPONENTS = [
  EditableExpressionComponent,
  ExpressionFunctionComponent,
  ExpressionHumanizeComponent,
  ExpressionFormatComponent,
  ExpressionConditionComponent,
  ExpressionSimpleComponent,
  ExpressionCaseComponent,
  ExpressionEditDialogComponent,
  ExpressionSelectDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyInputModule,
    MentionModule,
    FormsModule,
    PipesModule,
    PromanCommonComponentsModule,
    PromanButtonComponent,
    PromanTextSimpleComponent,
    PromanSelectComponent,
    PromanCheckboxComponent,
    PromanTableModule,
    GlobalOverlayModule,
  ],
  declarations: [
    ...COMPONENTS
  ],
  providers: [
    ExpressionHumanizeService,
  ],
  exports: [
    ...COMPONENTS
  ]
})

export class PromanExpressionModule {

}
