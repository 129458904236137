import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proMaxLength' })

export class MaxLengthPipe implements PipeTransform {

    constructor(private Filter: FilterService) {}

    transform(value: any, maxLength?: number, excludeDots?: boolean): any {
        return this.Filter.maxLength(value, maxLength, excludeDots);

    }
}
