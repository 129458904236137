import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { TranslatePipe } from "@proman/shared/pipes/pipes/translate.pipe";

@Component({
    selector: 'pro-no-records',
    standalone: true,
    imports: [
        TranslatePipe,
    ],
    template: `
        <div class="NoRecords LeftPadding">
            {{ 'no_records' | translate }}
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush

})

export class NoRecordsComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.cd.markForCheck();
    }

}
