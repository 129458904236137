import { Injectable } from '@angular/core';
import 'dragster';

import { CONFIG } from '../config';
import { RequestService } from './request.service';
import {
    HttpClient,
    HttpEventType,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { ToastService } from './toast.service';

class UploaderClass {
    public httpConfig: any;

    constructor(private Request: RequestService, private Http: HttpClient, private callback: any, private data: any, private Toast: ToastService) {

        this.httpConfig = {
            // transformRequest: angular.identity,
            headers: [
                'Content-Type: undefined',
                "__XHR__: () => { return (xhr: any) => xhr.upload.addEventListener('progress', callback);}"
            ]

            // headers: {
            //     'Content-Type': undefined,
            //     '__XHR__': (xhr: any) => {
            //         return xhr?.upload.addEventListener('progress', callback);
            //     }
            // }
        };
    }

    cleanUp() {
        const previousInput = document.body.querySelector('.proman-uploader');

        if (previousInput) {
            previousInput.remove();
        }

    }

    getSettings(options: { [key: string]: unknown }) {
        const settings = Object.assign({
            data: Object.assign({ type: 'misc' } , this.data || {}),
            accept: null,
            multiple: true,
            url: CONFIG.uploadUrl,
            reportProgress: true,
        }, options);

        return {
            ...settings,
            url: settings.url.endsWith('/') ? settings.url.slice(0, -1) : settings.url // remove '/' from end of url
        };
    }

    createFakeInput() {
        const div = document.createElement('div');
        div.classList.add('proman-uploader');
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('name','files[]');

        div.appendChild(input);

        const html = div;
        const body = document.body;

        this.cleanUp();


        body.appendChild(html);

        return html.querySelector('input[type=file]');
    }

    upload = (files: any[], options: any) => {
        let settings = this.getSettings(options);
        let dataElement;
        let data: any = new FormData();

        for (let file of files) {
            data.append('files[]', file);
        }

        for (let key in settings.data) {
            dataElement = settings.data[key];

            if (typeof dataElement === 'string') {
                data.append(key, dataElement);

            } else {
                data.append(key, JSON.stringify(settings.data[key]));

            }

        }

        const req = new HttpRequest('POST', settings.url, data, this.Request.getUploadHeaders(this.httpConfig));

        return new Promise((resolve, reject) => {
            this.Http.request(req).subscribe((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.callback(event);

                } else if (event instanceof HttpResponse) {
                    let response: any = event.body;

                    return resolve(response.data || response);

                }
            }, (err) => {
                console.error(err);
                if(err.error) this.Toast.pop('error', err.error.message);
            })
        });

    };

    show(options: any) {
        let input = this.createFakeInput() as HTMLInputElement;
        let settings = this.getSettings(options);

        if (settings.accept) {
            input.setAttribute('accept', settings.accept);

        }

        if (settings.multiple) {
            input.setAttribute('multiple', 'multiple');

        }

        return new Promise((resolve, reject) => {
           input.click();
           input.addEventListener('change', () => this.upload(input.files as unknown as any[], settings).then(resolve, reject));
        });

    };
}

@Injectable({ providedIn: 'root' })
export class UploaderService {

    constructor(
        private Request: RequestService,
        private Http: HttpClient,
        private Toast: ToastService,
    ) {

    }

    init(callback: any, data?: any) {
        return new UploaderClass(this.Request, this.Http, callback, data, this.Toast);
    }
}
