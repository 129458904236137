import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { Entity } from '@proman/services/entity.service';
import * as lodash from 'lodash';
import { deepCopy, isObject } from '@proman/utils';

@Component({
    selector: 'pm-config-field',
    template: `
        @if (item && item.form) {
            @switch(item.form.type) {
                @case ('string') {
                    <pm-txt [value]="item.value"
                            [config]="{ type: 'text' }"
                            (onChange)="handleChange($event)"></pm-txt>
                }
                
                @case ('boolean') {
                    @if (item.group === 'PosGlobalOptions') {
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <pro-checkbox [value]="item.value"
                                          [config]="{ indeterminable: (item.value === null || item.value === 'null') }"
                                          (onChange)="handleChange($event)"></pro-checkbox>
                            @if (item.value !== null && item.value !== 'null') {
                                <pro-btn icon="times" theme="warn" (onClick)="setAsIndeterminate()"></pro-btn>
                            }
                        </div>
                    } @else {
                        <pro-checkbox [value]="item.value"
                                      [config]="{}"
                                      (onChange)="handleChange($event)"></pro-checkbox>
                    }
                }

                @case ('number') {
                    <pm-number [value]="item.value"
                               [config]="{}"
                               (onChange)="handleChange($event)"></pm-number>
                }
                
                @case ('integer') {
                    <pm-number [value]="item.value"
                               [config]="{}"
                               (onChange)="handleChange($event)"></pm-number>
                }
                
                @case ('float') {
                    <pm-number [value]="item.value"
                               [config]="{}"
                               (onChange)="handleChange($event)"></pm-number>
                }
                
                @case ('select') {
                    <pro-autoc [value]="item.value"
                               [config]="{ label: '' }"
                               [getOptions]="parseSelectOptions"
                               (onChange)="handleChange($event)"></pro-autoc>
                }

                @case ('multi_select') {
                    <pro-select [value]="item.value"
                                [config]="{ label: '', multiple: true }"
                                [options]="item.form.data"
                                (onChange)="handleChange($event)"></pro-select>
                }

                @case ('select_entity') {
                    <pro-autoc [value]="item.value"
                               [config]="{ label: '', entity: item.form.data['entity'], entityParams: item.form.data['params'] }"
                               (onChange)="handleChangeAutocomplete($event)"></pro-autoc>
                }

                @case('color') {
                    <pm-advanced-color [value]="item.value"
                                       [noLabel]="true"
                                       [config]="{}"
                                       (onChange)="handleChange($event)"></pm-advanced-color>
                }
            }
        } @else {
            <pro-editable-container>
                {{ item.value }}
            </pro-editable-container>
        }
    `
})

export class ConfigFormFieldComponent implements OnInit {
    @Input() item: { group: string; name: string; value: any; form: any};
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    currUser: CurrUser;
    tempValue: string;

    constructor(
        private store: Store<unknown>,
        private Entity: Entity,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        if (!this.item && this.config?.default) {
            this.handleChange(this.config?.default);
        }
        if (this.item && this.item.form && this.item.form.type === 'multi_select') {
            if (!isObject(this.item.form.data[0])) this.item.form.data = this.item.form.data.map((i: string) => ({ id: i, name: i }));
        }
    }

    parseSelectOptions = (query: string) => {
        if (this.item.form?.data) {
            const newArr: any[] = [];
            for (let i = 0; i < this.item.form.data.length; i++) {
                newArr.push({name: this.item.form.data[i]})
            }
            return Promise.resolve().then(() => {
                return newArr;
            })
        } else {
            return null;
        }
    }

    handleChange(value: any) {
        if (this.item.form.type === 'multi_select') {
            let tempValue = deepCopy(this.item.value);
            value = (value as any[]).map((i: any) => i.id);
            (tempValue as any[]).push(...value);
            tempValue = lodash.uniq(tempValue);
            this.onChange.emit(tempValue);
            this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: tempValue });

        } else {
            if (value.name) {
                this.tempValue = value.name;
                this.item.value = value.name;
            } else {
                this.item.value = value;
            }
            this.onChange.emit(value);
            this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: this.item.value});
        }
    }

    handleChangeAutocomplete(value: any) {
        this.item.value = value.id;
        this.onChange.emit(value);
        this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: this.item.value });
    }

    setAsIndeterminate() {
        this.item.value = null;
        this.onChange.emit(null);
        this.Entity.get('config').postName(this.item.group + '/' + this.item.name)({ value: 'null' });
    }
}
