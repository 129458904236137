import { get } from '../../utils';

export const getFieldValue = (field: any, row: any) => {
    let fieldValue;

    if (!field) return null;

    if (field.getValue) {
        fieldValue = field.getValue(row, field);

    } else if (field.key.indexOf('.') !== -1) {
        // field is an attribute of entity's association
        fieldValue = get(row, field.key);

    } else {
        fieldValue = typeof row['_' + field.key] !== 'undefined' ? row['_' + field.key] : row[field.key];

    }

    return fieldValue;
};
