import { Component, SimpleChanges, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { durationFromSeconds, durationToSeconds, mapOption, twoDigit, zeroPad } from '@proman/utils';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { CommonModule } from '@angular/common';
import { PromanSelectComponent } from '@proman/select';
import { PipesModule } from '@proman/shared/pipes/pipes.module';

@Component({
    selector: 'pro-duration-dropdown',
    standalone: true,
    imports: [
        FlexLayoutModule,
        CommonModule,
        PromanSelectComponent,
        PipesModule,
    ],
    template: `
        <div class="DurationDropdown FormItem">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div> {{  config.label | translate }} &nbsp;&nbsp;&nbsp; </div>
                <pro-select [value]="durationData.hours"
                        [config]="{ key: 'id', disableSearch: true }"
                        [options]="hoursOptions"
                        (onChange)="setDurationData('hours', $event)"
                        [disabled]="disabled"
                        fxFlex="40px"
                ></pro-select>
                <span>{{  'hour_shorthand' | translate }}</span>
                <div fxFlex="15px"></div>
                <pro-select [value]="durationData.minutes"
                        [config]="{ key: 'id', disableSearch: true }"
                        [options]="minutesOptions"
                        (onChange)="setDurationData('minutes', $event)"
                        [disabled]="disabled"
                        fxFlex="40px"
                ></pro-select>
                <span>{{ 'minute_shorthand' | translate }}</span>
            </div>
        </div>
    `,
    styles: [
        '.DurationDropdown { margin-top: -2px; }',
        '.DurationDropdown-InputContainer { margin-top: -12px; }',
        ':host .FixAlignMargin { margin-top: 1px; }',
    ]
})

export class PromanDurationDropdownComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any = {};
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    viewVal: any = {};
    hoursValues: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
    minutesValues: string[] = ['00', '01', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '59'];
    hoursOptions: any;
    minutesOptions: any;
    duration: number = 0 * 60 * 60 + 30 * 60;
    durationData: any = { hours: '00', minutes: '00' };

    ngOnInit() {

        this.hoursOptions = this.hoursValues.map(mapOption);
        this.minutesOptions = this.minutesValues.map(mapOption);

        if (this.value || this.config.setZeroValue) {
            this.calculateDurationOptions();

        }

    }

    ngOnChanges(changes: SimpleChanges) {
        let config = changes.config;
        let value = changes.value;

    }

    calculateDurationOptions() {
        const durationInMinutes = this.value / 60;

        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes - hours * 60;

        this.durationData.hours = twoDigit(hours);
        this.durationData.minutes = twoDigit(minutes);

    }

    parseValue() {
        let days = parseInt(this.viewVal.days);
        let hours = parseInt(this.viewVal.hours);
        let minutes = parseInt(this.viewVal.minutes);
        let seconds = parseInt(this.viewVal.seconds);

        return durationToSeconds(days, hours, minutes, seconds);
    }

    set(key: any, value: any) {
        let seconds;

        seconds = this.parseValue();

        this.onChange.emit(seconds);
    }

    setDurationData(property: string, value: string) {
        const durationData = this.durationData;

        durationData[property] = value;

        this.value = (parseInt(durationData.hours) * 60 + parseInt(durationData.minutes)) * 60;

        this.onChange.emit(this.value);

    }

}
