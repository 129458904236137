import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ToastService } from '@proman/services/toast.service';

@Component({
    selector: 'pm-clipboard-copy-btn',
    template: `
        <input [(ngModel)]="copy" class="ClipboardCopyInput" #input>
        <pro-btn class="ClipboardCopyBtn"
                (onClick)="copyToClipboard()"
                icon="clone"></pro-btn>
    `,
    styles: ['.ClipboardCopyInput { position: absolute; top: -9999px; left: -9999px; }']
})

export class ClipboardCopyBtnComponent {
    @ViewChild('input', { static: true }) input: ElementRef;
    @Input() copy: string;

    constructor(
        private Toast: ToastService
    ) {}

    copyToClipboard() {
        this.input.nativeElement.select();

        document.execCommand('Copy');

        this.Toast.pop('info', 'copied_to_clipboard', { value: this.copy });
    }

}
