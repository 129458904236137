import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { QueryExpressionService } from '../../services/query-expression.service';
import { twoDigit } from '../../utils';
import { capitalize } from 'lodash';
import moment from 'moment';
import { FilterService } from '../../services/filter.service';
import { MatLegacyDialog } from "@angular/material/legacy-dialog";

@Component({
    selector: 'pro-table-filter-date',
    template: `
        <div fxLayout="row" class="TableFilter-Date" [ngClass]="{ '_hasTime': showTime }">
            <pro-datepicker [value]="filter.tmpValue.from"
                           (onChange)="setFrom($event)"
                           [config]="{ label: 'search_date_from', min: min, max: max, hideTime: true, preventInvalid: true }"
                           [ngClass]="{ 'TableFilter-Date-Empty': !(filter.tmpValue.from || filter.tmpValue.to) }"></pro-datepicker>
            <pro-time-dropdown *ngIf="filter.tmpValue?.from && showTime" [value]="filter.tmpValue?.from" [hideShorthand]="true" (onChange)="setTime('from', $event)"></pro-time-dropdown>
            <pro-datepicker [value]="filter.tmpValue.to"
                           (onChange)="setTo($event)"
                           [config]="{ label: 'search_date_to', min: min, max: max, hideTime: true, preventInvalid: true }"
                           [ngClass]="{ 'TableFilter-Date-Empty': !(filter.tmpValue.from || filter.tmpValue.to) }"></pro-datepicker>
          <pro-time-dropdown *ngIf="filter.tmpValue?.to && showTime" [value]="filter.tmpValue?.to" [hideShorthand]="true" (onChange)="setTime('to', $event)"></pro-time-dropdown>
        </div>
    `,
    styles: [`
        .TableFilter-Date { min-width: 100px; }
        .TableFilter-Date--TimeField { height: 18px; margin-top: 14px; z-index: 2; }
        pro-datepicker { overflow: hidden; }
        ._hasTime > pro-datepicker { width: 75px; }
        pro-datepicker.TableFilter-Date-Empty { width: 55px!important; }
    `]
})

export class TableFilterDateComponent implements OnChanges {
    @Input() value: any;
    @Input() showTime: boolean;
    @Input() min: any;
    @Input() max: any;
    @Output() onChange: EventEmitter<string> = new EventEmitter();
    filter: any = { tmpValue: {} };
    timeFrom: any = { h: 0, m: 0};
    timeTo: any = { h: 23, m: 59};
    useWorkdayTimeLimits: boolean;

    constructor(
        private MatLegacyDialog: MatLegacyDialog,
        private QueryExpression: QueryExpressionService,
        private Filter: FilterService,
        private cd: ChangeDetectorRef,
    ) {
      this.useWorkdayTimeLimits = this.Filter.getWorkdayTimeLimitStatus();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
          let filterValue: any = changes.value.currentValue;

          if (typeof filterValue === 'object' && filterValue) {
            // legacy format
            this.filter.tmpValue.from = filterValue.from;
            this.filter.tmpValue.to = filterValue.to;

          } else if (typeof filterValue === 'string') {
            let range: any = this.QueryExpression.parseDateRange(filterValue);

            this.filter.tmpValue.from = range.from;
            this.filter.tmpValue.to = range.to;

            if (this.showTime) {

              if (this.filter.tmpValue.from) {
                this.timeFrom.h = moment(this.filter.tmpValue.from).hours();
                this.timeFrom.m = moment(this.filter.tmpValue.from).minutes();

              }

              if (this.filter.tmpValue.to) {
                this.timeTo.h = moment(this.filter.tmpValue.to).hours();
                this.timeTo.m = moment(this.filter.tmpValue.to).minutes();

              }

            }

          } else {
            this.filter.tmpValue = {};

          }
        }

    }

    setFilter(from: string, to: string, changedProperty: string) {

        if (changedProperty === 'from' && moment(to) < moment(from) && !this.showTime) {
            to = moment(from).endOf('day').utc().format();
        }

        if (changedProperty === 'to' && from && moment(from) > moment(to) && !this.showTime) {
            from = moment(to).startOf('day').utc().format();
        }

        this.filter.value = this.QueryExpression.dateTimeRange(from, to);

        this.onChange.emit(this.filter.value);
    }

    setFrom(from: string) {
        from = moment(from).minutes(this.timeFrom.m).hours(this.timeFrom.h).format();

        if (!this.showTime) {
            const offset = moment(from).utcOffset();
            if (this.useWorkdayTimeLimits) {
              from = this.Filter.getSystemOptionsDayStartTime(
                moment(from).format()
              );
            } else {
              from = moment(from).startOf('d').format();
            }
            from = moment(from).add(offset, 'm').format();
        }

        this.filter.tmpValue.from = from;
        this.setFilter(from, this.filter.tmpValue.to, 'from');

    };

    setTo(to: string) {
        to = moment(to).minutes(this.timeTo.m).hours(this.timeTo.h).format();


        if (!this.showTime) {

          if (this.useWorkdayTimeLimits) {
            to = this.Filter.getSystemOptionsDayEndTime(
              moment(to).format()
            );
          } else {
            to = moment(to).endOf('d').format();
          }

            // to = moment(to).add(offset, 'm').format();

        }

        this.filter.tmpValue.to = to;
        this.setFilter(this.filter.tmpValue.from, to, 'to');

    }

    formatTimeUnit = (value: number|string) => {
        return twoDigit(value);
    };

  setTime = (property: string, value: any) => {
    const newValue = moment(value, 'HH:mm:ss');
    this['time' + capitalize(property)].m = newValue.minutes();
    this['time' + capitalize(property)].h = newValue.hours();
    this[`set${capitalize(property)}`](this.filter.tmpValue[property]);
  };

}
