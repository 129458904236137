import { Action, createReducer, on } from '@ngrx/store';
import { setStorableData } from './parameters.actions';

export interface ParametersState {
    data: {
        savedData: { [key: string]: unknown };
    };
}

export const initialState: ParametersState = {
    data: {
        savedData: {

        }
    },
};

export const parametersReducer = createReducer(
    initialState,
    on(setStorableData, (state: ParametersState, action) => {
       return {
           ...state,
           data: {
               ...state.data,
               savedData: {
                   ...state.data.savedData,
                   [action.payload.key]: action.payload.data
               }
           }
       }
    }),
);

export function reducer(state: ParametersState, action: Action): ParametersState {
    return parametersReducer(state, action);
}
