import { NgModule } from '@angular/core';
import { FaComponent } from './fa6.component';

@NgModule({
    declarations: [
        FaComponent
    ],
    imports: [],
    exports: [FaComponent]
})
export class Fa6Module {}
