import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'pro-parameter-group-name',
    template: `
        <pro-text-simple [value]="tmpVal"
                         [config]="config"
                         (onChange)="handleChange($event)"
                         [disabled]="disabled"></pro-text-simple>
    `
})

export class ParameterGroupNameComponent implements OnInit{
    @Input() value: any;
    @Input() config: any;
    @Input() disabled: any;
    @Input() parameter: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    tmpVal: any;
    parsedVal: any;

    ngOnInit() {
        try {
            this.parsedVal = JSON.parse(this.value);
        } catch (e) {
            return;
        }

        this.tmpVal = this.parsedVal?.name;
        
        if (!this.tmpVal) {
            try {
                this.parsedVal = JSON.parse(this.parameter.articleProductionParameter.value);
                this.tmpVal = this.parsedVal?.name;

                this.onChange.emit(this.parameter.articleProductionParameter.value);
            } catch (e) {

            }
        }
        
    }

    handleChange(value: any) {
        if (this.parsedVal) {
            this.parsedVal.name = value;
        } else {
            this.parsedVal = Object.assign({}, { name: value });
        }
        this.onChange.emit(JSON.stringify(this.parsedVal));
    }
}
