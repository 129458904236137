import { Injectable } from '@angular/core';
import { TableEntitySearchParametersService } from '../table/services/table-entity-search-parameters.service';
import { QueryService } from './query.service';

@Injectable({ providedIn: 'root' })
export class Search {
    queryWrapper: any = {};
    customQuery: any = null;
    extraParameters: any = null;
    filters: any = [];
    decorator: any;
    callback: any;
    filter: any;
    url: any;
    Entity: any;
    isRestore = false;

    constructor(
        private Query: QueryService,
        private EntitySearchParameters: TableEntitySearchParametersService,
    ) {
    }

    processResult = (response: any) => {
        let data = response.data || response;

        if (this.decorator) {

            for (let iter = 0; iter < data.length; iter++) {
                this.decorator(data[iter]);
            }

        }

        this.queryWrapper.results = data;

        return data;
    };

    async exec(val: any): Promise<any[]> {
        let filters = this.filters;
        let result: any;

        if (this.isRestore) {
            this.isRestore = false;
            result = [];

        } else if (filters.length) {
            filters.forEach((callback: any) => {
                callback(val);
            });
            result = [];

        } else if (val && this.customQuery) {
            result = this.processResult(await this
                .customQuery(val));

        } else if (!this.url || (val === '' || val === null) || !this.Entity) {
            result = []

        } else {
            result = this.processResult(await this.Query
                .get(this.url, null, null, 10, null, this.EntitySearchParameters.get(this.Entity, val), this.extraParameters));

        }

        return Promise.resolve(result);
    }

    clear() {
        this.queryWrapper.model = null;
        this.queryWrapper.results = [];
    }

    unregister() {
        this.url = null;
        this.callback = null;
        this.decorator = null;
        this.extraParameters = null;
        this.filters = [];
        this.queryWrapper.model = null;
        this.queryWrapper.searchText = '';
    }

    resultClicked(result: any) {

        if (this.callback) {
            this.callback(result);

        }

        this.clear();
    }

    register(Entity: any, callback: any, decorator: any = null, extra: any = {}) {
        this.Entity = Entity;
        this.url = Entity.searchUrl;
        this.callback = callback;
        this.decorator = decorator;
        this.filter = null;
        this.filters = [];
        this.extraParameters = extra;
    }

    useAsFilter(callback: any) {
        // allow multiple clients listening for a single search field value
        this.filters.push(callback);
    }

    setCustomQuery(queryFn: any, callback: any) {
        this.customQuery = queryFn;
        this.callback = callback;

        this.clear();
    }

    registerForm(queryWrapper: any) {
        this.queryWrapper = queryWrapper;
    }

    restoreQuery(value: any) {

        if (typeof value === 'string') {
            this.queryWrapper.searchText = value;

        } else {
            this.queryWrapper.model = value;
            this.queryWrapper.searchText = value.name;

        }

        this.isRestore = true;
    }
}
