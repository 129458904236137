import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { Expression } from '@proman/interfaces/entity-interfaces';
import { TableConfig } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pro-expression-select',
    template: `
        <pro-dialog-title title="functions"></pro-dialog-title>
        <div mat-dialog-content>
            <pro-table [config]="tableConfig"></pro-table>
        </div>
        <pro-dialog-actions [variant]="'close'" [isCallbackHidden]="true"></pro-dialog-actions>
    `
})

export class ExpressionSelectDialogComponent {
    tableConfig: TableConfig;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<ExpressionSelectDialogComponent>,
    ) {
        this.tableConfig = {
            entity: 'expression',
            aclRoot: null,
            fields: [
                {
                    name: 'name',
                    key: 'name'
                },
                {
                    name: 'expression',
                    key: 'body',
                    filter: null
                }
            ],
            onRowClick: (expression: Expression) => this.dialogRef.close(expression)
        };
    }

}
