import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { isNumber } from '../../utils';

@Component({
    selector: 'pro-pagination',
    template: `
        <div class="pagination"
             [ngClass]="{ simple: simple }"
             fxLayout="row"
             fxLayoutAlign="end center"
             *ngIf="visiblePages.length">
            <a class="pagination__item pagination__start"
               *ngIf="!simple"
               [proOverlay]="{ type: 'button', data: '1' }"
               [ngClass]="{ 'pagination__item--disabled': currPage === 0 }"
               (click)="setPage(0)">«</a>
            <a class="pagination__item  pagination__prev"
               [ngClass]="{ 'pagination__item--disabled': currPage === 0 }"
               (click)="setPage(currPage - 1)">‹</a>
            <a class="pagination__item pagination__page{{ page + 1 }}"
               [ngClass]="{ 'pagination__item--active': currPage == page }"
               (click)="setPage(page)"
               *ngFor="let page of visiblePages">{{ page + 1 }}</a>
            <a class="pagination__item  pagination__next"
               [ngClass]="{ 'pagination__item--disabled': currPage === pages.length - 1 }"
               (click)="setPage(currPage + 1)">›</a>
            <a class="pagination__item  pagination__end"
               *ngIf="!simple"
               [proOverlay]="{ type: 'button', data: pages.length.toString() }"
               [ngClass]="{ 'pagination__item--disabled': currPage === pages.length - 1 }"
               (click)="setPage(pages.length - 1)">»</a>
        </div>
    `,
  styles: [`
    .pagination {
      margin: 23px 0;
    &.simple {
       margin: 0;
     }

    &__item:not(.mat-tab-link) {
       padding: 4px 12px;
       margin: 0 2px;
       text-decoration: none;
       font-size: 14px;
       color: #2196f3;
       background-color: #ffffff;
       cursor: pointer;
       display: inline-block;;
       border-radius: 8px;

    &:hover {
       color: #0a6ebd;
       background-color: #E7F4FF;
       text-decoration: none;
     }

    }

    &__item--disabled:not(.mat-tab-link) {
       color: #bbbbbb;
       background-color: #ffffff;
       cursor: not-allowed;

    &:hover {
       color: #bbbbbb;
       background-color: #ffffff;
     }

    }

    &__item--active:not(.mat-tab-link) {
       color: #ffffff;
       background-color: #2196f3;
     }

    }
  `]
})

export class PaginationComponent implements OnChanges {
    @Input() currPage: number;
    @Input() limit: number;
    @Input() total: number;
    @Input() simple: boolean;
    @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

    pages: number[];
    visiblePages: number[];
    pageCount: number;

    constructor() {
        this.pages = [];
        this.visiblePages = [];
        this.pageCount = 5;
        this.currPage = 0;
        this.limit = 0;
        this.total = 0;
    }

    ngOnChanges(changes: any) {
        if (this.simple) this.pageCount = 3;

        this.limitPagination();
    }

    setPages() {
        let pages = [];
        let pagesLength = 0;
        let iter;

        for (iter = 0; iter < this.total; iter += this.limit) {
            pages.push(pagesLength++);
        }

        this.pages = pages;
    }

    setPage(value: any) {

        if (value !== this.currPage && value >= 0 && value <= this.pages.length - 1) {
            this.onSelect.emit(value);

            this.currPage = value;

            this.limitPagination();
        }
    }

    limitPagination() {
        let total = this.total;
        let limit = this.limit;
        let page = this.currPage;
        let pageCountHalf = Math.floor(this.pageCount / 2);
        let limitedPages = [];
        let totalPageCount;
        let start;
        let end;
        let iter;

        if (isNumber(total) && isNumber(limit)) {
            totalPageCount = Math.ceil(total / limit);

            if (totalPageCount <= this.pageCount) {
                start = 0;
                end = totalPageCount;
            } else if (page <= pageCountHalf) {
                start = 0;
                end = this.pageCount;
            } else if (page >= (totalPageCount - pageCountHalf)) {
                start = totalPageCount - this.pageCount;
                end = totalPageCount;
            } else {
                start = page - pageCountHalf;
                end = page + pageCountHalf + 1;
            }

            if (end - start > 1) {

                for (iter = start; iter < end; iter++) {
                    limitedPages.push(iter);
                }
            }

            this.visiblePages = limitedPages;

            this.setPages();
        }
    }
}
