import { NgModule } from '@angular/core';
import { SrefPipe } from './sref.pipe';

@NgModule({
  declarations: [
    SrefPipe,
  ],
  exports: [
    SrefPipe
  ]
})

export class FrontendPipesModule {

}
