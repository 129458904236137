import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAttacher } from './attacher/attacher';
import { CustomAttacherTriggerService } from './attacher-trigger.service';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
    imports: [CommonModule, OverlayModule],
    declarations: [],
    providers: [CustomAttacher, CustomAttacherTriggerService],
    exports: [OverlayModule],
})
export class CustomAttacherModule {}
