import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'pm-click-to-call',
    template: `
    <a class="ClickToCall"
       [href]="('tel:') + phoneString"
       fxLayout="row"
       fxLayoutAlign="start center"
       proClickStopPropagation>
        <fa name="phone" class="RightPadding"></fa>
        <span *ngIf="!config.isCompact">{{ phoneString }}</span>
    </a>
    `
})

export class ClickToCallComponent implements OnInit, OnChanges {
    @Input() item: any;
    @Input() value: string;
    @Input() config: any;
    phoneString: string = '';

    constructor() {}

    ngOnInit() {
        this.phoneString = this.value || this.item && this.item[this.config.key] || '';
    }

    ngOnChanges() {
        this.ngOnInit();
    }

}
