import { debounceTime } from '@proman/rxjs-common';
import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { InputErrorStateMatcher } from '@proman/validators/input-error-state-matcher';
import { get, isDefinedNotNull } from '@proman/utils';
import { AutocompleteConfig } from '@proman/interfaces/object-interfaces';
import { ImagePathService } from '@proman/services/image-path.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import { MatLegacyAutocompleteModule, MatLegacyAutocompleteTrigger } from "@angular/material/legacy-autocomplete";
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { GlobalOverlayModule } from '@proman/overlay/global-overlay.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { PromanContrastingColorDirective } from '@proman/shared/directives/proman-contrasting-color.directive';

@Component({
    selector: 'pro-autoc',
    imports: [
        CommonModule,
        MatLegacyAutocompleteModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SharedDirectivesModule,
        GlobalOverlayModule,
        PipesModule,
        PromanContrastingColorDirective,
    ],
    standalone: true,
    template: `
        <mat-form-field [floatLabel]="config.floatLabel || 'auto'" [attr.data-name]="config.label"
                        [ngClass]="{ 'important-input': config.important && !isDefinedNotNull(value) }"
                        [color]="config.important && !isDefinedNotNull(value) ? 'warn' : 'primary'" [attr.data-get-type]="optionsType">
            <input #box
                   matInput
                   (focus)="handleFocus()"
                   [required]="config.required"
                   [placeholder]="config.label | translate"
                   [matAutocomplete]="auto"
                   [errorStateMatcher]="matchErrorState"
                   (blur)="handleBlur()"
                   [formControl]="control"
                   autocomplete="off">
            @if (control.errors) {
                <mat-error>
                    @if (control.errors.required) {
                        <span>{{ 'field_is_required' | translate }}</span>
                    }
                </mat-error>
            }
            <mat-autocomplete #auto="matAutocomplete"
                              autoActiveFirstOption
                              (optionSelected)="handleChange($event)"
                              [displayWith]="displayFn"
                              (opened)="handleOpen()"
                              (closed)="handleClose()"
            >
                @if (config.isNone) {
                    <mat-option [value]="null">-</mat-option>
                }
                @for (option of options | async; track $index) {
                    <mat-option [value]="option"
                                title="{{ getOptionName(option) }}"
                                [attr.data-test-id]="option?.id"
                                [ngStyle]="config.hasColors ? { background: '#' + (option.color) } : {}"
                                [proContrastingColor]="config.hasColors ? option.color : null">
                        @if (config.imageKey) {
                            <img [src]="option[config.imageKey] | proThumbPath"
                                 onerror="this.onerror=null;this.src='assets/images/placeholder.png';"
                                 [proOverlay]="{ type: 'image', previewSrc: iconToPreview(option[config.imageKey]), data: option[config.imageKey] | proThumbPath:true   }"
                                 [ngStyle]="{ height: (config.imageSize || 50) + 'px', width: (config.imageSize || 50) + 'px' }" />
                        }
                        <span> {{ getOptionName(option) }} </span>
                        @if (config.descKey) {
                            <span class="ColorGray">
                                {{ option[config.descKey] }}
                            </span>
                        }
                    </mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    `,
    styles: [`
        mat-form-field {
            width: 100%;
        }

        img {
            vertical-align: middle;
            border-radius: 50%;
        }
    `]
})

export class PromanAutocompleteComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
    @Input() value: any;
    @Input() options: Promise<any[]> | undefined | null;
    @Input() config: AutocompleteConfig = {label: ''};
    @Input() disabled: boolean;
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() getOptions: (query: string) => Promise<any[]>;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('box', { static: true }) box: ElementRef;
    @ViewChild('box', { read: MatLegacyAutocompleteTrigger }) autoComplete: MatLegacyAutocompleteTrigger;

    matchErrorState: any = new InputErrorStateMatcher();
    entityInstance: EntityInterface;
    valueChanges: Subscription;
    optionsType: number;
    optionsLimit: number = 50;
    optionsParams: any[];
    focusCounter: number = 0;

    constructor(
        private Entity: Entity,
        private cd: ChangeDetectorRef,
        private ImagePath: ImagePathService,
    ) {

    }

    ngOnInit() {
        window.addEventListener('scroll', this.handleScrollEvent, true);
        const config = this.config;

        if (config && config.entity) {
            this.entityInstance = this.Entity.get(config.entity);

        }

        if (config.getOptions) this.getOptions = config.getOptions;

        if (this.value) this.control.setValue(this.value);

        if (this.disabled) this.control.disable();

        this.valueChanges = this.control.valueChanges.pipe(
            debounceTime(this.config.debounce || 500))
            .subscribe((val: any) => {

                this.handleSearch(val);

                if ((val === '' || val === null) && this.value) {
                    this.emitValueChange(null);

                }

            });

    }

    ngAfterContentInit() {
        const config = this.config;

        if (config && config.autofocus) {
            setTimeout(() =>
                this.box.nativeElement.focus());
        }

        if (config && config.autoSelectSingleOption) {
            setTimeout(() =>
                this.autoSelectSingleOption());
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        const config = changes.config;
        const options = changes.options;
        const value = changes.value;
        let entityConfig: any;

        if (config && config.currentValue.entity) {
            entityConfig = {name: config.currentValue.entity};

            if (config.currentValue.entityGet) {
                entityConfig.get = [config.currentValue.entityGet];

            }

            this.entityInstance = this.Entity.get(entityConfig);

        }

        if (this.control && changes.disabled) {

            if (changes.disabled.currentValue) {
                this.control.disable();

            } else {
                this.control.enable();

            }

        }

        if (value && this.control) {
            this.control.setValue(value.currentValue || null);

        }

        if (options) {
            this.cd.markForCheck();
            this.cd.detectChanges();

        }

    }

    ngOnDestroy() {
        if (this.valueChanges) this.valueChanges.unsubscribe();
    }

    displayFn = (item: any) => {
        const config = this.config;

        if (item && typeof item !== 'string') {
            const defaultResult = item[this.config.displayKey || 'name'];

            if (config.getOptionName) {
                return !item._nameChanged && config.getOptionName(item) || defaultResult;
            }

            return defaultResult;

        }

        return item;
    };

    handleFocus(): void {
        if (this.disabled) return this.options = undefined;

        this.box.nativeElement.select();
        let searchQuery = '';

        if (this.focusCounter > 0) {
            try {
                searchQuery = this.box.nativeElement.value;
            } catch (e) {

            }
        }

        if (typeof this.options === 'undefined' || this.config.cache === false) {
            this.handleSearch(searchQuery);

        }

        this.focusCounter++;

    }

    handleSearch = (query: string) => {
        const queryField = this.config.query || 'name';
        let params = {};

        // Execute query only for string (is needed because of md-autocomplete component issue)
        if (typeof query === 'string') {

            if (this.entityInstance) {

                if (this.config.entityParams) {

                    if (typeof this.config.entityParams === 'string') {
                        params = JSON.parse(this.config.entityParams);

                    } else {
                        params = this.config.entityParams;

                    }

                }

                if (this.config.entityGet) {
                    this.optionsType = 1;
                    this.options = this.getOptions1(query, queryField, params);

                } else {
                    const searchParams: any = Object.assign({}, {[queryField]: query}, params);

                    if (this.config.searchFields) {
                        delete searchParams[queryField];
                        const searchData = {};

                        this.config.searchFields.forEach((field: string) => searchData[field] = query);

                        searchParams.search = searchData;
                    }

                    if (this.config.emptyCollections) {
                        searchParams.emptyCollections = this.config.emptyCollections;
                    }

                    this.optionsType = 2;
                    this.options = this.getOptions2(searchParams);

                }

            } else if (this.getOptions) {
                this.optionsType = 3;
                this.options = this.getOptions(query);

            } else {
                this.optionsType = 4;
                this.onSearch.emit(query);

            }

        }

        this.triggerUpdateView();

    };

    getOptions1 = (query: string, queryField: string, params: any) => {
        this.optionsParams = [query, queryField, params];

        return this.entityInstance[this.config.entityGet](Object.assign({}, {[queryField]: query}, params, {limit: this.optionsLimit}));
    };

    getOptions2 = (searchParams: any) => {
        this.optionsParams = [searchParams];

        searchParams.limit = this.optionsLimit;

        return this.entityInstance
            .search(searchParams)
            .then((response: any) => {

                setTimeout(() => this.handleOpen(), 250);

                if (this.config.getOptionName) {
                    return response.map((item: any) => {
                        item.name = this.config.getOptionName(item);
                        item._nameChanged = true;

                        return item;
                    });

                } else if (this.config.transform) {
                    response.forEach((item: any) => this.config.transform(item));

                    return response;

                } else {
                    return response;

                }

            });
    };

    handleChange($event: any) {
        this.emitValueChange($event.option.value);
        this.box.nativeElement.blur();
    }

    emitValueChange(val: any) {

        if (this.config.required) {
            if (val) this.onChange.emit(val);

        } else {
            this.onChange.emit(val);

        }

    }

    handleBlur() {
        if (!this.config.preventClear) this.control.setValue(this.control.value || null);
    }

    getOptionName(option: any) {
        return get(option, this.config.displayKey || 'name');
    }

    handleContainerScroll = (event: any) => {

        const container = event.target;

        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            this.loadMoreOptions();

        }

    };

    handleOpen = () => {
        const container = document.querySelector('.mat-autocomplete-panel');

        if (container) container.addEventListener('scroll', this.handleContainerScroll);
        if (container) {
            const dialogCheck = document.querySelector('.mat-dialog-content');
            if (!dialogCheck) {
                container.setAttribute('style', 'min-width: 400px;');
            }
            // document.querySelectorAll('.mat-autocomplete-panel mat-option span span').forEach(item => {
            //     item.setAttribute('style', 'overflow: hidden; white-space: nowrap; text-overflow: ellipsis;')
            // });
        }
    };

    handleClose = () => {

    };

    loadMoreOptions = () => {

        if (this.optionsType === 1 || this.optionsType === 2) {

            this.options.then((response: any[]) => {

                if (response.length === this.optionsLimit) {

                    this.optionsLimit += 50;

                    this.options = this[`getOptions${this.optionsType}`](...this.optionsParams);
                    this.triggerUpdateView();

                }

            })

        }

    };

    autoSelectSingleOption = async () => {
        await this.handleSearch('');

        if (this.options) {
            this.options.then((data: any[]) => {
                if (data?.length === 1) this.onChange.emit(data[0]);
            });
        }

    };

    triggerUpdateView = () => {
        if (this.options) this.options.then((data: any[]) => this.cd.markForCheck());
    };

    isDefinedNotNull = (value: any) => isDefinedNotNull(value);

    iconToPreview(value: PromanFile) {
        return this.ImagePath.getFile(value, 'png');
    }

    handleScrollEvent = (event: any): void => {
        if(this.autoComplete.panelOpen)
          // this.autoComplete.closePanel();
            this.autoComplete.updatePosition();
    };

}
