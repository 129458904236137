import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';

import { DYNAMIC_FIELDS_TABLES, DynamicFieldsService } from '@proman/services/dynamic-fields.service';
import { findByProperty } from '@proman/utils';
import { camelCase } from 'lodash';
import { DynamicField } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-dynamic-fields',
    template: `
        <div>
            <div *ngFor="let field of fields" fxLayout="row" pmClipboard
                 [attr.data-dynamic-field]="field.alias"
            >
                <ng-container [ngSwitch]="field.type">
                    <pm-txt *ngSwitchCase="'string'"
                            [value]="field.value"
                            [config]="{ label: field.name, required: field.required }"
                            (onChange)="handleChange(field, $event)"
                            [disabled]="disabled"></pm-txt>

                    <pm-txt *ngSwitchCase="'text'"
                            [value]="field.value"
                            [config]="{ label: field.name, required: field.required, type: 'textarea' }"
                            (onChange)="handleChange(field, $event)"
                            [disabled]="disabled"></pm-txt>

                    <pm-txt *ngSwitchCase="'float'"
                            [value]="field.value"
                            [config]="{ label: field.name, parseNumber: true , required: field.required }"
                            (onChange)="handleChange(field, $event)"
                            [disabled]="disabled"></pm-txt>

                    <pm-txt *ngSwitchCase="'integer'"
                            [value]="field.value"
                            [config]="{ label: field.name, parseNumber: true , required: field.required }"
                            (onChange)="handleChange(field, $event)"
                            [disabled]="disabled"></pm-txt>

                    <pro-checkbox *ngSwitchCase="'boolean'"
                                 [value]="field.value"
                                 [config]="{ label: field.name }"
                                 (onChange)="handleChange(field, $event)"
                                 [disabled]="disabled"></pro-checkbox>

                    <pro-select *ngSwitchCase="'select'"
                            [value]="field.value"
                            [config]="{ label: field.name, key: 'id', required: field.required }"
                            [options]="field.metadata"
                            (onChange)="handleChange(field, $event)"
                            [disabled]="disabled"></pro-select>

                    <pro-datepicker *ngSwitchCase="'date'"
                                   [value]="field.value"
                                   [config]="{ label: field.name, required: field.required, hideTime: true, emitTimeZoneValue: true }"
                                   (onChange)="handleChange(field, $event)"
                                   [disabled]="disabled"></pro-datepicker>

                    <pro-datepicker *ngSwitchCase="'datetime'"
                                   [value]="field.value"
                                   [config]="{ label: field.name, required: field.required, hideTime: false }"
                                   (onChange)="handleChange(field, $event)"
                                   [disabled]="disabled"></pro-datepicker>

                </ng-container>
                <pm-clipboard-copy-btn *ngIf="field.type === 'string' || field.type === 'text' " [copy]="field.value"></pm-clipboard-copy-btn>
            </div>
        </div>
    `
})

export class DynamicFieldsComponent implements OnInit, OnChanges {
    @Input() entityName: EntityNameType;
    @Input() entity: { id: number };
    @Input() isCreate: boolean;
    @Input() isCreateAll: boolean;
    @Input() disabled: boolean;
    @Output() createFieldsEmit: EventEmitter<any> = new EventEmitter<any>();

    options: any = [];
    fields: DynamicField[];
    dynamicFieldEntity: any;
    dynamicFieldValueEntity: any;
    valuesToCreate: any[] = [];

    _inited: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private Entity: Entity,
        private QueryExpression: QueryExpressionService,
        private DynamicFields: DynamicFieldsService,
    ) {
        this.dynamicFieldEntity = this.Entity.get('dynamic_field');
        this.dynamicFieldValueEntity = this.Entity.get('dynamic_field_value');
    }

    ngOnInit() {

        this.DynamicFields
            .getFieldsForEntity(this.entityName, (this.isCreate ? { required: true } : {}))
            .then((response: any) => {
                this.fields = response;

                if (!this.isCreate) this.initFields();
                if (this.isCreate) this.createFieldsEmit.emit({ isValid: !this.fields?.length, values: [] });

            });

    }

    ngOnChanges() {
        if (this.entity && this._inited) this.ngOnInit();
    }

    initFields() {
        this.dynamicFieldValueEntity
            .search({ 'dynamicField.tableName': DYNAMIC_FIELDS_TABLES[this.entityName], 'entityId': this.entity.id, 'join': ['dynamicField'] })
            .then((values: any) => {
                if (values) values.forEach((item: any) => this.bindValue(item));

                this._inited = true;

                this.cd.markForCheck();
            });
    }

    handleChange(field: DynamicField, value: any) {
        if (this.isCreate) return this.handleCreateField(field, value);

        if (!field.fieldId) {
            this.DynamicFields
                .createFieldValue({ entityId: this.entity.id, dynamicField: field.id, value })
                .then((response: any) => {
                    field.fieldId = response.data;
                });

        } else {
            this.dynamicFieldValueEntity
                .update({ id: field.fieldId, value })
                .then(() => {
                  field.value = value;
                });

        }

    }

    bindValue = (item: any) => {
        const getTypeKey = (type: string) => {
            let result;

            switch (type) {
                case 'date':
                case 'datetime':
                case 'integer':
                case 'float':
                case 'boolean':
                case 'text':
                    result = camelCase(`${type}_value`);
                    break;

                default:
                    result = 'stringValue'

            }

            return result;
        };

        for (let field of this.fields) {
            if (field.id === item.dynamicField.id) {
                Object.assign(field, { fieldId: item.id, value: item.value || item[getTypeKey(item.dynamicField.type)]});
                break;

            }
        }

    };

    handleCreateField(field: any, value: any) {
        let _field = findByProperty(this.valuesToCreate, 'dynamicField', field.id);

        if (_field) {
            field.value = value;
        } else {
            this.valuesToCreate.push({ dynamicField: field.id, value });
        }

        this.createFieldsEmit.emit({ isValid: this.valuesToCreate.length === this.fields.length, values: this.valuesToCreate });

    }

}
