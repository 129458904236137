import { Injectable } from '@angular/core';
import { groupBy } from '../../utils';
import { QueryExpressionService } from '../../services/query-expression.service';
import { Entity } from '../../services/entity.service';
import { FilterService } from '../../services/filter.service';
import { ParametersOptionsService } from '../../services/parameters-options.service';
import { Material, MaterialCategory, Operation, Workplace } from '../../interfaces/entity-interfaces';

@Injectable({ providedIn: 'root' })
export class TableParametersFormatterService {
    mappings: {
        material: { [key: number]: Material };
        workplace: { [key: number]: Workplace };
        operation: { [key: number]: Operation };
        material_category: { [key: number]: MaterialCategory };
    } = {
        material: {},
        workplace: {},
        operation: {},
        material_category: {},
    };

    constructor(
        private QueryExpression: QueryExpressionService,
        private Entity: Entity,
        private Filter: FilterService,
        private ParametersOptions: ParametersOptionsService
    ) {

    }

    preloadParameters = () => {
        return Promise.all([
                this.loadMaterials(),
                this.loadWorkplaces(),
                this.loadOperations(),
                this.loadMaterialCategories()
            ])
            .then(([materials, worklaces, operations, matCategories]) => {
                materials?.forEach((material) => this.mappings.material[material.id] = material);
                worklaces?.forEach((workplace) => this.mappings.workplace[workplace.id] = workplace);
                operations?.forEach((operation) => this.mappings.operation[operation.id] = operation);
                matCategories?.forEach((category) => this.mappings.material_category[category.id] = category);

                return true;
            });
    };

    loadDropdownOptions(): any {
        return this.ParametersOptions
            .search({ entity: 'parameter_dropdown_option', entityParams: { translate: true }, emptyResponse: true });
    }

    loadMaterials(): Promise<Material[]> {
        return this.ParametersOptions
            .search({ entity: 'material', entityParams: { translate: true }, emptyResponse: true });
    }

    loadMaterialCategories(): Promise<MaterialCategory[]> {
        return this.ParametersOptions
            .search({ entity: 'material_category', entityParams: { translate: true }, emptyResponse: true });
    }

    loadWorkplaces(): Promise<Workplace[]> {
        return this.ParametersOptions
            .search({ entity: 'workplace', entityParams: { translate: true }, emptyResponse: true });
    }

    loadOperations(): Promise<Operation[]> {
        return this.ParametersOptions
            .search({ entity: 'operation', entityParams: { translate: true }, emptyResponse: true });
    }

    getGroupTypeParams(groupTypeParams: any) {
        let items = [];
        let paramsGroupedByParentId = groupBy(groupTypeParams, (item: any) => item.parameter.id);
        let param: any;
        let values: string[];

        for (let paramsGroup in paramsGroupedByParentId) {
            values = [];
            let groupIndex = 0;

            for (param of paramsGroupedByParentId[paramsGroup]) {

                values.push(`<br/>(${++groupIndex}) - ${JSON.parse(param.value)?.name} || null`);

                if (param.children) {
                    param.children.forEach((childParam: any) => {
                        if (childParam.parameter) {

                            if (childParam.parameter.type === 'workgroup') {

                                try {
                                    let foo = JSON.parse(childParam.value);
                                    let result = '';

                                    if (foo.operationId) {
                                        result += `${this.Filter.translate('operation')}: ${this.mappings.operation[foo.operationId] ? this.mappings.operation[foo.operationId].name : foo.operationId}`;
                                    }

                                    if (foo.workplaceId) {
                                        if (result.length) result += ',';

                                        result += `${this.Filter.translate('workplace')}: ${this.mappings.workplace[foo.workplaceId] ? this.mappings.workplace[foo.workplaceId].name : foo.workplaceId}`;

                                    }

                                    if (result.length) values.push(`${childParam.parameter.name}: ${result}`);

                                } catch (e) {
                                    values.push(`${childParam.parameter.name}: -`);
                                }

                            } else if (childParam.parameter.type === 'material_category') {

                                try {
                                    let foo = JSON.parse(childParam.value);
                                    let result = '';

                                    if (foo.categoryId) {
                                        result += `${this.Filter.translate('material_category')}: ${this.mappings.material_category[foo.categoryId] ? this.mappings.material_category[foo.categoryId].name : foo.categoryId}`;
                                    }

                                    if (foo.materialId) {
                                        if (result.length) result += ', ';

                                        result += `${this.Filter.translate('material')}: ${this.mappings.material[foo.materialId] ? this.mappings.material[foo.materialId].name : foo.materialId}`;

                                    }

                                    if (result.length) values.push(`${childParam.parameter.name}: ${result}`);

                                } catch (e) {
                                    values.push(`${childParam.parameter.name}: -`);
                                }

                            } else {
                                values.push(`${childParam.parameter.name}: ${childParam.value}`);

                            }

                       }
                    });

                }

            }

            items.push('<b>' + param.parameter.name + ':</b> ' + values.join('; ') + '');
        }

        return items;
    };

}
