import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, DetailedAddress } from '@proman/interfaces/entity-interfaces';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { Entity } from '@proman/services/entity.service';
import { deepCopy, findByProperty, isDefinedNotNull } from '@proman/utils';
import { ToastService } from '@proman/services/toast.service';

const states: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
};

@Component({
    selector: 'pm-detailed-address',
    template: `

        @if (isEditMode) {
            <div fxLayout="column">
                <pro-editable-container [label]="config?.label">
                    <div class="SidePadding-16">
                        <pro-editable-container [label]="'preview'">
                            {{ value.inline || '-' }}
                        </pro-editable-container>

                        <pro-select [value]="value.country"
                                    [config]="{ label: 'country', displayKey: 'name' }"
                                    [options]="countryOptions"
                                    (onChange)="set('country', $event)"></pro-select>
                        @if (value.country === 'USA') {
                            <pro-select [value]="value.state"
                                        [config]="{ label: 'country_state', key: 'id' }"
                                        [options]="stateOptions"
                                        (onChange)="set('state', $event)"></pro-select>
                        }
                        <pm-txt [value]="value.postalCode"
                                [config]="{ label: 'postalCode', validators: { length: { max: 16 } } }"
                                (onChange)="set('postalCode', $event)"></pm-txt>
                        <pm-txt [value]="value.city"
                                [config]="{ label: 'city', validators: { length: { max: 160 } } }"
                                (onChange)="set('city', $event)"></pm-txt>
                        <pm-txt [value]="value.street"
                                [config]="{ label: 'street', validators: { length: { max: 160 } } }"
                                (onChange)="set('street', $event)"></pm-txt>


                        <pro-btn [label]="'confirm'"
                                 [theme]="'accent'"
                                 (onClick)="confirm()"></pro-btn>
                    </div>
                </pro-editable-container>
            </div>
        } @else {
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
                <pro-editable-container [label]="config?.label">
                    {{ value.inline || '-' }}
                </pro-editable-container>
                @if (!disabled) {
                    <pro-btn [icon]="'edit'"
                             (onClick)="handleEdit()"></pro-btn>
                }
            </div>
        }
    `
})

export class DetailedAddressComponent implements OnInit {
    @Input() value: DetailedAddress;
    @Input() config: { label?: string };
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<DetailedAddress> = new EventEmitter<DetailedAddress>();
    isEditMode: boolean = false;
    stateOptions: SelectOption[];
    countryOptions: SelectOption[];

    constructor(
        private Entity: Entity,
        private Toast: ToastService,
    ) {
        this.stateOptions = Object.keys(states).map((key) => ({ id: states[key], name: states[key] }));
    }

    ngOnInit() {
        this.setInline();
    }

    handleEdit() {
        this.isEditMode = true;
        if (!this.countryOptions) {
            this.Entity.get<Country>('country')
                .search()
                .then((response) => {
                    this.countryOptions = response.map((country) => ({ id: country.iso3, name: country.name }));
                    switch (this.value.country) {
                        case 'United States':
                            this.value.country = findByProperty(this.countryOptions, 'name', 'United States of America');
                            break;
                        default:
                            this.value.country = findByProperty(this.countryOptions, 'name', this.value.country);
                            break;
                    }
                });
        }

    }

    set(property: keyof DetailedAddress, value: string) {
        this.value[property] = value;
        if (property === 'country') console.log(value);
        this.setInline();
    }

    setInline() {
        let result: string = '';

        if (isDefinedNotNull(this.value.street)) { result = `${this.value.street}`; }
        if (isDefinedNotNull(this.value.city)) { result = `${result}, ${this.value.city}`; }
        if (isDefinedNotNull(this.value.state)) { result = `${result}, ${this.value.state}`; }
        if (isDefinedNotNull(this.value.country)) { result = `${result}, ${this.value.country.name || this.value.country}`; }
        if (isDefinedNotNull(this.value.postalCode)) { result = `${result}, ${this.value.postalCode}`; }

        this.value.inline = result;

    }

    confirm() {
        this.isEditMode = false;
        const oldCountryValue = isDefinedNotNull(this.value.country) ? deepCopy(this.value.country) : null;
        if (this.value.country) {
            this.value.country = this.value.country.name;
        } else {
            this.Toast.pop('warning', 'country_not_provided');
        }

        this.onChange.emit(this.value);
        this.value.country = oldCountryValue;
    }
}
