import { Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { OverlayService } from '../services/overlay.service';
import { debounce } from '@proman/utils';

@Directive({
    selector: '[pmOverlay]'
})
export class OverlayDirective implements OnInit, OnDestroy, OnChanges {
    @Input('pmOverlay') data: any;
    @Input('pmOverlayDebounce') debounce: any = 500;
    @Input('pmOverlayDelay') delay: any = 0;
    @Input('pmOverlayForceHide') forceHide: boolean;

    _inited: boolean;
    visible: boolean = false;
    toBeShown: boolean = false;

    isIgnored: boolean;

    debounceShow: any;

    constructor(private Overlay: OverlayService) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && this._inited) this.Overlay.setData(changes.data.currentValue);

        if (this.isIgnored && changes.data?.currentValue && changes.data.currentValue?.data) {
            this.isIgnored = false;
            this.ngOnInit();
        }

        if (changes && changes.forceHide && changes.forceHide.currentValue) {
            this.disableOverlay();
        }
    }

    @HostListener('mouseover', ['$event'])
    public setOverlayData($event: MouseEvent) {
        if (this.isIgnored) return;

        $event.stopPropagation();

        this.Overlay.setData(this.data);

        this.debounceShow($event);

        this.toBeShown = true;
    }

    @HostListener('mouseleave')
    public disableOverlay() {
        if (this.isIgnored) return;

        if (!this.visible || this.toBeShown) {
            this.debounceShow.cancel();
            this.Overlay.hide();

        } else {
            this.Overlay.hide();

            this.visible = false;

        }

        this.toBeShown = false;
    }

    ngOnInit() {
        if (!this.data.data || this.Overlay.isMobile) {
            return this.isIgnored = true;

        }

        this.debounceShow = debounce(($event: MouseEvent) => {
            this.Overlay.show($event);

            this.visible = true;

        }, this.debounce);

        this._inited = true;

        document.addEventListener('click', this.handleDocumentClick);

    }

    handleDocumentClick = () => {
        if (this.visible) {
            this.Overlay.hide();
        }
    };

    ngOnDestroy() {
        if (this.toBeShown) this.debounceShow.cancel();
        if (this.visible) this.Overlay.hide();

        document.removeEventListener('click', this.handleDocumentClick);

    }

}
