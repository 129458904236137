import { Component, Input, OnInit } from '@angular/core';

import { Entity } from '@proman/services/entity.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import {
    Operation,
    ParameterDropdownOption,
    ProductionChildParameter,
    ProductionParameter, Workplace,
} from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-parameter-view',
    template: `
        <div class="Editable">
            <div class="Editable-Label" *ngIf="!config.noLabel">
                <b>{{ label }}</b>
            </div>
            <div>{{ value }}</div>
        </div>
    `
})

export class ParameterViewComponent implements OnInit {
    @Input() config: any = {};
    @Input() parameter: any;

    type: string;
    label: string;
    value: any;

    constructor(
        private Entity: Entity,
        private ParametersOptions: ParametersOptionsService,
    ) {}

    async ngOnInit() {
        let parameter: any = this.parameter;

        this.label = parameter.parameter.name;
        this.type = parameter.parameter.type;

        switch (this.type) {

            case 'string':
            case 'number':
                this.value = this.parameter.value;

                break;

            case 'material':
            case 'material_category':
                this.ParametersOptions.get({ entity: this.type, entityParams: { id: parameter.value } })
                    .then((response: any) => this.value = response.name);

                break;

            case 'workgroup':
                this.value = await this.getWorkgroupParameterValue(parameter)

                break;

            case 'dropdown':
                this.Entity.get<ParameterDropdownOption>('parameter_dropdown_option')
                    .get({ id: parameter.value })
                    .then((response) => this.value = response && response.name)
                    .catch(() => this.value = '-');

                break;

            case 'list':
                this.value = (JSON.parse(parameter.value) || []).join(', ');

                break;

            case 'parameter_group':
                this.value = '';

                if (parameter.children) {
                    for (const p of parameter.children) {
                        if (p.parameter.type === 'workgroup') {
                            this.value += await this.getWorkgroupParameterValue(p);
                        } else {
                            this.value += `${p.parameter.name} - ${p.value}; `;
                        }
                    }
                }

                break;
        }
    }

    async getWorkgroupParameterValue(p: ProductionParameter|ProductionChildParameter) {
        const parsedParameter: { operationId: number; workplaceId: number } = JSON.parse(p.value);
        let result = ''

        if (parsedParameter.workplaceId) {
            await this.ParametersOptions
                .get({ entity: 'workplace', entityParams: { id: parsedParameter.workplaceId } })
                .then((response: Workplace) => {
                    result += `${p.parameter.name} - ${response.alias}; `;
                });
        }

        if (parsedParameter.operationId) {
            await this.ParametersOptions
                .get({ entity: 'operation', entityParams: { id: parsedParameter.operationId } })
                .then((response: Operation) => {
                    result += `${p.parameter.name} - ${response.alias}; `;
                });
        }

        return result;
    }
}
