import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { OPERATORS_TYPES } from '@proman/utils/expressions_config';
import { Parameter } from '@proman/interfaces/entity-interfaces';
import { SelectOption } from '@proman/interfaces/object-interfaces';

interface ExpressionType {
    name: string;
    type: string;
    isCondition?: boolean;
}

@Component({
    selector: 'pro-expression-condition',
    template: `
        <div>
            <div class="List">
                @for (expressionValue of expression.values; track $index; let first = $first; let last = $last) {
                    <div class="List-row">
                        @if (!first && last) {
                            <pro-checkbox [config]="{ label: 'default_value' }"
                                          [value]="expressionValue.isDefault"
                                          (onChange)="toggleDefault(expressionValue, $event)"></pro-checkbox>
                        }
                        
                        @if (!expressionValue.isDefault) {
                            <div fxLayout="column">
                                <div fxLayout="row"
                                     fxLayoutAlign="start center">
                                    <h4>
                                <span>
                                    {{ 'if' | translate }}
                                </span>
                                        @for (condition of expressionValue.condition; track $index) {
                                            <span>
                                            @if (condition.config?.type === 'parameter') {
                                                <span>{{ condition.value?.name }}</span>
                                            } @else {
                                                <span>{{ condition.value }}</span>
                                            }
                                        </span>
                                        }
                                    </h4>
                                    <div fxFlex></div>
                                    @if (expressionValue.values?.length > 1) {
                                        <pro-btn (onClick)="deleteCondition(expression.values, $index)"
                                                 icon="trash"
                                                 theme="warn"></pro-btn>
                                    }
                                </div>
                                <div fxLayout="row"
                                     fxLayoutAlign="start center">
                                    @for (condition of expressionValue.condition; let childChildIndex = $index; let childChildLast = $last; track childChildIndex) {
                                        <div>
                                            <div fxLayout="row"
                                                 fxLayoutAlign="start center">
                                                @if (condition.value && !condition.isEditMode) {
                                                    <pro-btn (onClick)="edit(condition)"
                                                             icon="edit"
                                                             theme="primary"></pro-btn>
                                                }
                                                
                                                @if (condition.value && condition.isEditMode) {
                                                    <pro-btn (onClick)="confirmEdit(condition)"
                                                             icon="check-circle"
                                                             theme="primary"></pro-btn>
                                                    <pro-btn (onClick)="deleteConditionItem(expressionValue.condition, childChildIndex)"
                                                             icon="trash"
                                                             theme="warn"></pro-btn>
                                                }

                                                @if (!condition.value || condition.isEditMode) {
                                                    <pro-select [ngClass]="{ 'width-75' : condition.config?.type === 'numeric' }"
                                                                [value]="condition.config"
                                                                [config]="{ label: 'type', isNone: true, disableSearch: true }"
                                                                [options]="types | proFilterFunction:filterOutConditionals"
                                                                (onChange)="resetValue(condition, $event)"></pro-select>  
                                                }
                                                
                                                @switch (condition.config?.type) {
                                                    @case ('numeric') {
                                                        <pro-text-simple [value]="condition.value"
                                                                         [config]="{ label: 'value' }"
                                                                         (onChange)="set(condition, $event)"></pro-text-simple>
                                                    }
                                                    
                                                    @case ('parameter') {
                                                        <pro-select [value]="condition.value"
                                                                    [config]="{ label: 'value' }"
                                                                    (onChange)="set(condition, $event)"
                                                                    [options]="parameters"></pro-select>
                                                    }
                                                    
                                                    @case ('operator') {
                                                        <pro-select [value]="condition.value"
                                                                    [config]="{ label: 'operator', key: 'id' }"
                                                                    (onChange)="set(condition, $event)"
                                                                    [options]="operatorsTypes"></pro-select>
                                                    }
                                                    
                                                    @case ('function') {
                                                        <pro-select [value]="condition.value"
                                                                    [config]="{ label: 'function', key: 'id' }"
                                                                    (onChange)="set(condition, $event)"
                                                                    [options]="functions"></pro-select>
                                                    }
                                                }
                                                
                                                @if (childChildLast && condition.value) {
                                                    <pro-btn (onClick)="addNewCondition($index)"
                                                             icon="plus"
                                                             theme="accent"></pro-btn>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <h4>
                        <span>
                            {{ 'then' | translate }}
                        </span>
                            @for (conditionOperation of expressionValue.conditionOperation; track $index) {
                                <span>
                                    @if (conditionOperation.config?.type === 'parameter') {
                                        <span>{{ conditionOperation.value?.name }}</span>
                                    } @else {
                                        <span>{{ conditionOperation.value }}</span>
                                    }
                                </span>
                            }
                        </h4>
                        <div fxLayout="row wrap"
                             fxLayoutAlign="start center">
                            @for (conditionOperation of expressionValue.conditionOperation; let childChildIndex = $index; let childChildLast = $last; track childChildIndex) {
                                <div>
                                    <div fxLayout="row"
                                         fxLayoutAlign="start start">
                                        @if (conditionOperation.value && !conditionOperation.isEditMode) {
                                            <pro-btn (onClick)="edit(conditionOperation)"
                                                     icon="edit"
                                                     theme="primary"></pro-btn>
                                        }
                                        
                                        @if (conditionOperation.value && conditionOperation.isEditMode) {
                                            <pro-btn (onClick)="confirmEdit(conditionOperation)"
                                                     icon="check-circle"
                                                     theme="primary"></pro-btn>
                                            <pro-btn (onClick)="deleteConditionItem(expressionValue.conditionOperation, childChildIndex)"
                                                     icon="trash"
                                                     theme="warn"></pro-btn>
                                        }
                                        
                                        @if (!conditionOperation.value || conditionOperation.isEditMode) {
                                            <pro-select [ngClass]="{ 'width-75' : conditionOperation.config && conditionOperation.config.type === 'numeric' }"
                                                        [value]="conditionOperation.config"
                                                        [config]="{ label: 'type', disableSearch: true }"
                                                        [options]="types"
                                                        (onChange)="resetValue(conditionOperation, $event)"></pro-select>
                                        }
                                        
                                        @switch (conditionOperation.config?.type) {
                                            @case ('numeric') {
                                                <pro-text-simple [value]="conditionOperation.value"
                                                                 [config]="{}"
                                                                 (onChange)="set(conditionOperation, $event)"></pro-text-simple>
                                            }
                                            
                                            @case ('parameter') {
                                                <pro-select [value]="conditionOperation.value"
                                                            [config]="{ label: 'value' }"
                                                            [options]="parameters"
                                                            (onChange)="set(conditionOperation, $event)"></pro-select>
                                            }
                                            
                                            @case ('operator') {
                                                <pro-select [value]="conditionOperation.value"
                                                            [config]="{ label: 'operator', key: 'id' }"
                                                            [options]="operatorsTypes"
                                                            (onChange)="set(conditionOperation, $event)"></pro-select>
                                            }

                                            @case ('function') {
                                                <pro-select [value]="conditionOperation.value"
                                                            [config]="{ label: 'function', key: 'id' }"
                                                            (onChange)="set(conditionOperation, $event)"
                                                            [options]="functions"></pro-select>
                                            }

                                            @case ('conditional') {
                                                <pro-expression-condition [isSublevel]="true" fxFlexFill (onChange)="set(conditionOperation, $event)"></pro-expression-condition>
                                            }
                                        }
                                        
                                        @if (childChildLast && conditionOperation.value) {
                                            <pro-btn (onClick)="addNewConditionOperation($index)"
                                                     icon="plus"
                                                     theme="accent"></pro-btn>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <h4>
                            <span>
                                {{ 'else' | translate }}
                            </span>
                            @for (condition of expressionValue.elseCondition; track $index) {
                                <span>
                                    @if (condition.config?.type === 'parameter') {
                                        <span>{{ condition.value?.name }}</span>
                                    } @else {
                                        <span>{{ condition.value }}</span>
                                    }
                                </span>
                            }
                        </h4>
                        <div fxLayout="row wrap"
                             fxLayoutAlign="start center">
                            @for (elseOperation of expressionValue.elseOperation; let childChildIndex = $index; let childChildLast = $last; track childChildIndex) {
                                <div>
                                    <div fxLayout="row"
                                         fxLayoutAlign="start center">
                                        @if (elseOperation.value && !elseOperation.isEditMode) {
                                            <pro-btn (onClick)="edit(elseOperation)"
                                                     icon="edit"
                                                     theme="primary"></pro-btn>
                                        }
                                        
                                        @if (elseOperation.value && elseOperation.isEditMode) {
                                            <pro-btn (onClick)="confirmEdit(elseOperation)"
                                                     icon="check-circle"
                                                     theme="primary"></pro-btn>
                                            <pro-btn (onClick)="deleteConditionItem(expressionValue.elseOperation, childChildIndex)"
                                                     icon="trash"
                                                     theme="warn"></pro-btn>
                                        }
                                        
                                        @if (!elseOperation.value || elseOperation.isEditMode) {
                                            <pro-select [ngClass]="{ 'width-75' : elseOperation.config && elseOperation.config.type === 'numeric' }"
                                                        [value]="elseOperation.config"
                                                        [config]="{ label: 'type', disableSearch: true }"
                                                        [options]="types"
                                                        (onChange)="resetValue(elseOperation, $event)"></pro-select>
                                        }
                                        
                                        @switch (elseOperation.config?.type) {
                                            @case ('numeric') {
                                                <pro-text-simple [value]="elseOperation.value"
                                                                 [config]="{}"
                                                                 (onChange)="set(elseOperation, $event)"></pro-text-simple>
                                            }
                                            
                                            @case ('parameter') {
                                                <pro-select [value]="elseOperation.value"
                                                            [config]="{ label: 'value' }"
                                                            [options]="parameters"
                                                            (onChange)="set(elseOperation, $event)"></pro-select>
                                            }
                                            
                                            @case ('operator') {
                                                <pro-select [value]="elseOperation.value"
                                                            [config]="{ label: 'operator', key: 'id' }"
                                                            [options]="operatorsTypes"
                                                            (onChange)="set(elseOperation, $event)"></pro-select>
                                            }

                                            @case ('function') {
                                                <pro-select [value]="elseOperation.value"
                                                            [config]="{ label: 'function', key: 'id' }"
                                                            (onChange)="set(elseOperation, $event)"
                                                            [options]="functions"></pro-select>
                                            }

                                            @case ('conditional') {
                                                <pro-expression-condition [isSublevel]="true" fxFlexFill></pro-expression-condition>
                                            }
                                        }
                                        
                                        @if (childChildLast && elseOperation.value) {
                                            <pro-btn (onClick)="addNewElseOperation($index)"
                                                     icon="plus"
                                                     theme="accent"></pro-btn>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <small>
                {{ previewValue }}
            </small>
            @if (isSublevel) {
                <pro-btn (onClick)="addSubExpression()"
                         [label]="'add_expression' | translate"
                         [disabled]="!canAdd()"></pro-btn>
            } @else {
                <div fxLayout="row">
                    <!--    <pro-btn (onClick)="addCondition()"
                                [label]="'add_condition' | translate"
                                theme="accent"
                                [disabled]="!canAdd()"></pro-btn>-->
                    <pro-btn (onClick)="addExpression()"
                             [label]="'add_expression' | translate"
                             [disabled]="!canAdd()"></pro-btn>
                </div>
            }
        </div>
    `,
    styles: [`
        .width-75 { width: 75% }
        pro-select { min-width: 90px; }
    `]
})

export class ExpressionConditionComponent implements OnInit {
    @Input() isSublevel: boolean;
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    operatorsTypes: SelectOption[];
    expression: any;
    parameters: Parameter[];
    types: ExpressionType[];
    functions: SelectOption[];
    previewValue: string;

    isConditionCreateMode: boolean;
    isAddExpressionMode: boolean;
    isAddNewVariableMode: boolean;

    constructor(
        private Entity: Entity,
        private filter: FilterService,
    ) {

    }

    ngOnInit() {
        this.isConditionCreateMode = true;
        this.isAddExpressionMode = false;
        this.isAddNewVariableMode = true;

        this.expression = {
            values: [{
                condition: [{ /*config: {}*/ }],
                conditionOperation: [{ /*config: {}*/ }],
                elseOperation: [{ /*config: {}*/ }],

            }]
        };

        this.types = [
            {
                name: this.filter.translate('numeric'),
                type: 'numeric'
            },
            {
                name: this.filter.translate('parameter'),
                type: 'parameter'
            },
            {
                name: this.filter.translate('operator'),
                type: 'operator'
            },
            {
                name: this.filter.translate('function'),
                type: 'function'
            },
            {
                name: this.filter.translate('conditional'),
                isCondition: true,
                type: 'conditional'
            }
        ];

        this.functions = [
            {
                id: 'articlePrice()',
                name: this.filter.translate('article_price'),
            },
            {
                id: 'articlePrice1()',
                name: this.filter.translate('article_price') + ' 2',
            },
            {
                id: 'articlePrice2()',
                name: this.filter.translate('article_price') + ' 3',
            }
        ]

        this.operatorsTypes = OPERATORS_TYPES.map((item: string) => {
            if (item === '=') {
                return {
                    id: '==',
                    name: item,
                }
            }
            return {
                id: item,
                name: item
            }
        });

        this.Entity.get('parameter')
            .search({})
            .then((response: Parameter[] ) => this.parameters = response);
    }

    formatExpression() {
        const expressionValues = this.expression.values;
        let output = '';

        for (const expression of expressionValues) {
            const expressionCondition = expression.condition;
            const expressionConditionOperation = expression.conditionOperation;
            const expressionElseOperation = expression.elseOperation;

            if (expressionCondition && expressionCondition.length) {

                for (const expressionConditionValue of expressionCondition) {
                    const value = expressionConditionValue.value;
                    const config = expressionConditionValue.config;

                    if (expressionConditionValue && config && value) {
                        switch (config.type) {
                            case 'numeric':
                            case 'function':
                            case 'operator':
                                output += ' ' + expressionConditionValue.value;
                                break;
                            case 'parameter':
                                output += ' p(' + value.id + ')';
                                break;
                        }
                    }
                }
            }

            if (expressionConditionOperation && expressionConditionOperation.length) {
                output += ' ? ';

                for (const expressionConditionOperationValue of expressionConditionOperation) {
                    const value = expressionConditionOperationValue.value;
                    const config = expressionConditionOperationValue.config;

                    if (expressionConditionOperationValue && value) {
                        switch (config?.type) {
                            case 'numeric':
                            case 'function':
                            case 'operator':
                                output += ' ' + expressionConditionOperationValue.value;
                                break;
                            case 'parameter':
                                output += ' p(' + expressionConditionOperationValue.value.id + ')';
                                break;
                            case 'conditional':
                                output += '(' + expressionConditionOperationValue.value + ')';
                                break;
                        }
                    }
                }
            }

            if (expressionElseOperation && expressionElseOperation.length) {
                    output += ' : ';

                    for (const expressionElseOperationValue of expressionElseOperation) {
                        const value = expressionElseOperationValue.value;
                        const config = expressionElseOperationValue.config;

                        if (expressionElseOperationValue && value) {
                            switch (config?.type) {
                                case 'numeric':
                                case 'function':
                                case 'operator':
                                    output += ' ' + expressionElseOperationValue.value;
                                    break;
                                case 'parameter':
                                    output += ' p(' + expressionElseOperationValue.value.id + ')';
                                    break;
                                case 'conditional':
                                    output += '(' + expressionElseOperationValue.value + ')';
                                    break;
                            }
                        }
                    }
                }
        }

        return output;
    }

    set = (item: any, value: any) => {
        item.value = value;

        this.update();
    };

    toggleDefault = (item: any, value: any) => {
        item.isDefault = value;
    };

    canAdd = () => {
        const expression = this.expression;

        return expression.values[0].condition.length > 2 && expression.values[0].conditionOperation[0].value && expression.values[0].elseOperation[0].value;
    };

    addNewCondition(parentIndex: number) {
        this.expression.values[parentIndex].condition.push({ /*config: {}*/ });
    }

    addNewConditionOperation(parentIndex: number) {
        this.expression.values[parentIndex].conditionOperation.push({ /*config: {}*/ });
    }

    addNewElseOperation(parentIndex: number) {
        this.expression.values[parentIndex].elseOperation.push({ /*config: {}*/ });
    }

    addCondition() {
        this.expression.values.push({
            condition: [{ /*config: {}*/ }],
            conditionOperation: [{ /*config: {}*/ }]
        });

        this.update();
    }

    edit(item: any) {
        item.isEditMode = true;

        this.update();
    }

    deleteCondition(items: any, index: number) {
        items.splice(index, 1);

        this.update();
    }

    deleteConditionItem(items: any, index: number) {
        items.splice(index, 1);

        this.update();
    }

    confirmEdit(item: any) {
        delete item.isEditMode;

        this.update();
    }

    resetValue(item: any, value: any) {
        delete item.value;

        item.config = value;

        this.update();
    }

    addExpression() {
        const output = this.formatExpression();

        this.onChange.emit(output);
    }

    addSubExpression() {
        this.onChange.emit(this.formatExpression())
    }

    update() {
        this.previewValue = this.formatExpression();
    }

    filterOutConditionals = (item: ExpressionType) => !item.isCondition;
}
