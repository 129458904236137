import { ESCAPE } from '@angular/cdk/keycodes';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ElementRef,
    Injectable,
    OnDestroy,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomAttacher } from './attacher/attacher';
import { CustomAttacherPositionToOrigin } from './attacher/models/position-to-origin';

const OVERLAY_CONFIG: Partial<OverlayConfig> = {
    // maxWidth: 250,
    hasBackdrop: true,
    disposeOnNavigation: true,
    // backdropClass: 'cdk-overlay-transparent-backdrop',
    backdropClass: 'cdk-overlay-dark-backdrop',
    panelClass: ['mat-elevation-z4', 'custom-color-picker__panel']
};

@Injectable()
export class CustomAttacherTriggerService implements OnDestroy {
    private _overlayRef?: OverlayRef;
    private _templatePortal?: TemplatePortal<unknown>;
    private _subscription = new Subscription();

    constructor(private _attacher: CustomAttacher) {}

    ngOnDestroy() {
        this.dispose();
    }

    /**
     * Open attacher panel and position it based on its origin element.
     * @param origin: Element that attach the attacher panel to.
     * @param attacherTemplateRef:  Template reference of the attacher panel.
     * @param originViewContainerRef: ViewContainerRef of the origin element.
     * @param config: Overlay config for the attacher panel.
     */
    open(
        origin: ElementRef,
        originViewContainerRef: ViewContainerRef,
        attacherTemplateRef: TemplateRef<unknown>,
        config?: Partial<OverlayConfig>
    ) {
        this.dispose();

        const overlayConfig = new OverlayConfig({
            ...OVERLAY_CONFIG,
            ...config
        });

        if (!overlayConfig.positionStrategy) {
            overlayConfig.positionStrategy = this._attacher
                .position()
                .flexibleConnectedTo(origin)
                .positionToOrigin(CustomAttacherPositionToOrigin.topRight);
        }

        this._overlayRef = this._attacher.create(origin, overlayConfig);
        this._templatePortal = new TemplatePortal(
            attacherTemplateRef,
            originViewContainerRef,
            {
                $implicit: this._overlayRef
            }
        );

        this._overlayRef.attach(this._templatePortal);

        const escapePressed$ = this._overlayRef.keydownEvents().pipe(
            // tslint:disable-next-line: deprecation
            filter(event => (event as KeyboardEvent).keyCode === ESCAPE)
        );
        this._subscription = merge(
            this._overlayRef.backdropClick(),
            escapePressed$
        ).subscribe(() => {
            this.dispose();
        });

        return this._overlayRef;
    }

    dispose() {
        this._subscription.unsubscribe();
        if (this._overlayRef) {
            this._overlayRef.detach();
            this._overlayRef.dispose();
            this._overlayRef = undefined;
            this._templatePortal = undefined;
        }
    }
}
