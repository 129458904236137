import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[proClickStopPropagation]'
})
export class ClickStopPropagationDirective {

    @HostListener('click', ['$event'])
    public onClick(event: any) {
        event.stopPropagation();
    }
}
