import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@proman/shared/pipes/pipes.module';

import { SwitchComponent } from './components/switch.component';
import { TxtComponent } from './components/txt.component';
import { PriceComponent } from './components/price.component';
import { HtmlTxtComponent } from './components/html-txt.component';
import { InputComponent } from './components/input.component';
import { TimeIntervalComponent } from './components/time-interval.component';
import { ListComponent } from './components/list.component';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { CustomDateAdapter } from '@proman/shared/adapters/custom-date.adapter';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { BtnListComponent } from './components/btn-list.component';
import { MonthpickerComponent } from './components/monthpicker.component';
import { DetailedAddressComponent } from '@frontend/inputs/components/detailed-address.component';
import { NumberComponent } from '@frontend/inputs/components/number.component';
import { EmojiComponent } from '@frontend/inputs/components/emoji.component';
import { CanvasSignComponent } from './components/canvas-sign.component';
import { AdvancedColorComponent } from '@frontend/inputs/components/advanced-color.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { Fa6Module } from '@proman/fa/fa6.module';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { FrontendDirectivesModule } from '@frontend/shared/frontend-directives.module';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { PromanCheckboxComponent } from '@proman/checkbox/proman-checkbox.component';
import { PromanSelectComponent } from '@proman/select';
import { PromanDateTimeModule } from '@proman/datepicker/proman-date-time.module';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CustomAttacherModule } from '@proman/overlay_cdk/attacher.module';

const COMPONENTS = [
    SwitchComponent,
    TxtComponent,
    MonthpickerComponent,
    PriceComponent,
    HtmlTxtComponent,
    InputComponent,
    TimeIntervalComponent,
    ListComponent,
    BtnListComponent,
    DetailedAddressComponent,
    NumberComponent,
    EmojiComponent,
    CanvasSignComponent,
    AdvancedColorComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        Fa6Module,
        MatLegacyInputModule,
        MatLegacyAutocompleteModule,
        MatLegacyButtonModule,
        MatLegacyDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatLegacySelectModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatLegacyFormFieldModule,
        FlexLayoutModule,
        ColorSketchModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        PromanCommonComponentsModule,
        PromanCheckboxComponent,
        PromanSelectComponent,
        Fa6Module,
        PipesModule,
        SharedDirectivesModule,
        FrontendDirectivesModule,
        PromanDateTimeModule,
        PromanColorComponent,
        PromanAutocompleteComponent,
        CustomAttacherModule,
        OverlayModule,
        PromanButtonComponent,

    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
    declarations: COMPONENTS,
    exports: [
      ...COMPONENTS,
      PromanButtonComponent,
      PromanCheckboxComponent,
      PromanSelectComponent,
      PromanDateTimeModule,
    ]
})

export class InputsModule {

}
