import { AfterViewInit, Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[proOnLoad]',
})

export class ElementOnLoadDirective implements AfterViewInit {
    @Input() proOnLoadDisabled: boolean = false;
    @Output() proOnLoad:  EventEmitter<void> = new EventEmitter<void>();

    ngAfterViewInit() {
        if (!this.proOnLoadDisabled) this.proOnLoad.emit();
    }

}
