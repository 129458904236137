import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from '../rxjs-common';
import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { InputErrorStateMatcher } from '../validators/input-error-state-matcher';
import { isDefinedNotNull } from '../utils';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { PromanButtonComponent } from '../button';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { NumberValidator } from '../validators/number.validator';
import { RangeValidator } from '../validators/range.validator';

const DEFAULT_DEBOUNCE_TIME = 1500;

@Component({
  selector: 'pro-text-simple',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    PipesModule,
    FlexLayoutModule,
    PromanButtonComponent
  ],
  template: `
    <div fxLayout="row" (click)="handleClick($event)">
      <mat-form-field [floatLabel]="config.floatLabel || 'auto'"
                      [color]="config.important && !isDefinedNotNull(value) ? 'warn' : 'primary'"
                      [attr.data-name]="config.label"
                      [appearance]="'legacy'"
      >

        @switch (config.type) {
          @case ('textarea') {
            <textarea #box
                      matInput
                      [ngStyle]="config.expandable ? {} : { 'resize': 'none' }"
                      [placeholder]="config.label | translate"
                      (keydown.enter)="handleEnter($event)"
                      [value]="value"
                      [formControl]="control"
                      [required]="config.required"
                      [errorStateMatcher]="matchErrorState"></textarea>
          }
          
          @case ('number') {
            <input [type]="'number'"
                   #box
                   matInput
                   [formControl]="control"
                   [placeholder]="config.label | translate"
                   [value]="value | proDecimal:config?.decimals"
                   [errorStateMatcher]="matchErrorState"
                   [required]="config.required"
                   autocomplete="off"/>
          }
          
          @default {
            <input [type]="config.type || 'text'"
                   #box
                   matInput
                   [formControl]="control"
                   [placeholder]="config.label | translate"
                   [value]="value"
                   [errorStateMatcher]="matchErrorState"
                   [required]="config.required"
                   autocomplete="off"

            />
          }
        }
        <!--                                 [pmOverlay]="{ type: 'button', data: config.type === 'password' ? '' : value }"-->
        <!--                                 [pmOverlayDebounce]="1500"-->
        @if (control && control.errors) {
          <mat-error>
            @if (control.errors.isValidNumber) {
              <span>{{ 'invalid_number' | translate }}</span>
            }
          </mat-error>
        }
      </mat-form-field>
      @if (config.hasClearButton) {
        <pro-btn icon="times" theme="warn" [disabled]="stringLengthCheck(value)" (onClick)="control.reset()"></pro-btn>
      }
    </div>
  `
  ,
  styles: [`
      :host {
          display: inline-block;
          width: 100%;
      }

      mat-form-field {
          width: 100%;
      }

      input {
          width: 100%;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PromanTextSimpleComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() config: {
    label?: string;
    parseNumber?: boolean;
    parseInteger?: boolean;
    type?: 'text'|'password'|'textarea'|'number';
    autofocus?: boolean;
    required?: boolean;
    important?: boolean;
    expandable?: boolean;
    hasClearButton?: boolean;
    debounce?: number;
    preventNewLine?: boolean;
    floatLabel?: 'always' | 'auto';
    prefix?: string;
    suffix?: string;
    disabled?: boolean;
    decimals?: number;
    preventNegative?: boolean;
    validators? : {
        number?: boolean;
        range?: any
    };
    stopClickPropagation?: boolean; // for table cell use cases
  };
  @Input() value: string|number;
  @Input() disabled: any;
  @Input() control: UntypedFormControl;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('box') box: ElementRef;
  matchErrorState: any = new InputErrorStateMatcher();

  inputSubscription: Subscription;
  blurSubscription: Subscription;
  parseNumberSubscription: Subscription;
  enterSubscription: Subscription;
  emittedValue: string;
  expandedTextArea: boolean;
  destroyed$: Subject<void> = new Subject<void>();

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private cd: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    // if (this.config.type === 'number') this.config.type = 'text'; // fix to HTML clear number values with point;
    if (this.config.disabled) this.disabled = this.config.disabled;
    this.setControl();
  }

  isDefinedNotNull = (value: any) => isDefinedNotNull(value);

  setControl() {
    const config = this.config;
    const validators: any = [];
    if (!this.control) {

      // Set validators

      if (config.required) validators.push(Validators.required);

      if (config?.validators) {
        if (config.validators.number) validators.push(NumberValidator);
        if (config.validators.range) validators.push(RangeValidator(config.validators.range));
        // if (config.validators.email) validators.push(EmailValidator);
        // if (config.validators.noComma) validators.push(NoCommaValidator);
        // if (config.validators.length) {
        //   if (config.validators.length.min) validators.push(Validators.minLength(config.validators.length.min));
        //   if (config.validators.length.max) validators.push(Validators.maxLength(config.validators.length.max));
        // }
      }

      this.control = new UntypedFormControl(isDefinedNotNull(this.value) ? this.value : '',  validators);
    }

    if (this.disabled) this.control.disable();

    this.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cd.markForCheck();
      });

  }

  mapEventData = (event: any) => {
    return event.target.value;
  };

  getEventObservable(eventName: string) {
    return fromEvent(this.box.nativeElement, eventName).pipe(
      map(this.mapEventData));
  }

  ngAfterViewInit() {

    const getDebounceTime = () => {
      const debounce = this.config.debounce;

      return (!isNaN(debounce)) ? debounce : DEFAULT_DEBOUNCE_TIME;
    };

    this.enterSubscription = fromEvent<KeyboardEvent>(this.box.nativeElement, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => (event.code === 'Enter')),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: any) => this.handleChange(event.target.value));

    this.inputSubscription = merge(
      this.getEventObservable('blur'),
      this.control.valueChanges.pipe(debounceTime(getDebounceTime()))
    )
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((value: string) => {
        if (value != this.value || (value === '' && this.value !== '')) {
          if (this.control.pending) {
            const Subscription = this.control.statusChanges.subscribe(() => {
              this.handleChange(value);
              Subscription.unsubscribe();
            });

          } else {
            this.handleChange(value);

          }

        }

      });

    this.blurSubscription = fromEvent(this.box.nativeElement, 'blur')
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged())
      .subscribe(() => {
        this.handleBlur();
        this.onBlur.emit();
      });

    if (this.config.autofocus) setTimeout(() => this.box.nativeElement.focus());

    if (this.config.parseNumber || this.config.parseInteger) {
      this.parseNumberSubscription = fromEvent(this.box.nativeElement, 'keyup')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((event: any) => this.handleParseNumber(event));

    }

    // update view on multiple line textarea
    this.cd.markForCheck();

  }

  ngOnChanges(changes: SimpleChanges) {
    const disabled = changes.disabled;
    const value = changes.value;

    if (this.control && disabled && disabled.currentValue !== disabled.previousValue) {
      disabled.currentValue ?
        this.control.disable() :
        this.control.enable();

    }

    if (isDefinedNotNull(this.value)) {
      if (typeof this.value === 'number') {
        this.value = (this.value as Number).toString();
      }
    } else {
      this.value = '';
    }

    if (value && this.control && this.control.invalid) { //
      this.control.setValue(this.value);
    }

    this.cd.markForCheck();

  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  handleChange = (value: string) => {
    if (this.control.valid) {
      this.onChange.emit(value);
      this.emittedValue = value;
    }
  };

  handleParseNumber(event: any) {
    let value: string = event.target.value;

    if (value.indexOf(',') > -1) value = value.replace(',', '.');
    if (this.config.parseInteger && value.includes('.')) this.control.setErrors({ isValidNumber: true });
    if (this.config.preventNegative) value = value.replace(/-/, '');

    event.target.value = value.replace(/^0+(?!\.|$)/, ''); // remove zeros from start
  }

  handleEnter(event: any) {
    if (this.config.preventNewLine) event.preventDefault();

  }

  handleBlur() {
    if (this.config.required) {
      this.control.setValue(this.emittedValue || this.value);

    }

    const trimValue = (emitValue: string): string => {
      // allow string / number comparison

      let result = emitValue;

      if (typeof result === 'string' && result !== ' ') {
        result = result.trim();
      }

      return result;
    };
    if (this.control.value !== trimValue(this.control.value) || this.control.value === '') {
      this.control.setValue(trimValue(this.control.value));
    }

  }

  stringLengthCheck = (value: string|number) => {
    if (typeof value === 'number') {
      return false;
    }

    return (value as string)?.length === 0;
  }

    handleClick(event: MouseEvent) {
      if (this.config.stopClickPropagation) {
          event.stopPropagation();
      }
    }
}
