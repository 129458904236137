import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHTML', pure: false })

export class HtmlSanitizerPipe implements PipeTransform {

    constructor(
        private Sanitizer: DomSanitizer
    ) {

    }

    transform(value: any): any {
        return this.Sanitizer.bypassSecurityTrustHtml(value);
    }
}
