import { Injectable } from '@angular/core';

@Injectable()
export class OutsideClickDetectorService {
    listeners: any = [];

    constructor() {

    }

    isException(event: MouseEvent) {
        const exceptions: string[] = [];

        const result =  exceptions.filter((selector) => {
            const exceptionElement = document.querySelector(selector);
            if (!exceptionElement) return false;

            return exceptionElement.contains(event.target as Node);

        }).length > 0;

        return result;
    }

    handleClick = (event: MouseEvent) => {
        let element;

        if (!this.isException(event)) {

            for (const listener of this.listeners) {
                element = listener.element;

                const isSameElOrParent = element === event.target || element.contains(event.target);

                if (!isSameElOrParent) {
                    setTimeout(() => {
                        listener.callback(event);
                    });

                }

            }

        }

    };

    addListener(element: HTMLElement, callback: () => void) {

        if (this.listeners.length === 0) {
            document.addEventListener('mousedown', this.handleClick);

        }

        this.listeners.push({ element, callback });

        return ((index) => {
            return {
                cancel: () => {
                    this.listeners.splice(index, 1);

                    if (this.listeners.length === 0) {
                        document.removeEventListener('mousedown', this.handleClick);
                    }
                }
            };
        })(this.listeners.length - 1);
    }
}
