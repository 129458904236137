import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pro-move-handle',
    template: `
        <span class="MoveHandle {{ class }}"
              proClickStopPropagation
              fxLayout="row"
        >
            <fa name="ellipsis-v-alt"
                   size="1x"></fa>
            <fa name="arrows-alt-v"
                   size="1x"></fa>
        </span>
    `,
    styles: [`
        .MoveHandle {
            cursor: pointer;
            user-select: none;
        }
    `]
})

export class MoveHandleComponent implements OnInit {
    @Input() class: string;

    constructor(

    ) {

    }

    ngOnInit() {

    }

}
