import { Inject, Injectable } from '@angular/core';
import { snakeCase } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { Parameter } from '@proman/interfaces/entity-interfaces';

const replaceAt = (_string: string, index: number, replacement: string) => {
    return _string.substr(0, index) + replacement + _string.substr(index + 1);
};

const colorsArray = [
    '#333333',
    '#efb022',
    '#6d27ef',
    '#377202',
    '#dd3e37',
    '#dd4fbb',
    '#1d7fdd',
    '#ddd62a',
    '#47dd42',
    '#4304dd',
    '#dd5f4c',
    '#dd5f4c',
];

@Injectable()
export class ExpressionHumanizeService {
    fetchPromise: any = null;
    parameters: any;
    expressions: any;
    Expression: EntityInterface;

    constructor(
        private Entity: Entity,
        private Filter: FilterService,
    ) {
        this.Expression = this.Entity.get('expression');
    }

    static wrapVariable(variable: any) {
        return '<span class="ExpressionVariable">' + variable + '</span>';
    }

    static wrapKeyword(keyword: any) {
        return '<span class="ExpressionKeyword">' + keyword + '</span>';
    }

    getParameterName(parameterId: any) {
        if (!this.parameters) return;
        let name;

        for (const parameter of this.parameters) {
            if (parameter.id === parseInt(parameterId) || parameter.alias === parameterId) {
                name = parameter.name;
            }
        }

        return name;
    }

    fetchData() {
        const parametersPromise = this.Entity.get('parameter').search({ select: ['id', 'name', 'alias'] });
        const expressionsPromise = this.Expression.search({ select: ['id', 'name'] });

        return Promise.all([
                parametersPromise,
                expressionsPromise
            ]);
    }

    getData() {

        if (!this.fetchPromise) {
            this.fetchPromise = this.fetchData();

        }

        return this.fetchPromise;
    }

    humanize(expression: string) {

        return this.getData()
            .then((response: [Parameter[], any[]]) => {
            this.parameters = response[0];
            this.expressions = response[1];

            if (expression) {

                // Replace entity parameters
                expression = expression.replace(/\b([a-zA-Z])\w+\.(parameter|p)\(\d+\)/g, (value: string) => {
                    const parts = value.split('.');
                    let parameterId;
                    const transformedParts = [];
                    let name;

                    for (const part of parts) {
                        if (part.match(/^parameter|p\(\d+\)/)) {
                            parameterId = parseInt(part.replace(/[^0-9]/g, ''));
                            name = this.getParameterName(parameterId);
                            transformedParts.push(name || part);

                        } else {
                            transformedParts.push(this.Filter.translate(snakeCase(part)));

                        }

                    }

                    value = ExpressionHumanizeService.wrapVariable(transformedParts.join('.'));

                    return value;
                });

                // Replace entities
                expression = expression.replace(/\b([a-zA-Z])\w+\.[a-zA-Z]+/g, (value: any) => {
                    const parts = value.split('.');
                    let output = '';
                    let part;
                    let iter;

                    for (iter = 0; iter < parts.length; iter++) {
                        part = snakeCase(parts[iter]);

                        output += this.Filter.translate(part);

                        if (iter !== parts.length - 1) {
                            output += '.';
                        }
                    }

                    output = ExpressionHumanizeService.wrapVariable(output);

                    return output;
                });

                // Replace parameters
                expression = expression.replace(/\b(parameter|p)\(\w+\)/g, (value: any) => {
                    const regExp = /\(([^)]+)\)/;
                    const matches = regExp.exec(value);

                    value = ExpressionHumanizeService.wrapVariable(this.getParameterName(matches[1]) || value);

                    return value;
                });

                // Replace expressions
                expression = expression.replace(/\b(exp|e)\(\d+\)/g, (value: any) => {
                    const expressionId = parseInt(value.replace(/[^0-9]/g, ''));

                    for (const expression of this.expressions) {

                        if (expression.id === expressionId) {
                            value = expression.name;

                            break;
                        }

                    }

                    value = ExpressionHumanizeService.wrapVariable(value);

                    return value;
                });

                // Replace other keywords
                expression = expression.replace(/\b(case|when|then|else|end|sum)/g, (value: any) => {

                    if (value === 'case' || value === 'end') {
                        value = '';

                    }

                    value = this.Filter.uppercase(this.Filter.translate(value));

                    value = ExpressionHumanizeService.wrapKeyword(value);

                    return value;
                });

                // Color strings
                expression = expression.replace(/".*?"/g, (match: string, offset: number, string: string) => {
                    return match === '"ExpressionVariable"' ?
                        match :
                        `"<span class="ColorOrange">${match.substring(1, match.length -1)}</span>"`;
                });

                let depth = 0;
                let maxDepth = 0;
                const colorsQueue = [];

                const reverseExpression = expression.split('').reverse().join('');

                for (let i = 0; i < reverseExpression.length; i++) {
                    const index = reverseExpression.length - i - 1;
                    const char = reverseExpression.charAt(i);
                    const isOpen = char === ')';
                    const isClose = char === '(';

                    if (isOpen || isClose) {

                        isOpen ?
                            depth++ :
                            depth--;

                        if (isOpen) colorsQueue.push(colorsArray[(depth -1) % colorsQueue.length]);

                        if (maxDepth < depth) maxDepth = depth;

                        const replacement = `<span style="color: ${colorsQueue[colorsQueue.length -1]}; font-weight: bold">${char}</span>`;
                        expression = replaceAt(expression, index, replacement);

                        if (isClose) colorsQueue.pop();

                    }

                }

                return expression;

            } else {
                return '';

            }

        });
    }
}
