import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { findScrollElement } from '../../utils';
import { fromEvent, Subject, distinctUntilChanged, takeUntil, merge } from '@proman/rxjs-common';

@Directive({
    selector: '[proScrollLimit]'
})

export class ScrollLimitDirective implements AfterViewInit, OnDestroy {
    @Input() scrollLimit: number = 50;
    @Input() scrollLimitIncrement: number = 5;
    @Output() scrollLimitChange: EventEmitter<number> = new EventEmitter<number>();
    ignoreWheel: boolean;
    destroyed$: Subject<void> = new Subject();
    lastHeight: number = 0;

    constructor(
        private cd: ChangeDetectorRef,
        private viewContainerRef: ViewContainerRef,
    ) {

    }

    ngAfterViewInit() {
      const el = this.viewContainerRef.element.nativeElement;

      merge(
        fromEvent(el, 'wheel'),
        fromEvent(el, 'touchmove')
      )
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.destroyed$),
        )
        .subscribe(() => this.handleScroll());
    }

    handleScroll = () => {
        const foo: HTMLElement = this.viewContainerRef.element.nativeElement;
        if (!foo || this.ignoreWheel) {
          return;
        }

        const scrollElement = findScrollElement(foo);

        if (!scrollElement) {
          return;
        }

        this.ignoreWheel = true;

        setTimeout(() => this.ignoreWheel = false, 50);

        const rect = foo.getBoundingClientRect();

        if (rect.height != this.lastHeight && (rect.height + rect.top < scrollElement.clientHeight + 250))  {
            this.scrollLimit += this.scrollLimitIncrement;
            this.scrollLimitChange.emit(this.scrollLimit);
            this.lastHeight = rect.height;
            this.cd.markForCheck();

            setTimeout(this.handleScroll, 50);

        }

    };

    ngOnDestroy() {
       this.destroyed$.next();
    }

}
