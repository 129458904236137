import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface, EntityNameType } from '../../services/entity.service';
import { getIndexByProperty, getRandomString, omit } from '../../utils';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from '@proman/rxjs-common';

declare interface ItemWithPosition {
  id: number;
  position: number;
  name: string;
}

@Component({
  selector: 'pro-entity-reposition-dialog',
  template: `
        <pro-dialog-title title="sort"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
          <div class="ListManager"
               fxLayout="column" >
            <div class="List">

              <div fxLayout="column"
                   *ngIf="!items?.length">
                <hr>
                <pro-no-records></pro-no-records>
              </div>
              <div [dragula]="dragulaId"
                   [dragulaModel]="items"
                   proScrollLimit [(scrollLimit)]="viewLimit">
                <div class="List-row ListManager__item"
                     fxLayout="column"
                     *ngFor="let item of items;let $index = index;"

                >
                  <ng-container *ngIf="$index <= viewLimit">
                    <div fxLayout="row"
                         fxLayoutAlign="start center">

                      <pro-move-handle [class]="'Entity-reposition-dialog-moveHandle'"></pro-move-handle>

                      <div fxLayout="column"
                           style="overflow: scroll">
                        <div class="ListManager__itemName" fxLayout="row" fxLayoutAlign="start center">
                          <ng-container>{{ item.name }}</ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>
            </div>
          </div>

        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `,
  styles: [ `
    div[mat-dialog-content] {
        min-width: 300px;
    }
  `]
})

export class EntityRepositionDialogComponent implements OnDestroy {
  dataEntity: EntityInterface;
  items: ItemWithPosition[];
  params: any;
  viewLimit: number = 50;
  dragulaId: string;
  isMovable: boolean;
  subscriberDrag: Subscription;
  subscriberDrop: Subscription;
  dragItem: ItemWithPosition;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { entity: EntityNameType, params: any },
    private Entity: Entity,
    private Dragula: DragulaService,
    private dialogRef: MatLegacyDialogRef<EntityRepositionDialogComponent>,
  ) {
    this.dataEntity = this.Entity.get(this.data.entity);
    this.params = omit(this.data.params, ['sort']) || {};

    this.dragulaId = `entityRepositionDialog-${Date.now()}`;

    this.initDragula();

    this.getItems();
  }

  getItems() {
    this.dataEntity
      .search({ ...this.params, sort: { position: 'asc' } })
      .then((result) => {
        this.items = result;
      });
  }

  initDragula() {
    this.dragulaId = `list-manager2-${new Date().valueOf()}-${getRandomString()}`;


    this.Dragula.createGroup(this.dragulaId, {
      moves: (el: any, source: any, handle: Element) => {

        const isMove = (element: Element) => element.classList.contains('Entity-reposition-dialog-moveHandle');

        this.isMovable = isMove(handle) || isMove(handle?.parentElement) || isMove(handle?.parentElement?.parentElement);

        return this.isMovable;
      }
    });

    this.subscriberDrag = this.Dragula.drag(this.dragulaId).subscribe(({ name, el, source }) => {

      if (this.isMovable) {
        let index = [].slice.call(el.parentElement.children).indexOf(el);

        this.dragItem = Object.assign({}, this.items[index]);

      }

    });

    this.subscriberDrop = this.Dragula.drop(this.dragulaId).subscribe(async (value: any) => {
      if (this.isMovable) {
        let el = value.el;
        let index = [].slice.call(el.parentElement.children).indexOf(el);
        let swapItem = this.items[index];

        setTimeout(() => Promise.resolve());

        let position = getIndexByProperty(this.items, 'id', swapItem.id);


        this.reposition(this.dragItem.id, position)

        this.dragItem = null;
        this.getItems();

      }

    });

  }

  reposition(positionId: number, positionAt: number) {
    this.dataEntity
      .reposition({ ...this.params, positionAt, positionId })
      .then(() => this.getItems());
  }

  ngOnDestroy() {
    this.subscriberDrag.unsubscribe();
    this.subscriberDrop.unsubscribe();
  }

}
