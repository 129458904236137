import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pro-expression-format',
    template: `
        <div class="Expression"
             fxLayout="column" fxFlexFill>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center">
                <div>
                    {{ 'expression_builder' | translate }}</div>
                @for (format of formats; track $index) {
                    <pro-btn (onClick)="loadFormat(format)"
                             [label]="format + '_expression' | translate"
                             theme="accent"></pro-btn>
                }
            </div>

            <div class="Expression-Container">
                @switch (format) {
                    @case ('simple') {
                        <pro-expression-simple (onChange)="updateModel($event)"></pro-expression-simple>
                    }
                    @case ('case') {
                        <pro-expression-case (onChange)="updateModel($event)"></pro-expression-case>
                    }
                    @case ('condition') {
                        <pro-expression-condition (onChange)="updateModel($event)"></pro-expression-condition>
                    }
                }
            </div>

        </div>

    `
})

export class ExpressionFormatComponent {
    @Input() value: string;
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    formats: string[] = [ 'simple', 'condition' ];
    format: string;

    constructor() {}

    loadFormat(format: string) {
        this.format = format;
    }

    updateModel(expression: string) {
        this.value = expression;

        this.format = null;

        this.onChange.emit(expression);

    }

}
