import { Component, Input } from '@angular/core';

@Component({
    selector: 'pro-editable-container',
    template: `
        <div fxLayout="column"
             fxLayoutAlign="center start"
             class="Editable Editable-Disabled"
            [ngClass]="{ 'NoMargin': noPadding }">
            <div class="Editable-Label" [ngClass]="{ 'VisibilityHidden': hideLabel }">
                {{ label | translate }} </div>
            <div class="Editable-Item" [ngClass]="{ 'Error': isError }">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [`
        .Editable-Item { width: 100%; }
    `]
})

export class EditableContainerComponent {
    @Input() label: string;
    @Input() noPadding: boolean;
    @Input() hideLabel: boolean;
    @Input() isError: boolean;

    constructor() {}

}
