import { AbstractControl, ValidatorFn } from '@angular/forms';
import { hasSubstring } from '../utils';

export function StartsWithSymbolValidator(control: AbstractControl) {
    let value = control.value;
    const isValid = value.match && value.match(/^[a-z](?:_?[a-z0-9]+)*/i);

    if (!isValid && value) {
        return { startsWithLetter: true };

    }

    return null;
}
