import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject, OnChanges, OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, Subscription } from '@proman/rxjs-common';
import { distinctUntilChanged, takeUntil } from '@proman/rxjs-common';
import { GlobalOverlayService } from './global-overlay.service';
import { UI_DARK_MODE, UiPreferencesService } from '@proman/services/ui-preferences.service';

@Component({
  selector: 'pro-overlay',
  template: `
    <div #el class="GlobalOverlay" [ngClass]="{ 'dark-mode': !!isDarkMode }" *ngIf="(data$ | async) as data" >
      <ng-container *ngIf="overlayTemplate; else standartLayout">
        <div>
          <ng-container *ngTemplateOutlet="overlayTemplate"></ng-container>
        </div>
      </ng-container>
    </div>

    <ng-template #standartLayout>
      <ng-container *ngIf="(data$ | async) as data">
        <div *ngIf="data.type === 'button'">
          <span>{{ data.data }}</span>
        </div>
      </ng-container>
    </ng-template>
  `,
  styles: [
    `
      .GlobalOverlay {
        position: absolute;
        z-index: 30000;
        font-size: 1.2rem;
        font-size: 11px;
        /*min-width: 250px;*/

        left: -9999px;
        top: -9999px;
        pointer-events: none;

      /*&.NoMinWidth {*/
      /*   min-width: initial;*/
      /* }*/

      /*.Overlay-img {*/
      /*  max-width: 450px;*/
      /*  max-height: 450px;*/

      /*&> img {*/
      /*   display: block;*/
      /*   margin: 0 auto;*/
      /*   max-width: 100%;*/
      /*   max-height: 100%;*/
      /*   object-fit: contain;*/
      /* }*/

      /*}*/

      /*.Overlay-saleOpportunity {*/
      /*  max-width: 400px;*/
      /*}*/

      }

      .GlobalOverlay > div {
        background-color: rgba(255, 255, 255, 0.95);
        border: 1px solid rgb(99, 99, 99, 0.9);
        border-radius: 15%;
        pointer-events: none;
        padding: 2px 4px;
      }
      
      .GlobalOverlay.dark-mode > div {
          background-color: grey;
          color: lightgrey;
      }

      .DiffOverlay, .EventOverlay {
          word-break: break-all;
      }

      .DiffOverlay {
          max-width: 400px;
      }

      .EventOverlay {
          max-width: 900px;
      }

      .Overlay-saleOpportunity {
          overflow: hidden;
      }
        `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GlobalOverlayComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('el') el: ElementRef;

  type: string;
  isDarkMode: boolean;
  data$: Observable<{ type: string; data: any }>;
  _subShow: Subscription;
  destroyed$: Subject<void> = new Subject<void>();
  overlayTemplate: TemplateRef<any>;
  result: string = '';

  constructor(
    @Inject(GlobalOverlayService) private GlobalOverlayService: GlobalOverlayService,
    private Render2: Renderer2,
    private UiPrefs: UiPreferencesService,
    private cd: ChangeDetectorRef,
  ) {
    this.isDarkMode = this.UiPrefs.get(UI_DARK_MODE);
  }

  ngOnInit() {
    this.GlobalOverlayService.overlayShow
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe((value: any) => {
        value ? this.show() : this.hide();
      });

    this.GlobalOverlayService.template
      .subscribe((value) => {
        this.overlayTemplate = value;
        this.cd.markForCheck();
      });

    this.data$ = this.GlobalOverlayService.data
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
      );
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  ngOnChanges() {
    this.cd.markForCheck();
  }

  async show() {
    this.Render2.removeClass(this.el.nativeElement, 'DisplayNone');

    await this.setPosition();

    this.cd.markForCheck();
  }

  setPosition() {
    setTimeout(() => { // timeout to load image before repositioning
      this.Render2.setStyle(this.el.nativeElement, 'left', this.GlobalOverlayService.getPos(this.el, 0));
      this.Render2.setStyle(this.el.nativeElement, 'top', this.GlobalOverlayService.getPos(this.el, 1));

      this.cd.markForCheck();
    });
  }

  hide() {
    this.Render2.addClass(this.el.nativeElement, 'DisplayNone');

    this.Render2.setStyle(this.el.nativeElement, 'top', '-999999px');
    this.Render2.setStyle(this.el.nativeElement, 'left', '-999999px');

    this.cd.markForCheck();
  }

}
