import { Injectable } from '@angular/core';
import { WebsocketService } from '@proman/services/websocket.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WsUpdatesService {
    topics: string[] = [];
    objects: any = {};
    updateTimeStamp: Subject<number> = new Subject<number>();

    constructor(
        private WS: WebsocketService,
    ) {
        this.WS.message.subscribe((data) => {
            if (this.topics.indexOf(data.topic) > -1) {
                Object.assign(this.objects[data.topic], data.data[4]);

                this.updateTimeStamp.next(new Date().getTime());
            }
        })
    }

    initEntity(object: any, entityName: string) {
        this.listen(entityName, object.id, object);
    }

    dropEntity(object: any, entityName: string) {
        this.drop(entityName, object.id);
    }

    listen(entityName: string, id: string | number, object: any) {
        if (!this.WS.client) return;

        const topic = this.getTopicString(entityName, id);
        this.WS.client.subscribe(topic);
        this.topics.push(topic);
        this.objects[topic] = object;
    }

    drop(entityName: string, id: string | number) {
        if (!this.WS.client) return;

        const topic = this.getTopicString(entityName, id);
        this.WS.client.unsubscribe(topic);
        this.topics.splice(this.topics.indexOf(topic, 1));
        delete this.objects[topic];
    }

    getTopicString(entityName: string, id: string | number) {
        return `${this.WS.domain}/entity/${entityName}/${id}`;
    }

}
