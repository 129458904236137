import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UploaderService } from '../../services/uploader.service';

@Component({
    selector: 'pro-entity-table-image-with-upload',
    template: `
        <div fxLayout="row"
             fxLayoutAlign="start center"
             proClickStopPropagation>
            <pro-thumbnail *ngIf="image"
                          [file]="image"></pro-thumbnail>
            <pro-btn
                    *ngIf="!image"
                    (click)="upload()"
                    icon="upload"
                    theme="accent"></pro-btn>
            <pro-btn
                    *ngIf="image"
                    (click)="setEmit(null)"
                    icon="times"
                    theme="warn"></pro-btn>
        </div>
    `
})

export class EntityTableImageWithUploadComponent implements OnInit {
    @Input() item: any;
    @Input() config: any;
    @Output() callback: EventEmitter<any> = new EventEmitter();
    image: any;
    progress: any;

    constructor(private uploader: UploaderService) {}

    ngOnInit() {
        this.image = this.item[this.config.key];
        if(this.config.getValue) {
          this.image = this.config.getValue(this.item);
        }
    }

    uploaderCallback(event: any) {
        this.progress = ((event.loaded / event.total) * 100);
    }

    upload() {
        this.uploader
            .init(this.uploaderCallback)
            .show({ multiple: false })
            .then((response: any) => {

                if (!response[0].error) this.setEmit(response[0]);

            })
            .catch(() => {});
    }

    setEmit(value: any) {
        this.image = value;
        this.item[this.config.config] = value;

        this.callback.emit(value);
    }

}
