import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'pro-entity-table-checkbox',
    template: `
        <pro-checkbox [value]="value"
                     [disabled]="config && config.disabled"
                     (onChange)="callback.emit($event)"
                     proClickStopPropagation
        ></pro-checkbox>
    `
})

export class EntityTableCheckboxComponent implements OnInit {
    @Input() item: any;
    @Input() config: any;
    @Output() callback: EventEmitter<boolean> = new EventEmitter();

    value: boolean;

    constructor() {}

    ngOnInit() {
        let item = this.item;
        let config = this.config;
        this.value = config.getValue ? config.getValue(item) : item[config.key];
    }

}
