import { Injectable } from '@angular/core';
import { resourcesConfig } from '../../resources';

@Injectable({ providedIn: 'root' })
export class TableEntitySearchParametersService {

    constructor() {

    }

    get(Entity: any, searchValue: any) {
        let resourceConfig = resourcesConfig[Entity.entityConfig.name];
        let fields = ['name'];
        let params = {};

        if (resourceConfig && resourceConfig.params.searchFields) {
            fields = resourceConfig.params.searchFields;

        }

        for (let field of fields) {
            params[field] = searchValue;

        }

        return params;
    };

}
