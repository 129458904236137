import { Inject, Injectable } from '@angular/core';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { TableStateParser, TableStateParserType } from '@proman/table';
import { ACL } from '@proman/services/acl.service';

@Injectable()
export class FrontendTableStateParserService implements TableStateParser {

  constructor(
    @Inject(PromanStateService) private PromanState: PromanStateService,
    private ACL: ACL,
  ) {

  }

  goToState = (data: TableStateParserType) => {
    return this.PromanState.to(data.name,data.id || null, data.queryParams, data.newTab);
  };

  getPath = (data: TableStateParserType): [string[], any] => {
    const pathData = this.PromanState.getPath(data.name,data.id || null, data.queryParams);

    if (data.acl && !this.ACL.check(data.acl)) {
      return [[], null];
    }
    return [pathData[0], pathData[1]];
  };

}
