import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { params as buildParams } from '@proman/utils-params';
@Injectable({ providedIn: 'root' })
export class QueryService {

    constructor(
        private Request: RequestService,
    ) {

    }

    get = (url: any, sort: any, sortDir: any, limit: any, offset: any, search: any, filters?: any, date?: any) => {

        let params: any = { limit, offset, search, sort };

        if (filters) {
            for (let key in filters) {
                params[key] = filters[key];
            }
        }

        if (date) {
            if (date.from) params.from = date.from;

            if (date.to) params.to = date.to;

        }

        return this.Request.get(url + '?' + buildParams(params));
    };

}
