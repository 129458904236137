import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'pro-entity-table-status-switcher',
    template: `
        <pro-btn class="confirm"
                icon="check"
                (click)="handleClick(true)"
                [theme]="(value ? 'accent' : 'primary')"></pro-btn>
        <pro-btn class="cancel"
                icon="times"
                (click)="handleClick(false)"
                [theme]="(!value ? 'warn' : 'primary')"></pro-btn>
    `
})

export class EntityTableStatusSwitcherComponent implements OnInit {
    @Input() item: any;
    @Input() config: any;
    @Output() callback: EventEmitter<boolean> = new EventEmitter();
    value: boolean;

    constructor() {

    }

    ngOnInit() {
        this.value = this.item[this.config.key];
    }

    handleClick(value: boolean) {
        this.value = value;
        this.item[this.config.key] = value;

        this.callback.emit(value);
    }

}
