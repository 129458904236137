import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from '@proman/rxjs-common';
import { getCloseDialogTimestamp } from '../../store/system-options';
import { pairwise, takeUntil } from '@proman/rxjs-common';
import { CommonModule } from '@angular/common';
import { PromanButtonComponent } from '@proman/button/proman-button.component';

@Component({
    selector: 'pro-dialog-actions',
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        PromanButtonComponent,
    ],
    template: `
        <div mat-dialog-actions>
            @if ((variant && callback) && !isCallbackHidden) {
                <pro-btn (onClick)="handleClick()"
                         type="submit"
                         [disabled]="isCallbackDisabled"
                         [pattern]="variant"></pro-btn>
            }
            <ng-content></ng-content>
            @if (!(removeCloseButton || hideClose)) {
                <pro-btn (onClick)="dialogRef.close(variant ? undefined : 1)"
                         class="DialogCloseButton"
                         [disabled]="disableClose"
                         [pattern]="(variant === 'create' || variant === 'remove' || variant === 'confirm' || variant === 'save' || variant === 'update') ? 'cancel' : 'close'"
                         [attr.data-variant]="variant ? undefined : 1"
                ></pro-btn>
            }
        </div>
    `
})
export class DialogActionsComponent implements OnDestroy {
    @Input() variant: 'create'|'remove'|'confirm'|'save'|'edit'|'update';
    @Input() isCallbackDisabled: boolean;
    @Input() isCallbackHidden: boolean;
    @Input() preventCloseOnNavigation: boolean;
    @Input() disableClose: boolean = false;
    @Input() hideClose: boolean = false;
    @Input() removeCloseButton: boolean = false;
    @Output() callback: EventEmitter<void> = new EventEmitter<void>();
    clickTimeout: boolean = false;
    destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store,
        public dialogRef: MatLegacyDialogRef<DialogActionsComponent>,
    ) {
        this.store
            .pipe(
                takeUntil(this.destroyed$),
                select(getCloseDialogTimestamp),
                pairwise(),
            )
            .subscribe(([a, b]) => {
               if (a !== b && !this.preventCloseOnNavigation) {
                   this.dialogRef.close();
               }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    handleClick() {
        if (!this.clickTimeout) {
            this.callback.emit();
            this.clickTimeout = true;
            setTimeout(() => this.clickTimeout = false, 500);

        } else {
            return;
        }

    }

}
