import { Directive, Input, OnInit } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';

@Directive({
    selector: '[pmEventEmployeeTimeRange]'
})

export class EventEmployeeTimeRangeDirective implements OnInit {
    @Input('pmEventEmployeeTimeRange') booking: any;

    constructor(private Filter: FilterService) {

    }

    ngOnInit() {
        this.booking._time = this.Filter.timeRange(this.booking.plannedStart, this.booking.plannedEnd);
    }

}
