import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeURL', pure: false })

export class UrlSanitizerPipe implements PipeTransform {

    constructor(
        private Sanitizer: DomSanitizer
    ) {

    }

    transform(value: any): any {
        return this.Sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
