import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { FilterService } from '../../services/filter.service';

@Directive({
    selector: '[proContrastingColor]',
    standalone: true,
})

export class PromanContrastingColorDirective implements OnInit, OnChanges {
    @Input('proContrastingColor') color: string;

    constructor(
        private el: ElementRef,
        private Filter: FilterService,
    ) {

    }

    ngOnInit() {
        this.el.nativeElement.style.color =  this.Filter.contrastingColor(this.getValue(this.color));
    }

    ngOnChanges() {
        this.el.nativeElement.style.color =  this.Filter.contrastingColor(this.getValue(this.color));
    }

    getValue(value: string) {
        return value && value.startsWith('#') ? value.substr(1) : value;
    }

}
