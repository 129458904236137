import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pro-status-switch-checkbox',
    template: `
        <pro-checkbox
                [value]="value"
                (onChange)="handleSwitch($event)"
                [disabled]="config.disabled"
                proClickStopPropagation>
        </pro-checkbox>
    `
})

export class EntityTableStatusSwitchCheckboxComponent implements OnInit {
    @Input() item: any;
    @Input() config: { disabled?: boolean; key: string };
    @Output() callback: EventEmitter<boolean> = new EventEmitter();
    value: boolean;

    constructor() {

    }

    ngOnInit() {
        this.value = this.item[this.config.key];
    }

    handleSwitch(value: boolean) {
        this.value = value;
        this.item[this.config.key] = value;

        this.callback.emit(value);
    }

}
