import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { InlineListService } from '@proman/inline-list/inline-list.service';
import { Entity } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { getIndexById } from '@proman/utils';

@Component({
    selector: 'pm-country-regions',
    template: `
        <div class="Regions"
             fxLayout="row"
             fxLayoutAlign="start center"
             proClickStopPropagation>
            <pro-btn
                    icon="flag"
                    (click)="addRegion($event)"></pro-btn>
            <div class="Regions-List"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center">
                <div *ngFor="let region of item.regions"
                     class="Regions-List--Item"
                     [ngStyle]="{ 'background-color': '#' + region.color }"
                     (click)="removeRegion(region)"
                     [proContrastingColor]="region.color">
                     {{ region.name }} </div>
            </div>
        </div>
    `
})

export class CountryRegionsComponent {
    @Input() item: any;
    @Input() config: any;

    constructor(
        private Entity: Entity,
        private QueryExpression: QueryExpressionService,
        private InlineList: InlineListService,
        private cd: ChangeDetectorRef,
    ) {

    }

    addRegion($event: any) {
        let request: any = {};
        let usedIds: any = [];

        this.item.regions.forEach((region: any) => usedIds.push(region.id));

        if (usedIds?.length) {
            request.id = this.QueryExpression.notIn(usedIds);
        }

        this.Entity.get('region')
            .search(request)
            .then((response: any) => {

                this.InlineList.show({
                    event: $event,
                    data: response,
                    onSelect: (region: any) => {
                        this.item.regions.push(region);
                        this.cd.detectChanges();

                        this.Entity.get({ name: this.config.entity })
                            .addAssociation({
                                id: this.item.id,
                                regions: region.id
                            });
                    }
                });

            });

    }

    removeRegion(region: any) {
        let index: number = getIndexById(this.item.regions, region.id);

        this.Entity.get({ name: this.config.entity })
            .removeAssociation({
                id: this.item.id,
                regions: region.id
            })
            .then(() => {
                this.item.regions.splice(index, 1);
                this.cd.detectChanges();
            });
    }

}
