import { Component, SimpleChanges, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { durationFromSeconds, durationToSeconds, isDefined, zeroPad } from '@proman/utils';

type TimeIntervalVisibilityType = 'auto' | boolean;

interface TimeIntervalVisibilityInterface {
    days: TimeIntervalVisibilityType;
    hours: TimeIntervalVisibilityType;
    minutes: TimeIntervalVisibilityType;
    seconds: TimeIntervalVisibilityType;
}

const DEFAULT_SETTINGS: TimeIntervalVisibilityInterface = {
    days: true,
    hours: true,
    minutes: true,
    seconds: true
};

@Component({
    selector: 'pm-time-interval',
    template: `
        <div class="TimeInterval FormItem">
            <div fxLayout="row"
                 fxLayoutAlign="start center">
                <div class="FormItem-label">
                    {{ config.label | translate }}
                </div>
                <div class="FormItem-help"></div>
            </div>
            <div fxLayout="row" class="TimeInterval-InputContainer" [ngClass]="{ 'FixAlignMargin': config.fixAlignMargin }">
                <pm-txt *ngIf="visibilitySettings.days"
                        [value]="viewVal.days"
                        [config]="{ label: '', suffix: 'd', parseNumber: true }"
                        (onChange)="set('days', $event)"
                        [disabled]="disabled"
                        class="TimeInterval-InputContainer__Days"></pm-txt>
                <pm-txt *ngIf="visibilitySettings.hours"
                        [value]="viewVal.hours"
                        [config]="{ label: '', suffix: 'h', parseNumber: true }"
                        (onChange)="set('hours', $event)"
                        [disabled]="disabled"
                        class="TimeInterval-InputContainer__Hours"></pm-txt>
                <pm-txt *ngIf="visibilitySettings.minutes"
                        [value]="viewVal.minutes"
                        [config]="{ label: '', suffix: 'm', parseNumber: true }"
                        (onChange)="set('minutes', $event)"
                        [disabled]="disabled"
                        class="TimeInterval-InputContainer__Minutes"></pm-txt>
                <pm-txt *ngIf="visibilitySettings.seconds"
                        [value]="viewVal.seconds"
                        [config]="{ label: '', suffix: 's', parseNumber: true}"
                        (onChange)="set('seconds', $event)"
                        [disabled]="disabled"
                        class="TimeInterval-InputContainer__Seconds"></pm-txt>
            </div>
        </div>
    `,
    styles: [`
        .TimeInterval { margin-top: -2px; }
        .TimeInterval-InputContainer { margin-top: -12px; }
        :host pm-txt { width: 30px; padding-right: 2px; }
        :host .FixAlignMargin { margin-top: 1px; }
        :host .FormItem-label { font-size: 10px; }
    `]
})

export class TimeIntervalComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any = {};
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    visibilitySettings: TimeIntervalVisibilityInterface;
    viewVal: any = {};

    ngOnInit() {
        this.setVisibilitySettings();

        if (!this.value || this.config.setZeroValue) {
            this.setViewVal(this.value || 0);

        }

    }

    ngOnChanges(changes: SimpleChanges) {
        let config = changes.config;
        let value = changes.value;

        if (config && config.currentValue.visibility || !this.visibilitySettings) {
            this.setVisibilitySettings();

        }

        if (value && value.currentValue) {
            this.setViewVal(changes.value.currentValue);

        }

    }

    setVisibilitySettings() {
        this.visibilitySettings = Object.assign({},
                DEFAULT_SETTINGS,
                this.config && this.config.visibility || {}
            );

    }

    parseValue() {
        let days = parseInt(this.viewVal.days);
        let hours = parseInt(this.viewVal.hours);
        let minutes = parseInt(this.viewVal.minutes);
        let seconds = parseInt(this.viewVal.seconds);

        return durationToSeconds(days, hours, minutes, seconds);
    }

    set(key: any, value: any) {
        let seconds;

        this.updateBlock(key, value);

        seconds = this.parseValue();

        this.setViewVal(seconds);

        this.onChange.emit(seconds);
    }

    setViewVal(seconds: any) {
        let interval = durationFromSeconds(seconds);

        this.updateBlock('days', interval.d);
        this.updateBlock('hours', zeroPad(interval.h));
        this.updateBlock('minutes', zeroPad(interval.m));
        this.updateBlock('seconds', zeroPad(interval.s));
    }

    updateBlock(model: any, value: number) {
        this.viewVal[model] = value;
        let settings = this.visibilitySettings;

        settings[model] = settings[model] === 'auto' ? value > 0 : settings[model];
    }
}
