import {
    Component,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { TagType } from '../resources/tag';
import { Entity, EntityInterface, EntityNameType } from '../services/entity.service';
import { QueryExpressionService } from '../services/query-expression.service';
import { getIndexById, mapId } from '../utils';
import { ParametersOptionsService } from '../services/parameters-options.service';
import { Tag } from '../interfaces/entity-interfaces';
import { InlineListService } from '../inline-list/inline-list.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '../store/curr-user';
import { NgIf } from '@angular/common';
import { Fa6Module } from '../fa/fa6.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanContrastingColorDirective } from '../shared/directives/proman-contrasting-color.directive';

@Component({
    selector: 'pro-tags',
    standalone: true,
    imports: [
        NgIf,
        Fa6Module,
        FlexLayoutModule,
        PromanContrastingColorDirective,
    ],
    template: `
        <div class="Tags"
             fxLayout="row"
             fxLayoutAlign="start center"
             (click)="$event.stopPropagation()">
            @if (config?.entity) {
                <fa name="tags"
                    class="RightPadding Clickable"
                    (click)="addTag($event)"></fa>
            }
            <div class="Tags-List"
                 fxLayout="row wrap"
                 fxLayoutAlign="start center">
                @for (tag of (item?.tags || []); track tag) {
                    @if (!isCustomer || (isCustomer && tag.public)) {
                        <div class="Tags-List--Item"
                             [ngStyle]="{ 'background-color': '#' + tag.color }"
                             (click)="removeTag(tag)"
                             [title]="tag.description || ''"
                             [proContrastingColor]="tag.color">
                            {{ tag.icon }} {{ tag.name }} </div>
                    }
                }
            </div>
        </div>
    `,
    styles: [`
        .Tags {
            padding-left: 5px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagsComponent implements OnInit, OnChanges {
    @Input() item: any = { tags: [] };
    @Input() config: { entity: EntityNameType, tagType?: TagType };
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    entity: EntityInterface;
    isCustomer: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private Entity: Entity,
        private queryExpression: QueryExpressionService,
        private InlineList: InlineListService,
        private ParametersOptions: ParametersOptionsService,
        private store: Store<unknown>,
    ) {
        this.store.select(getCurrUser).subscribe((value) => {
            if (value) {
                this.isCustomer = value.isCustomer;
            }
        })
    }

    ngOnInit() {
        if (this.config && this.config.entity) this.entity = this.Entity.get(this.config.entity);

        this.cd.markForCheck();
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    addTag(event: MouseEvent) {
        const request: any = { sort: { priority: 'desc' } };
        if (this.isCustomer) {
            Object.assign(request, { public: true });
        }
        if (this.config.tagType) {
            Object.assign(request, { type: this.queryExpression.orNull(this.config.tagType) });
        }
        const usedTagsIds: any = [];

        if (this.item && this.item.tags) this.item.tags.forEach((tag: any) => usedTagsIds.push(tag.id));

        if (usedTagsIds.length) request.id = this.queryExpression.notIn(usedTagsIds);

        this.ParametersOptions
            .search({ entity: 'tag', entityParams: request })
            .then((response: any) => {

              response.forEach((tag: Tag) => {
                if (tag.icon) tag.name = `${tag.icon} ${tag.name}`;
              });

                this.InlineList.show({
                    event,
                    data: response,
                    onSelect: (tag: Tag) => {
                        tag.name = tag.name.replace(tag.icon, '');
                        this.item.tags.push(tag);
                        this.cd.markForCheck();

                        this.onChange.emit(this.item.tags.map(mapId));

                        if (this.item.id) {
                            this.entity
                                .addAssociation({
                                    id: this.item.id,
                                    tags: tag.id
                                }).then(() => console.log(this.item));
                        }
                    }
                });

            });

    }

    removeTag(tag: Tag) {
        const index: number = getIndexById(this.item.tags, tag.id);

        const splice = () => { this.item.tags.splice(index, 1); };

        if (this.item.id) {
            this.entity
                .removeAssociation({
                    id: this.item.id,
                    tags: tag.id
                })
                .then(() => {
                    splice();
                    this.cd.markForCheck();
                });
        } else {
            splice();
        }
    }

}
