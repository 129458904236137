import { Component, EventEmitter, OnInit } from '@angular/core';
import { Money } from '@proman/interfaces/common.interface';
import { ShipmentOrderEntityInterface } from '@proman/resources/shipment_order';
import { Entity } from '@proman/services/entity.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { BehaviorSubject, from } from 'rxjs';

@Component({
    selector: 'pm-shipment-provider-calculate-cell',
    template: `
        <pro-editable-container>{{ testEmitter$ | async }}</pro-editable-container>
    `
})

export class ShipmentProviderCalculateCellComponent implements OnInit {
    onChange: EventEmitter<Money> = new EventEmitter<Money>();
    value: any = '-';
    shipmentOrderEntity: ShipmentOrderEntityInterface;
    currUser: CurrUser;
    item: any;
    testEmitter$ = new BehaviorSubject<string>(this.value);

    constructor(private Entity: Entity) {
        this.shipmentOrderEntity = this.Entity.get('shipment_order');
    }

    ngOnInit() {
        if (this.item?.calculate) {
            this.value = 'Calculating...';
            this.handleCalculate();
        }
    }

    handleCalculate() {
        let req = this.shipmentOrderEntity.calculate({
            shipment: this.item.shipment,
            provider: this.item.provider,
            method: this.item.method
        });
        from(req).subscribe((thing) => {
            this.value = thing.amount + ' ' + thing.currency;
            this.testEmitter$.next(this.value)
        });
    }

}
