import {
    Input,
    Output,
    Component,
    EventEmitter,
    SimpleChanges,
    ViewChild,
    OnChanges,
    OnInit, ElementRef
} from '@angular/core';
import moment from 'moment';
import { isObject, twoDigit } from '../utils';

@Component({
    selector: 'pro-time',
    template: `
        <div class="Time" fxLayout="row" fxLayoutAlign="start center" #timeBlockEl>
            <div class="Time-Label">
                {{ config.label | translate }}
            </div>
            <pro-text-simple class="Time-Block"
                    [value]="hours"
                    [config]="{ label: 'hour_shorthand' }"
                    proParseTime="hours"
                    (input)="handleHourInput()"
                    (onChange)="set('hours', $event)"
                    [disabled]="disabled"></pro-text-simple>
            <pro-text-simple class="Time-Block Time-Block-minutes"
                    [value]="minutes"
                    [config]="{ label: 'minute_shorthand' }"
                    proParseTime="minutes"
                    (onChange)="set('minutes', $event)"
                    [disabled]="disabled"></pro-text-simple>
        </div>
    `
})

export class PromanTimeComponent implements OnInit, OnChanges {
    @Input() config: {
        label?: string;
        isUtcTime?: boolean;
    } = {};
    @Input() value: any;
    @Input() disabled: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('timeBlockEl', { static: true }) timeBlockEl: ElementRef;

    utcOffset: number = 0;
    isDateTimeModel: any = false;
    datetime: any;
    hours: any;
    minutes: any;
    hourInputClicks: number = 0;

    constructor(

    ) {

    }

    ngOnInit() {
        this.utcOffset = moment().utcOffset() / 60;
        this.init(this.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.init(changes.value.currentValue);

        }

    };

    init(value: string|null|{date: string}|any) {
        if (isObject(value)) {
            value = value.date
        }

        let time;
        let timeParts;

        if (value) {

            if (value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}/)) {
                this.datetime = moment(value);
                this.isDateTimeModel = true;

            } else if (value.match(/\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}/)) {
                this.datetime = moment(moment().format('YYYY-MM-DD') + 'T' + value);
                this.isDateTimeModel = false;

            } else if (value.match(/\d{2}:\d{2}:\d{2}/)) {
                timeParts = value.split(':');

                this.datetime = moment()
                    .hours(+timeParts[0])
                    .minutes(+timeParts[1])
                    .seconds(+timeParts[2]);
                this.isDateTimeModel = false;

            } else {
                throw Error('Invalid format for pmTime: ' + value);

            }

            time = this.datetime.format().split('T')[1].split(':');

            this.hours = time[0];
            this.minutes = time[1];

            if (this.config.isUtcTime) {
                this.hours = twoDigit(this.fromUtc(+this.hours));

            }

        } else {
            this.hours = null;
            this.minutes = null;

        }

    };

    update(value: string) {
        if (this.config.isUtcTime) {
            const parts = value.split(':');
            parts[0] = twoDigit(this.toUtc(+parts[0]));
            this.onChange.emit(parts.join(':'));
        } else {
            this.onChange.emit(value);
        }
    };

    set(property: any, value: any) {
        let dateTimeStr;

        this[property] = value;

        if (!this.hours) {
            this.hours = '00';

        }

        if (!this.minutes) {
            this.minutes = '00';

        }

        if (this.isDateTimeModel) {
            dateTimeStr = moment(this.datetime)
                .hours(this.getTimeString(this.hours))
                .minutes(this.getTimeString(this.minutes))
                .format();

            this.value = dateTimeStr;

            this.update(dateTimeStr);

        } else {
            this.value = `${twoDigit(this.getTimeString(this.hours))}:${twoDigit(this.getTimeString(this.minutes))}:00`;

            this.update(this.value);

        }

    };

    getTimeString = (value: string) => +twoDigit(parseInt(value).toString());

    handleHourInput = () => {
        this.hourInputClicks++;

        if (this.hourInputClicks === 2) {
            this.hourInputClicks = 0;

            this.timeBlockEl.nativeElement.querySelector('.Time-Block-minutes input').focus();
            document.execCommand('selectAll', false, '');

        }

        setTimeout(() => this.hourInputClicks = 0, 3000);
    };

    toUtc(value: number) {
        return this.negativeFormat(value - this.utcOffset);
    }

    fromUtc(value: number) {
        return this.negativeFormat(value + this.utcOffset);
    }

    negativeFormat(value: number) {
        if (value >= 24) {
            return value - 24;
        }
        if (value < 0) {
            return value + 24;
        }
        return value;
    }

}
