import { Component, Input } from '@angular/core';
import { OrderProduct } from '@proman/interfaces/entity-interfaces';
import { Store } from '@ngrx/store';
import { getDecimalPlaces } from '@proman/store/system-options';

@Component({
    selector: 'pm-order-product-shipments-cell',
    template: `
        <div *ngFor="let shipmentProduct of item?.shipmentProducts">
            <ng-container *ngIf="!shipmentProduct.shipment.deleted">
                <b>{{ shipmentProduct.shipment.date | proDateTime }}: </b>
                <span>{{ shipmentProduct.quantity | proDecimal: this.getDecimalPlaces }}</span>
            </ng-container>
        </div>
    `,
    styles: [' b { white-space: nowrap; } ']
})

export class OrderProductShipmentCellComponent {
    @Input() item: OrderProduct;
    getDecimalPlaces: number;

    constructor(private store: Store) {
        this.store.select(getDecimalPlaces, 'product_quantity')
            .subscribe((value) => this.getDecimalPlaces = value);
    }
}
