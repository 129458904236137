import { ModuleWithProviders, NgModule } from "@angular/core";
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { ListManagerComponentsMappings } from "@proman/list-manager/types";
import { LIST_MANAGER_DYNAMIC_TEMPLATES } from "@proman/list-manager/constants";
import { ListManagerDynamicTemplateComponent } from "@proman/list-manager/list-manager-dynamic-template.component";
import { ListManagerComponent } from "@proman/list-manager/list-manager.component";
import { PromanButtonComponent } from "@proman/button/proman-button.component";
import { PromanCheckboxComponent } from "@proman/checkbox/proman-checkbox.component";
import { PromanSelectComponent } from "@proman/select";
import { PromanThumbnailComponent } from "@proman/common-components/components/proman-thumbnail.component";

const COMPONENTS = [
  ListManagerComponent,
  ListManagerDynamicTemplateComponent,
];

@NgModule({
  imports: [
    CommonModule,
    DragulaModule.forRoot(),
    MatExpansionModule,
    MatLegacyListModule,
    FlexLayoutModule,
    PromanCommonComponentsModule,
    PipesModule,
    SharedDirectivesModule,
    RouterModule,
    PromanColorComponent,
    PromanButtonComponent,
    PromanCheckboxComponent,
    PromanSelectComponent,
      PromanThumbnailComponent,
  ],
  providers: [],
  declarations: COMPONENTS,
  exports: COMPONENTS
})

export class PromanListManagerModule {
  static forRoot(config: {
    templates: ListManagerComponentsMappings,
  }): ModuleWithProviders<PromanListManagerModule> {
    return {
      ngModule: PromanListManagerModule,
      providers: [
        {
          provide: LIST_MANAGER_DYNAMIC_TEMPLATES,
          useValue: config.templates
        }
      ]
    }
  }
}
