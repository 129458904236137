import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[proDelayedIf]'
})
export class DelayedIfDirective {
    @Input() set proDelayedIf(config: { render: boolean, in: number, out?: number, }) {
        if (config.render) {
            setTimeout(() => this.viewContainer.createEmbeddedView(this.templateRef), config.in);
        } else {
            setTimeout(() => this.viewContainer.clear(), config.out || 0);
        }
    }
    constructor (
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}
}
