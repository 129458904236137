import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../shared/pipes/pipes.module';

import { LabelComponent } from './components/label.component';
import { PaginationComponent } from './components/pagination.component';
import { NoRecordsComponent } from './components/no-records.component';
import { DialogTitleComponent } from './standalone/dialog-title.component';
import { DialogActionsComponent } from './standalone/dialog-actions.component';
import { PromanThumbnailComponent } from './components/proman-thumbnail.component';
import { MoveHandleComponent } from './components/move-handle.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PromanButtonComponent } from '../button/proman-button.component';
import { PreviewComponent } from './components/preview.component';
import { GlobalOverlayModule } from '../overlay/global-overlay.module';
import { EditableContainerComponent } from './components/editable-container.component';

const COMPONENTS = [
    PaginationComponent,
    MoveHandleComponent,
    EditableContainerComponent
];

@NgModule({
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        MatLegacyButtonModule,
        FlexLayoutModule,
        Fa6Module,
        PipesModule,
        PromanButtonComponent,
        SharedDirectivesModule,
        GlobalOverlayModule,
        DialogTitleComponent,
        DialogActionsComponent,
        PreviewComponent,
        LabelComponent,
        PromanThumbnailComponent,
        NoRecordsComponent,
    ],
    declarations: COMPONENTS,
    exports: [
        ...COMPONENTS,
        DialogTitleComponent,
        DialogActionsComponent,
        LabelComponent,
        NoRecordsComponent,
    ]
})

export class PromanCommonComponentsModule {

}
