import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { EntityRemoveDialogComponent } from '../components/entity-remove-dialog.component';
import { EntityCreateDialogComponent } from '../components/entity-create-dialog.component';
import { EntityEditDialogComponent } from '../components/entity-edit-dialog.component';
import { ObservablesService } from '@proman/services/observables.service';
import { isDefined } from '@proman/utils';
import { EntityNameType } from '@proman/services/entity.service';
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';
import { ConfirmActionDialogComponent } from '@proman/shared-dialogs/dialogs/confirm-action-dialog.component';

export interface DialogOptions {
    disableAutoFocus?: boolean;
    width?: string;
    refreshOnClose?: boolean;
    disableClose?: boolean;
}

export interface DialogData {
    entity?: EntityNameType;
    mainField?: Partial<EntityItemFieldConfig>;
    parameters?: any[];
    header?: string;
    [key: string]: unknown;
}

@Injectable({ providedIn: 'root' })
export class Dialog {

    constructor(
        private MatLegacyDialog: MatLegacyDialog,
        private Observables: ObservablesService,
    ) {

    }

    open2(Component: any, data: DialogData = {} as DialogData, options?: DialogOptions) {
        this.Observables.dialogTimeStamp.next(new Date().getTime());

        return this.MatLegacyDialog.open(Component,
            {
                data,
                autoFocus: !(options && options.disableAutoFocus),
                width: (options?.width) || '',
                disableClose: (options && options.disableClose)
            }
        );
    }

    open(Component: any, data?: DialogData, options?: DialogOptions) {
        return new Promise((resolve, reject) => {
            this.open2(Component, data, options)
                .afterClosed()
                .subscribe((result: unknown) => {
                    return (isDefined(result) || options && options.refreshOnClose) && resolve(result);
                });
        })
    }

    confirm($event: any, question: any, callback: any) {
        const dialogRef = this
            .open2(ConfirmActionDialogComponent, { question });

        dialogRef
            .afterClosed()
            .subscribe((response: any) => {
                if (response) callback();
            });
    }

    confirm2($event: any, question: any) {
        const dialogRef = this.open2(ConfirmActionDialogComponent, { question });

        return dialogRef.afterClosed();
    }

    confirmRemove(item: any, entity: any, callback?: any) {
        const dialogRef = this
            .open2(EntityRemoveDialogComponent, { item, entity });

        dialogRef
            .afterClosed()
            .subscribe((response: any) => {
                if (response && callback) callback();

            });
    }

    entityCreate(entity: any, params?: any, config?: any, options?: any) {
        let data: any = { entity };

        if (params) {
            data.params = params;

        }

        if (config) {
            data = Object.assign(config, data);

        }

        return this
            .open2(EntityCreateDialogComponent, data, options)
            .afterClosed()
            .toPromise()
            .then((response: any) => {
                return new Promise((resolve) => {
                    if (response) {
                        resolve(response)
                    }
                })
            });
    }

    entityEdit(entity: any, item: any, config: any = {}) {
        let data: any = { entity, item };

        if (config) data = Object.assign(data, config);

        return this.open2(EntityEditDialogComponent, data)
    }

}
