import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { PromanExpressionModule } from '@proman/expression/proman-expression.module';
import { ParameterDirective } from './parameter.directive';
import { ParametersService } from '@proman/parameters/services/parameters.service';
import { ParameterComponent } from './components/parameter.component';
import { PromanListManagerModule } from '@proman/list-manager/list-manager.module';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { ParameterDropdownComponent } from '@proman/parameters/components/parameter-dropdown.component';
import { ParameterGroupComponent } from './components/parameter-group.component';
import { ParameterGroupNameComponent } from './components/parameter-group-name.component';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { PARAMETER_DYNAMIC_TEMPLATES } from './constants';
import { ParameterComponentsMappings } from './types';
import { UniqueValidatorService } from '@proman/services/unique-validator.service';
import { ParameterResolver } from '@proman/parameters/parameters.resolvers';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        PipesModule,
        DragulaModule.forRoot(),
        PromanCommonComponentsModule,
        PromanExpressionModule,
        PromanListManagerModule,
        PromanButtonComponent,
        PromanTextSimpleComponent,
        PromanAutocompleteComponent,
        SharedDirectivesModule,
    ],
    providers: [
        ParametersService,
        UniqueValidatorService,
        ParameterResolver,
    ],
    declarations: [
        ParameterComponent,
        ParameterGroupComponent,
        ParameterGroupNameComponent,
        ParameterDropdownComponent,
        ParameterDirective,
    ],
    exports: [
        ParameterComponent,
        ParameterDropdownComponent,
        ParameterDirective,
    ]
})

export class PromanParametersModule {
    static forRoot(config: {
        templates: ParameterComponentsMappings,
    }): ModuleWithProviders<PromanParametersModule> {
        return {
            ngModule: PromanParametersModule,
            providers: [
                {
                    provide: PARAMETER_DYNAMIC_TEMPLATES,
                    useValue: config.templates
                }
            ]
        }
    }
}
