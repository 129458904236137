import { Directive, Input, ElementRef } from '@angular/core';
import { twoDigit } from '../utils';

const LIMITS = {
    hours: [0, 23],
    minutes: [0, 59]
};

@Directive({
    selector: '[proParseTime]',
    host: { '(input)': 'parseValue()' }
})
export class ParseTimeDirective {
    @Input('proParseTime') type: string;

    constructor(
        private elementRef: ElementRef,
    ) {

    }

    private parseValue() {
        let input = this.elementRef.nativeElement.querySelector('input') as HTMLInputElement;
        let value = input.value;
        let parsedValue = value.replace(/[A-Za-z$-]/g, '');
        let limitHigh = LIMITS[this.type][1];

        parsedValue = twoDigit(parsedValue);

        if (parseInt(parsedValue) > limitHigh) {
            parsedValue = limitHigh;

            input.value = parsedValue;

        }

        if (value.length < 2) {
            input.value = twoDigit(parsedValue);

        }

    }

}
