import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Entity } from '@proman/services/entity.service';
import { Action } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pro-parameter-group',
    template: `
        <div class="List"
             fxLayout="column">
            <div class="List-header">
                <pro-btn
                        (click)="addLayer($event)"
                        *ngIf="!config.hideAddLayer && !disabled"
                        size="1"
                        icon="plus"
                        theme="accent"></pro-btn>
            </div>
            <div fxLayout="column"
                 [dragula]="dragulaId"
                 [dragulaModel]="config.parameter.parameters">
                <div class="List-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px"
                    *ngFor="let parameter of config.parameter.parameters">
                    <pro-move-handle *ngIf="config.isMove && !disabled"
                                    [class]="'ParametersGroup-moveHandle'"></pro-move-handle>

                    <pro-parameter-group-name [config]="{ label: parameter.parameter.name }"
                                             [value]="parameter.value"
                                             [parameter]="parameter"
                                             (onChange)="updateGroupName($event, parameter)"
                                             [disabled]="disabled"></pro-parameter-group-name>

                    <pro-parameter *ngFor="let child of parameter.children"
                                  [parameter]="child"
                                  [config]="{ label: child.parameter.name, article: config.article }"
                                  (onChange)="update(child, $event)"
                                  [disabled]="disabled" fxFlex ></pro-parameter>

                    <div *ngIf="config.groupActions?.length && !disabled">
                        <pro-btn
                                *ngFor="let action of config.groupActions"
                                (click)="action.callback($event, parameter)"
                                [icon]="action.icon"
                                [size]="'1x'"
                                [theme]="action.theme"
                                [disabled]="disabled"></pro-btn>
                    </div>

                    <div *ngIf="config.entity.startsWith('article')">
                        <pro-btn *ngIf="parameter.autoCreated"
                                (click)="updateParameter(parameter, 'autoCreated', !parameter.autoCreated); parameter.autoCreated = !parameter.autoCreated"
                                icon="plus-octagon"
                                size="1"
                                theme="accent"></pro-btn>

                        <pro-btn *ngIf="!parameter.autoCreated"
                                (click)="updateParameter(parameter, 'autoCreated', !parameter.autoCreated); parameter.autoCreated = !parameter.autoCreated"
                                icon="plus-octagon"
                                size="1"
                                theme="grey"></pro-btn>
                    </div>

                    <pro-btn *ngIf="!disabled"
                            (click)="config.removeLayer(parameter)"
                            icon="trash"
                            size="1"
                            theme="warn"></pro-btn>
                </div>
            </div>
        </div>
    `
})
export class ParameterGroupComponent implements OnDestroy {
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() value: any;
    @Input() config: any;
    @Input() actions: Action[];
    @Input() disabled: any;
    dragulaId: any;
    dragParameter: any;
    subscriberDrag: any;
    subscriberDrop: any;
    isMovable: any;
    entity: any;

    constructor(private Dragula: DragulaService, private Entity: Entity) {
        this.dragulaId = 'parameter-group' + new Date().valueOf();
        Dragula.createGroup(this.dragulaId, {
            moves: (el: any, source: any, element: HTMLElement) => {
                const isMove = (element: HTMLElement) => element.classList.contains('ParametersGroup-moveHandle');

                this.isMovable = isMove(element) || isMove(element.parentElement) || isMove(element.parentElement.parentElement);

                return this.isMovable;
            },
            invalid: (el: any, element: HTMLElement) => {

                const isParameter = (element: HTMLElement) => element.classList.contains('Parameters-moveHandle');

                return isParameter(element) || isParameter(element.parentElement) || isParameter(element.parentElement.parentElement);
            },
        });
        this.subscriberDrag = Dragula.drag(this.dragulaId).subscribe(({ name, el, source }) => {

            if (this.isMovable) {
                let index = [].slice.call(el.parentElement.children).indexOf(el);

                this.dragParameter = Object.assign({}, this.config.parameter.parameters[index]);

            }

        });

        this.subscriberDrop = Dragula.drop(this.dragulaId).subscribe(({ name, el, source }) => {

            if (this.isMovable) {
                let index = [].slice.call(el.parentElement.children).indexOf(el);
                let swapParameter = this.config.parameter.parameters[index];

                if (swapParameter) {
                    this.config.setPosition(this.dragParameter, swapParameter, );
                }
                this.dragParameter = null;
            }
        });
    }

    ngOnInit() {
        this.entity = this.Entity.get({ name: this.config?.entity });
        if (this.config?.parameter?.parameters) {
            this.config?.parameter?.parameters.forEach((par: any) => {
                par.children.sort((param1: any, param2: any) => {
                    return param1.parameter.position - param2.parameter.position;
                })
            })
        }
    }

    ngOnDestroy() {
        this.Dragula.destroy(this.dragulaId);
        this.subscriberDrag.unsubscribe();
        this.subscriberDrop.unsubscribe();
    }

    update(parameter: any, value: any) {
        this.config.updateChild(parameter, value, this.config.parameter.parameters);
    }

    updateParameter(parameter: any, key: string, value: any) {
        return this.entity.update({ id: parameter.id, [key]: value });
    }

    updateGroupName(value: any, parameter: any) {
        return this.entity.update({ id: parameter.id, value });
    }

    addLayer($event: any) {
        this.config.addLayer($event, this.config.parameter, this.config.parameter.parameters);
    }
}
