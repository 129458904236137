import {
    Directive,
    Input,
    ViewContainerRef,
    TemplateRef,
    OnInit,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { isArray, isDefinedNotNull, isEquivalent } from '../../utils';

// example
// *proFor="let item of items; offset: 5;renderInterval: 200; renderIterator: 5;"

@Directive(
    {
        selector: '[proFor][proForOf]',
        standalone: true,
    })
export class For2Directive implements OnInit, OnChanges {
    @Input() set proForOf(collection: unknown[]) {

        if (!isArray(collection)) {
            console.warn('proFor Error: value ', collection , ' should be of array!');
            this.collection = [];
        }

        const defautlSet = () => {
            this.collection = collection;
            this.incrementalRender = false;
            this.view.clear();
            this.viewClearIter = this.viewIter;
            this.renderMap = {};
        };

        if (this.collection?.length && collection?.length && this.collection.length <= collection.length) {
            if (
                this.collection.every((item, i) => {
                    return isEquivalent(item, collection[i]);
                })
            ) {
                this.collection = this.collection.concat(collection.filter((c, i) => i >= this.collection.length));
                this.incrementalRender = true;
                this.incrementalRenderIndex = this.collection.length;
            } else {
                defautlSet();
            }
        } else {
            defautlSet();

        }

        this.viewIter++;


        this.getRenderTimeoutMap();
    }

    @Input('proForOffset') offset: number = 0;
    @Input('proForRenderInterval') interval: number = 0;
    @Input('proForRenderIterator') iterator: number = 1;

    collection: unknown[];
    inited: true;
    incrementalRender: boolean;
    incrementalRenderIndex: number = 0;

    viewIter: number = 0;
    viewClearIter: number = 0;

    renderTimeoutMap: { [key: number]: number } = {};
    renderMap: { [key: number]: boolean } = {};

    constructor(private view: ViewContainerRef, private template: TemplateRef<any>, private cd: ChangeDetectorRef) { }

    ngOnInit() {
    }

    getRenderTimeout(index: number) {
        let result;
        if (index < this.offset) {
            result = 0
        } else {
            result = Math.ceil((index - this.offset) / this.iterator) * this.interval;
        }
        return result;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['proForOf']) {
            this.renderView();
        }
    }

    renderView() {
        const viewIter = this.viewIter;
        this.collection?.forEach((item, index) =>
        {
            const first = index === 0;
            const last = this.collection.length === index + 1;

            const renderCallback = () => {
                if (this.viewClearIter <= viewIter) {
                    if (!this.renderMap[index]) {
                        this.renderMap[index] = true;
                        this.view.createEmbeddedView( this.template, { $implicit: item, index, first, last });
                        const shouldMark = !last && this.renderTimeoutMap[index] !== this.renderTimeoutMap[index+1] || last;
                        if (shouldMark) this.cd.markForCheck();
                    }

                }

            };

            if (!this.incrementalRender || this.incrementalRenderIndex >= index) {
                if (!this.renderTimeoutMap[index]) {
                    renderCallback();
                } else {
                    setTimeout(renderCallback, this.renderTimeoutMap[index]);
                }
            }

        });
    }

    getRenderTimeoutMap() {
        this.renderTimeoutMap = isDefinedNotNull(this.collection) ? this.collection.reduce((a, b, i) => { a[i] = this.getRenderTimeout(i); return a; }, {}) as { [key: number]: number } : this.collection as any;
    }

}
