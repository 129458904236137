import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    EventEmitter, Inject,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild
} from '@angular/core';

import { PromanSelectComponent } from '@proman/select/proman-select.component';
import { PromanDatepickerComponent } from '@proman/datepicker/proman-datepicker.component';
import { PromanTimeComponent } from '@proman/datepicker/proman-time.component';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { PromanCheckboxComponent } from '@proman/checkbox/proman-checkbox.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RangeValidator } from '@proman/validators/range.validator';
import { NumberValidator } from '@proman/validators/number.validator';
import { LabelComponent } from '@proman/common-components/components/label.component';
import { PromanTimeDropdownComponent } from '@proman/datepicker/proman-time-dropdown.component';
import { ParameterComponentType } from '@proman/interfaces/object-interfaces';
import { UniqueValidatorService } from '@proman/services/unique-validator.service';
import { ToastService } from '@proman/services/toast.service';
import { EntityNameType } from '@proman/services/entity.service';
import { EditableExpressionComponent } from '@proman/expression/components/editable-expression.component';
import { ParameterDirective } from '../parameter.directive';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { ParameterDropdownComponent } from './parameter-dropdown.component';
import { ParameterGroupComponent } from './parameter-group.component';
import { PARAMETER_DYNAMIC_TEMPLATES } from '@proman/parameters/constants';
import { ParameterComponentsMappings } from '@proman/parameters/types';
import { ListManagerComponent } from '@proman/list-manager/list-manager.component';
import { isDefinedNotNull } from '@proman/utils';

export class ParameterItem {
    constructor(
        public component: Type<any>,
        public value: any,
        public config: any,
        public disabled: any,
        public onChange: EventEmitter<any>
    ) {

    }
}

export interface ParameterItemComponent {
    value: any;
    config: any;
    disabled: any;
    onChange: EventEmitter<any>;
    control: any;
    options: any;
}

@Component({
    selector: 'pro-parameter',
    template: `
        <ng-template proParameterHost></ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(ParameterDirective, { static: true }) parameterHost: ParameterDirective;
    @Input() changeDetectionValue: any; // is used only for change detection purposes
    @Input() parameter: any;
    @Input() config: any;
    @Input() entity: EntityNameType = 'production_parameter';
    @Input() disabled: any;
    @Input() form: UntypedFormGroup;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    componentRef: any;

    constructor(
      @Inject(PARAMETER_DYNAMIC_TEMPLATES) private mappings: ParameterComponentsMappings,
      private componentFactoryResolver: ComponentFactoryResolver,
      private UniqueValidatorService: UniqueValidatorService,
      private Toast: ToastService
    ) {
    }

    ngOnInit() {
        if (this.parameter) this.loadComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        const parameter = changes.parameter;
        const disabled = changes.disabled;
        const value = changes.changeDetectionValue;
        let hasChanges = false;

        if (parameter && !parameter.isFirstChange()) {
            this.loadComponent();

        }

        if (this.componentRef) {

            if (value && !value.isFirstChange() && value.currentValue !== value.previousValue) {
                (<ParameterItemComponent>this.componentRef.instance).value = value.currentValue;
                hasChanges = true;

            }

            if (typeof disabled !== 'undefined' && !disabled.isFirstChange() && disabled.currentValue !== disabled.previousValue) {
                (<ParameterItemComponent>this.componentRef.instance).disabled = disabled.currentValue;
                hasChanges = true;

            }

            if (typeof this.componentRef.instance.ngOnChanges === 'function' && hasChanges) {
                this.componentRef.instance.ngOnChanges(changes);

            }

        }

    }

    ngOnDestroy() {

        if (this.form) this.form.removeControl(this.parameter.id);

    }

    loadComponent() {
        const parameter = this.parameter.parameter ? this.parameter.parameter : this.parameter;
        let componentConfig: any = {
            label: this.config?.showId && parameter.id ? `${parameter.name} - ${parameter.id}` : parameter.name,
            isDefinition: this.config?.isDefinition,
            parameter: this.parameter
        };
        const type: ParameterComponentType = parameter.type;
        const parameterFilter = this.parameter._filter || this.parameter.filter || parameter.filter || {}; // _filter - overridden filter values
        let component;
        let parameterItem;
        let componentFactory;
        let viewContainerRef;
        switch (type) {

            case 'string':
                component = PromanTextSimpleComponent;

                break;

            case 'password':
                component = PromanTextSimpleComponent;
                componentConfig.type = 'password';

                break;

            case 'color':
                component = PromanColorComponent;
                componentConfig.noPadding = this.config.noPadding;

                break;

            case 'select':
                component = PromanSelectComponent;

                break;

            case 'text':
                componentConfig.type = 'textarea';
                component = PromanTextSimpleComponent;

                break;

            case 'autocomplete':
                component = PromanAutocompleteComponent;

                break;

            case 'integer':
                componentConfig.parseInteger = parameter.isInteger;
          // eslint-disable-next-line no-fallthrough
            case 'weight':
                componentConfig.weighable = true;
          // eslint-disable-next-line no-fallthrough
            case 'number':
                componentConfig.validateNumber = true;
                componentConfig.type = 'number';
                component = PromanTextSimpleComponent;

                break;

            case 'material_category':
                componentConfig.entity = 'material_category';
                componentConfig.entityParams = { 'parent.id': parameter.value };
                if (this.parameter.filter && this.parameter.filter.length > 0) Object.assign(componentConfig.entityParams, JSON.parse(this.parameter.filter));
                component = ParameterDropdownComponent;

                break;

            case 'material':
                componentConfig.entity = 'material';
                componentConfig.entityParams = parameterFilter;
                componentConfig.isFilterClearable = true;
                component = ParameterDropdownComponent;

                break;

            case 'subcontractors':
                componentConfig.entity = 'subcontractor';
                component = ParameterDropdownComponent;

                break;

            case 'editable_expression':
                component = EditableExpressionComponent;

                break;

            case 'date':
                component = PromanDatepickerComponent;
                componentConfig.hideTime = true;
                componentConfig.emitTimeZoneValue = true;

                break;

            case 'datetime':
                component = PromanDatepickerComponent;

                break;

            case 'time':
                component = PromanTimeComponent;

                break;

            case 'time_dropdown':
                component = PromanTimeDropdownComponent;

                break;

            case 'entity':
                component = PromanAutocompleteComponent;
                componentConfig.entity = parameter.resource;

                break;

            // case 'price':
            //     // all price parameters now rendered in TxtComponent
            //     // Dynamic table price support
            //     // if (typeof this.parameter['columnName'] !== 'undefined') {
            //     //     componentConfig.simpleVal = true;
            //
            //     // }
            //
            //     break;

            case 'parameter_group':
                componentConfig.entity = this.entity;

                if (componentConfig.entity.startsWith('article_article_')) componentConfig.entity.replace('article_article_', 'article_article_');

                component = ParameterGroupComponent;

                break;

            case 'checkbox':
                component = PromanCheckboxComponent;

                break;

            case 'label':
                component = LabelComponent;

                break;

            case 'list-manager':
                component = ListManagerComponent;
                break;
        }

        Object.keys(this.mappings).forEach((key) => {
            if (type === key) {
                switch(type) {
                    case 'dropdown':
                        if (this.config?.isDefinition) {
                            component = this.mappings[key].component;

                        } else {
                            componentConfig.entity = 'parameter_dropdown_option';
                            componentConfig.entityParams = { 'parameter.id': parameter.id, 'translate': true };
                            component = ParameterDropdownComponent;

                        }
                        break;
                    case 'accounting':
                        component = this.mappings[key].component;
                        componentConfig.groupInput = true;
                        if (type === 'accounting') {
                          this.onChange.subscribe((value) => {
                            Object.assign({}, this.parameter.value, value);
                          })
                        }
                        break;
                    default:
                        Object.assign(componentConfig, this.mappings[key].config);
                        component = this.mappings[key].component;
                        if (key === 'price' && !componentConfig.parameter?.config?.priceComponent) {
                            component = PromanTextSimpleComponent;
                        }
                }
            }
        })

        componentConfig = Object.assign(componentConfig, this.config);

        if (component) {
            parameterItem = new ParameterItem(component, this.parameter.value, componentConfig, this.disabled, this.onChange);
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(parameterItem.component);
            viewContainerRef = this.parameterHost.viewContainerRef;

            viewContainerRef.clear();
            this.componentRef = viewContainerRef.createComponent(componentFactory);

            (<ParameterItemComponent>this.componentRef.instance).value = parameterItem.value;
            (<ParameterItemComponent>this.componentRef.instance).config = parameterItem.config;
            (<ParameterItemComponent>this.componentRef.instance).disabled = parameterItem.disabled;
            (<ParameterItemComponent>this.componentRef.instance).onChange = parameterItem.onChange;

            if (parameterItem.config.control) {
                (<ParameterItemComponent>this.componentRef.instance).control = parameterItem.config.control;
            } else {
                const validators = [];
                let asyncValidator;

                if (parameterItem.config.required) {
                    validators.push(Validators.required);

                }

                if (isDefinedNotNull(parameter.min) || isDefinedNotNull(parameter.max) || isDefinedNotNull(parameter.config?.min) || isDefinedNotNull(parameter.config?.max)) {
                    const min = (parameter.min || parameter.config?.min) ?? 0;
                    validators.push(RangeValidator({ min, max: parameter.max || parameter.config?.max }));
                }

                if (parameterItem.config.validateNumber) validators.push(NumberValidator);

                if (parameterItem.config.validators && parameterItem.config.validators.unique && !componentConfig.parameter?.notUnique) {
                    const uniqueConfig = parameterItem.config.validators.unique;

                    asyncValidator = this.UniqueValidatorService.get(uniqueConfig.resource, uniqueConfig.field, uniqueConfig.onlyWarning, uniqueConfig.data);

                }

                (<ParameterItemComponent>this.componentRef.instance).control = new UntypedFormControl(this.parameter.value, validators, asyncValidator);

            }

            if (this.form) {
                this.form.addControl(this.parameter.id, (<ParameterItemComponent>this.componentRef.instance).control);

            }

            if (parameterItem.config.options) {
                (<ParameterItemComponent>this.componentRef.instance).options = parameterItem.config.options;

            }

            if (type === 'list-manager') {
                (<ParameterItemComponent>this.componentRef.instance).onChange = ((<ParameterItemComponent>this.componentRef.instance) as any).onAdd;
            }

            // Trigger component change detection
            this.componentRef.changeDetectorRef.detectChanges();

        } else {
            this.Toast.pop('error', 'Not found: ' + type);

        }

    }
}
