import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { ParametersOptionsService } from '../../services/parameters-options.service';

@Component({
    selector: 'pro-table-filter-autocomplete',
    template: `
        <div fxLayout="row">
            <pro-select [value]="selected"
                    [config]="{ multiple: true, label: 'value' }"
                    [options]="options"
                    (onChange)="setFilter($event)"></pro-select>
        </div>
    `,
    styles: [`
        pro-select { min-width: 60px; }
    `]
})

export class TableFilterAutocompleteComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    options: any;
    selected: any;

    constructor(
        private ParametersOptions: ParametersOptionsService,
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.selected = this.value || [];

        this.ParametersOptions.search({ entity: this.config.entity || 'tag' })
            .then((response: any) => {
                this.options = response;
                this.cd.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        const value = changes.value;

        if (value && !value.firstChange && value.currentValue === null) {
            this.selected = [];

        }

    }

    setFilter(value: any) {
        this.selected = value;

        this.handleChange();
    }

    handleChange() {
        const emitValue = this.selected.map((item: any) => item.id);

        this.onChange.emit(emitValue.length ? emitValue : null);
    }

    handleRemove = (item: any) => {
        const index = this.options.indexOf(item);

        this.options.splice(index, 1);

        this.handleChange();
    };

}
