import {
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    OnDestroy,
    OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef
} from '@angular/core';
import { isDefinedNotNull } from '@proman/utils';
import { fromEvent, merge as observableMerge, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from '@proman/rxjs-common';

const DEFAULT_DEBOUNCE_TIME = 1500;

@Component({
    selector: 'pm-number',
    template: `
        <mat-form-field [floatLabel]="config.floatLabel || 'auto'"
                        [attr.data-name]="config.label">

            <input [type]="'number'"
                   [disabled]="disabled"
                   #box
                   matInput
                   [placeholder]="config.label | translate"
                   [value]="isDefinedNotNull(value) ? value : ''"
                   [required]="config.required"
                   autocomplete="off"
                   [pmOverlay]="{ type: 'button', data: value }"
                   [pmOverlayDebounce]="1500"
            >


        </mat-form-field>
    `,
    styles: [
        ':host { display: inline-flex; }',
        ':host mat-input-container { width: 22px; margin-left: 2px; }',
        'mat-form-field { flex: auto; }',
        'pro-btn { position: absolute; right: 0; top: 0; }',
        'pro-btn * { box-shadow: none; }',
        '.MinWidth-130 { min-width: 130px }',
        '.MinWidth-100 { min-width: 100px }',
        /* Chrome, Safari, Edge, Opera */
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }',

        /* Firefox */
        'input[type=number] { -moz-appearance: textfield; }'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NumberComponent implements OnInit, OnChanges, OnDestroy {
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Input() config: {
        label?: string;
        required?: boolean;
        disabled?: boolean;
        floatLabel?: 'always' | 'auto';
        debounce?: number;
    } = {
        label: ''
    };
    @Input() value: any;
    @Input() disabled: boolean;
    @ViewChild('box') box: ElementRef;
    inputSubscription: Subscription;
    blurSubscription: Subscription;
    enterSubscription: Subscription;
    destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnDestroy() {

    }

    ngOnInit() {
        if (this.config.disabled) {
            this.disabled = this.config.disabled;
        }
    }

    getEventObservable(eventName: string) {
        return fromEvent(this.box.nativeElement, eventName).pipe(
            map(this.mapEventData));
    }

    mapEventData = (event: any) => {
        return event.target.value;
    };

    ngAfterViewInit() {

        const getDebounceTime = () => {
            let debounce = this.config.debounce;

            return (!isNaN(debounce)) ? debounce : DEFAULT_DEBOUNCE_TIME;
        };

        this.enterSubscription = fromEvent(this.box.nativeElement, 'keydown')
            .pipe(
                filter((event: KeyboardEvent) => (event.code === 'Enter')),
                takeUntil(this.destroyed$),
            )
            .subscribe((event: any) => this.handleChanges(event.target.value));

        this.inputSubscription = observableMerge(
            this.getEventObservable('blur'),
        )
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.destroyed$),
            )
            .subscribe((value: any) => {
                if (value !== this.value) {
                    this.handleChanges(value);

                }

            });

        this.blurSubscription = fromEvent(this.box.nativeElement, 'blur')
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged())
            .subscribe((value: string) => {
                this.handleBlur(value);
                this.onBlur.emit();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.cd.markForCheck();
    }

    handleChanges = (value: any) => {
        this.onChange.emit(value);
        this.cd.markForCheck();
    }

    isDefinedNotNull = (value: any) => isDefinedNotNull(value);

    handleBlur = (value: any) => {

        this.onBlur.emit(value);
        this.cd.markForCheck();

    }

}
