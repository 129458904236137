import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { PromanButtonComponent } from '@proman/button';
import { TooltipDirectiveModule } from '@proman/tooltip/tooltip-directive.module';

@Component({
    selector: 'pro-checkbox',
    imports: [
        CommonModule,
        FormsModule,
        Fa6Module,
        PipesModule,
        MatLegacyCheckboxModule,
        PromanButtonComponent,
        TooltipDirectiveModule,
    ],
    standalone: true,
    template: `
        @if (!!(config?.iconOnly)) {
            <pro-btn [tabIndex]="-1"
                     (onClick)="handleChange(!value)"
                     [icon]="config.icon"
                     [tooltip]="config?.tooltip || null"
                     [isCheckbox]="true"
                     [disabled]="disabled"
                     [theme]="value ? 'accent' : 'grey'"></pro-btn>
        } @else {
            <mat-checkbox (change)="handleChange($event.checked)"
                          [(ngModel)]="value"
                          [proTooltip]="config?.tooltip || null"
                          [proTooltipPosition]="config?.tooltipPosition || 'top'"
                          [disabled]="disabled"
                          [indeterminate]="config?.indeterminable || null"
                          [color]="config?.indeterminable ? 'primary' : 'accent'"
                          [attr.data-check]="config?.label"
                          [attr.data-value]="value"
            >
                {{ label }}
                @if (!!config.icon) {
                    <fa [name]="config.icon" [tooltip]="config.tooltip | translate"></fa>
                }
            </mat-checkbox>
        }
    `,
    styles: [`
        fa { margin-left: 8px; }
        mat-checkbox { margin-right: 8px; }
    `]
})
export class PromanCheckboxComponent implements OnInit, OnChanges {
    @Input() config: {
        icon?: string;
        label?: string;
        tooltip?: string;
        tooltipPosition?: 'top'|'left'|'right'|'bottom';
        iconOnly?: boolean;
        preventTranslate?: boolean;
        indeterminable?: boolean;
    } = {};
    @Input() value: boolean;
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    label: string;

    constructor(
        private Filter: FilterService,
    ) {}

    ngOnInit(): void {
        this.setLabel();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config) {
            this.setLabel();
        }
    }

    handleChange(value: boolean) {
        this.value = value;
        this.onChange.emit(value);
    }

    setLabel() {
        const label: string = this.config?.label;
        this.label = label && !this.config?.preventTranslate ? this.Filter.translate(label) : (label ?? '');
    }

}
