import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pro-table-dialog',
    template: `
        <pro-dialog-title [title]="data.header"></pro-dialog-title>
        <div mat-dialog-content>
            <pro-table [config]="data.config" [dataSource]="data.payload"></pro-table>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class TableDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<TableDialogComponent>
    ) {}

}
