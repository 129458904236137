import { Directive, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[proOnInit]'
})
export class InitDirective implements OnInit, AfterViewInit {

    @Input() isLast: boolean;

    @Output('proOnInit') initEvent: EventEmitter<any> = new EventEmitter();
    @Output('proAfterViewInit') afterViewInit: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.initEvent.emit();
    }

    ngAfterViewInit() {
        this.afterViewInit.emit();
    }

}
