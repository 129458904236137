import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParameterViewComponent } from './components/parameter-view.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ParameterViewComponent,
  ],
  exports: [
    ParameterViewComponent,
  ],
})

export class ParameterViewComponentModule {

}
