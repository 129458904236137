import { Pipe, PipeTransform } from '@angular/core';
import { ImagePathService } from '../../../services/image-path.service';

@Pipe({ name: 'proThumbPath' })

export class ThumbPathPipe implements PipeTransform {

    constructor(
        private ImagePath: ImagePathService,
    ) {}

    transform(value: any, bigImage: boolean = false) {
        return value ? this.ImagePath.getFile(value, 'icon') : null;
    }

}
