import { Pipe, PipeTransform, Inject } from '@angular/core';
import { PromanStateService } from '../services/proman-state.service';
import { ACL, PermissionType } from '@proman/services/acl.service';
import { isDefined } from '@proman/utils';

@Pipe({ name: 'pmSref' })

export class SrefPipe implements PipeTransform {

    constructor(
        @Inject(PromanStateService) private PromanState: PromanStateService,
        @Inject(ACL) private ACL: ACL,
    ) {

    }

    transform(value: any, params?: any, acl?: PermissionType|PermissionType[]) {
        const routeCallback = () => this.PromanState.getUrl(value, params);
        if (isDefined(acl)) {
            if (this.ACL.check(acl)) {
                return routeCallback();
            }
        } else {
            return routeCallback();
        }
    }
}
