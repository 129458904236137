import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Entity, EntityNameType } from '../../services/entity.service';
import { ParametersActions, setStorableData } from './parameters.actions';
import { filter, tap, withLatestFrom } from '@proman/rxjs-common';
import { selectParametersState } from './parameters.selectors';
import { isDefined } from '../../utils';

declare interface ParameterActionsLoadDataActionType {
    type: string;
    payload: {
        entity: EntityNameType;
        entityParams: unknown;
    };
}

@Injectable()
export class ParametersEffects {

    getKey = (data: { entity: EntityNameType; entityParams: unknown }): string => `${data.entity}|${JSON.stringify(data.entityParams)}`;

    loadStorableData$ = createEffect(() => this.actions$
            .pipe(
                ofType(ParametersActions.LoadData),
                withLatestFrom(this.store.select(selectParametersState)),
                filter(([_action, state]) => {
                    let action: ParameterActionsLoadDataActionType = _action;
                    const key = this.getKey(action.payload);
                    const result = !isDefined(state.data.savedData[key]);
                    if (result) this.store.dispatch(setStorableData({ payload: { key: this.getKey(action.payload), data: null } }));
                    return result;
                }),
                tap(([_action, state]) => {
                    let action: ParameterActionsLoadDataActionType = _action;
                    this.Entity.get(action.payload.entity)
                       .search(action.payload.entityParams)
                       .then((response) => {
                         this.store.dispatch(setStorableData({ payload: { key: this.getKey(action.payload), data: response } }));
                       });
                })
            ),
        { dispatch: false }
    );

    constructor(
        private Entity: Entity,
        private store: Store,
        private actions$: Actions,
    ) {

    }

}
