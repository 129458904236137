import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proCurrencyShorthand' })

export class CurrencyShorthandPipe implements PipeTransform {

    constructor(private Filter: FilterService) {}

    transform(name: any) {
        return name ? this.Filter.currencyShorthand(name) : '';
    }
}
