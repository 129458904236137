import { Pipe, PipeTransform } from '@angular/core';
import { getProductionOperationName } from '../../../utils';

@Pipe({ name: 'proEventName' })

export class EventNamePipe implements PipeTransform {

    constructor() {}

    transform(operation: any): any {
        let name = '';

        if (operation) {

            switch (operation.type) {
                case 'production':
                    name = getProductionOperationName(operation);
                    break;

                case 'maintenance':
                case 'task':
                    name = operation.name;
                    break;
            }

        }

        return name;

    }
}
