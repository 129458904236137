import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParametersState } from './parameters.reducer';

export const parametersKey: string = 'parameters';

export const selectParametersState = createFeatureSelector<ParametersState>(parametersKey);

export const getStoredData = createSelector(
    selectParametersState, (state: ParametersState, props: string) => state.data.savedData[props]
);
