import { AfterViewInit, Component, Input } from '@angular/core';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

@Component({
    selector: 'pro-dialog-title',
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        Fa6Module,
        PipesModule,
        FlexLayoutModule,
    ],
    template: `
        @if (title) {
            <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ title | translate }}</span>
                <div fxLayout="row" fxLayoutAlign="end center" class="ActionsContainer">
                    @if (pulse) {
                        <fa name="heart-rate"></fa>
                    }

                    <ng-container><ng-content select=".dialog-title-actions"></ng-content></ng-container>

                    @if (!disableClose) {
                        <button class="dialog-close" [disabled]="disableClose" [mat-dialog-close]="dialogCloseValue" tabindex="-1"></button>
                    }
                </div>
            </h1>
        }
    `,
    styles: [`
        h1 {
            margin: -24px -24px 40px;
            background-color: #448aff;
            color: #ffffff;
            padding: 12px;
            overflow: auto;
        }

        .ActionsContainer {
            font-size: 14px;
            color: white;
        }

        .dialog-close {
            -moz-appearance: none;
            -webkit-appearance: none;
            background: none;
            border: none;
            border-radius: 290486px;
            cursor: pointer;
            pointer-events: auto;
            display: inline-block;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 0;
            height: 40px;
            max-height: 40px;
            max-width: 40px;
            min-height: 40px;
            min-width: 40px;
            outline: none;
            position: relative;
            vertical-align: top;
            width: 40px;
        }

        .dialog-close::before, .dialog-close::after {
            background-color: white;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            -webkit-transform-origin: center center;
            transform-origin: center center;
        }

        .dialog-close::before {
            height: 2px;
            width: 75%;
            background-color: white;
        }

        .dialog-close::after {
            height: 75%;
            background-color: white;
            width: 2px;
        }

    `]
})
export class DialogTitleComponent implements AfterViewInit {
    @Input() title: string;
    @Input() pulse: boolean = false;
    @Input() disableClose: boolean = false;
    dialogCloseValue: any;

    ngAfterViewInit() {
        setTimeout(() => {
            try {
                this.dialogCloseValue = document.querySelector('pro-dialog-actions pro-btn.DialogCloseButton').attributes.getNamedItem('data-variant').value;
            } catch (e) {
                this.dialogCloseValue = undefined;
            }
        }, 1000);
    }
}
