import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '../../services/entity.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from '@proman/common-components/components/preview.component';

@Component({
    selector: 'pro-file-preview-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        PreviewComponent,
    ],
    template: `
        <div mat-dialog-content #content>
            <pro-preview *ngIf="!isIframe" [image]="image" [settings]="options"></pro-preview>
            <iframe *ngIf="isIframe" #iframe [src]="url" width="{{ this.width }}" height="{{ this.height }}" (load)="iframeLoaded($event)"></iframe>
        </div>
    `,
    styles: ['.mat-dialog-container { box-shadow: none!important; background: transparent!important; }']
})

export class FilePreviewDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('content', { static: true }) content: ElementRef;
    @ViewChild('iframe', { static: true }) iframe: ElementRef;
    image: PromanFile;
    options: any;
    isIframe: boolean = false;
    url: SafeResourceUrl;
    width: string;
    height: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Render2: Renderer2,
        private Entity: Entity,
        private Sanitizer: DomSanitizer,
        private dialogRef: MatLegacyDialogRef<FilePreviewDialogComponent>,
    ) {
        this.image = data.file;
        this.options = data.options;

        this.isIframe = data.iframe;
        if (this.isIframe) {
            this.url = this.Sanitizer.bypassSecurityTrustResourceUrl(data.url);
            // console.log('url', this.url);

        }


    }

    ngOnInit() {
        this.Render2.addClass(this.content.nativeElement.parentElement.parentElement, 'DialogTransparent');
        this.width = (this.content.nativeElement.getBoundingClientRect().width - 56) + 'px';
        this.height = (window.innerHeight * 4 / 5).toString() + 'px';
    }

    ngAfterViewInit() {

    }

    iframeLoaded(event: unknown) {
        if (this.data.url.includes('/txt/')) {
            setTimeout(() => {
                console.log(this.iframe, event);
                if (this.iframe) this.iframe.nativeElement.shadowRoot.style.backgroundColor = 'white';
                const iframe = this.content.nativeElement.querySelector('iframe');
                console.log('i', iframe);
                // iframe.shadowRoot.style.backgroundColor = 'lightgrey';
                // iframe.shadowRoot.style.color = '#333';
            }, 250);
        }

    }

}
