import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[pmClipboard]'
})

export class ClipboardDirective {
    constructor(el: ElementRef, renderer: Renderer2) {
        renderer.addClass(el.nativeElement, 'ClipboardCopy');
    }
}
