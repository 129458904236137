import { Pipe, PipeTransform } from '@angular/core';
import { hasSubstring } from '../../../utils';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'proMarkdown' })

export class MarkdownPipe implements PipeTransform {
    value: string;

    constructor(private Sanitizer: DomSanitizer) {

    }

    transform(value: string) {

        if (!value) return '';

        this.value = value;

        this.handleHeaders();

        this.handleLinks();

        this.handleLineBreaks();

        this.handleBold();

        this.handleItalic();

        this.handleCode();

        this.handleTabs();

        return this.Sanitizer.bypassSecurityTrustHtml(this.value);

    }

    handleLineBreaks(): void {
        this.swapTags('\n', '<br/>');
    }

    handleTabs(): void {
        this.swapTags('\\t', '&emsp;');
    }

    handleHeaders = (): void => {

        if (hasSubstring(this.value, '#') && this.value.indexOf('\n')) {

            this.swapForTags(this.value, ['#', '\n'], ['<h2>', '</h2>'],  this.value.indexOf('#'));

            this.handleHeaders();

        }

    };

    handleBold = () => this.replaceTag(['**', '**'], ['<b>', '</b>'], this.handleBold);

    handleItalic = () => this.replaceTag(['*', '*'], ['<em>', '</em>'], this.handleItalic);

    handleCode = () => this.replaceTag(['```', '```'], ['<code>', '</code>'], this.handleCode);

    handleLinks = () => {
        const parts = this.value.split('http');

        if (parts.length > 1) {

            const linkStart = `<a target="_blank" href="`;

            this.value = parts[0];

            for (let i = 1; i < parts.length; i++) {
                const part = parts[i];
                let link;

                if (part.split('\n')[0].length < part.split(' ')[0].length) {
                    link = part.split('\n')[0];

                } else {
                    link = part.split(' ')[0];

                }

                const endPart = part.substr(link.length);

                link = `http${link}`;

                const anchor = `${linkStart}${link}" (click)="$event.stopPropagation()">${link}</a> ${endPart}`;

                this.value += anchor;

            }

        }

    };

    replaceTag = (str: string[], tags: string[], callback: () => void) => {
        if (hasSubstring(this.value, str[0]) && hasSubstring(this.getPartAfter(this.value, str[0]), str[1])) {
            this.value = this.value.split(str[0]).splice(0, 1).concat(this.getPartAfter(this.value, str[0])).join(tags[0]);
            this.value = this.value.split(str[1]).splice(0, 1).concat(this.getPartAfter(this.value, str[1])).join(tags[1]);
            callback();

        }
    };

    swapTags(str: string, tag: string) {
        this.value = this.value.split(str).join(tag);
    }

    spliceSplit(str: string, index: number, count: number, add: string) {
        let ar = str.split('');
        ar.splice(index, count, add);
        return ar.join('');
    }

    swapForTags(str: string, swap: string[], tags: string[], start: number): void {
        let ar: string[] = str.split('');
        let strAr: string[] = ar.slice(start);

        str = str.slice(start); // string array to work with
        ar = ar.slice(0, start); // start of string untouched

        strAr.splice(str.indexOf(swap[0]), 1, tags[0]);

        strAr.splice(str.indexOf(swap[1]), 1, tags[1]);

        this.value = [].concat(ar, strAr).join('');

    }

    getPartAfter(str: string, sep: string) {
        return str.split(sep).splice(1).join(sep);
    }

}
