import {
    Component,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';
import { ImagePathService } from '../../services/image-path.service';
import { FilePreviewService } from '../../services/file-preview.service';
import { PromanFile } from '../../interfaces/entity-interfaces';
import { PromanFileIconType } from '../../resources/file';
import 'dragster';
import { CommonModule } from "@angular/common";
import { ImageErrorDirective } from "@proman/shared/directives/image-error.directive";
import { Fa6Module } from "@proman/fa/fa6.module";
import { GlobalOverlayModule } from "@proman/overlay/global-overlay.module";
import { TranslatePipe } from "@proman/shared/pipes/pipes/translate.pipe";


const DEFAULT_THUMBNAIL_SIZE = 50;
const DEFAULT_IMAGE = 'assets/images/placeholder.png';

@Component({
    selector: 'pro-thumbnail',
    standalone: true,
    imports: [
        CommonModule,
        ImageErrorDirective,
        Fa6Module,
        GlobalOverlayModule,
        TranslatePipe,
    ],
    providers: [
        FilePreviewService,

    ],
    template: `
        <div class="Thumbnail" #element [ngStyle]="{ 'width':  size + 'px' , 'height': size + 'px' }" [ngClass]="{ 'PointerEventsNone': hideOverlay }">
            @if (uploadable) {
                <div class="FilesUploadOverlay">
                    @if (overlayMoved) {
                        <span>
                    {{ 'drop_files_here_to_upload' | translate }}
                </span>
                    }
                </div>
            }
            @if (image) {
                <img [ngStyle]="{ 'width':  size + 'px' , 'height': size + 'px', borderRadius: borderRadius + 'px' }"
                     class="image-{{ file?.id }}"
                     [src]="image"
                     [proOverlay]="{ type: 'image', data: getOverlayLink() }"
                     (click)="preventView ? null : preview($event)"
                     [ngClass]="{ 'Thumbnail-avatar': isAvatar, MarginAuto: !isDefaultSize, Clickable: !preventView }"
                     [proImageError]="'assets/images/placeholder.png'"
                />
            } @else {
                <fa class="Thumbnail-preview"
                    [ngClass]="{ 'Clickable': !preventView }"
                    (click)="preventView ? null : preview($event)"
                    [name]="faIcon"
                    size="4x"
                ></fa>
            }
        </div>
    `,
    styles: [`
        .Thumbnail {
            overflow: hidden;
        }
        .Thumbnail-avatar {
            margin: 8px 16px 8px 0;
            box-sizing: content-box;
        }
        .Thumbnail-preview {
            width: 50px;
            height: 50px;
            position: relative;
            top: 2px;
            left: 5px;
        }
        img {
            display: block;
        }

        .FilesUploadOverlay {
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(33, 150, 243, 0.2);
            border: 2px solid #448aff;
            z-index: 9;
        }

        .FilesUploadOverlay span {
            font-size: 7em;
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-align: center;
            text-anchor: middle;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush

})

export class PromanThumbnailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() isAvatar: boolean = false;
    @Input() size: number = DEFAULT_THUMBNAIL_SIZE;
    @Input() file: PromanFile;
    @Input() borderRadius: number;
    @Input() config: { size?: '1x'|'2x' };
    @Input() preventView: boolean = false;
    @Input() hideOverlay: boolean;
    @Input() type: PromanFileIconType = 'icon';
    @Input() uploadable: boolean;
    @Output() onFileUpload: EventEmitter<any> = new EventEmitter();
    @ViewChild('element', { static: true }) element: ElementRef;
    image: string = DEFAULT_IMAGE;
    action: string = 'blank';
    icon: string = 'assets/images/file.png';
    nameAttr: string;
    faIcon: string = 'file';
    _isDocument: boolean;
    _isSpreadsheet: boolean;
    _isCompressed: boolean;
    isDefaultSize: boolean = true;

    overlayMoved: boolean;
    overlayElement: HTMLElement;

    constructor(
        private cd: ChangeDetectorRef,
        private ImagePath: ImagePathService,
        private FilePreview: FilePreviewService,
    ) {

    }

    ngOnChanges() {
        this.ngOnInit();
    }

    isImage = (ext: string) => ['gif', 'v', 'jpeg', 'jpg', 'xlsx', 'docx', 'pdf'].includes(ext);

    isDocument(ext: string) {
        this._isDocument = ['pdf'].indexOf(ext) > -1;

        return this._isDocument;
    }

    isSpreadsheet = (ext: string) => {
        this._isSpreadsheet = ['xls', 'xlsx'].indexOf(ext) > -1;

        return this._isSpreadsheet;
    };

    isVideo = (ext: string) => {
        return ['mov', 'mp4'].includes(ext);
    };

    isCompressed = (ext: string) => {
        return ['zip', 'rar', '7z'].includes(ext);
    };

    ngOnInit() {
        if (this.config?.size) {
            if (this.config.size === '1x') {
                this.size = (DEFAULT_THUMBNAIL_SIZE / 2);
            }
        }

        this.isDefaultSize = this.size === DEFAULT_THUMBNAIL_SIZE;

        const file = this.file;

        if (file) {
            this.nameAttr = file.filename ? 'filename' : 'name';

            const ext = file.extension;
            const newExt = file.newExtension;

            if (this.isVideo(ext) || this.isVideo(newExt)) {
                this.image = null;
                this.faIcon = 'file-video';
            } else if (this.isCompressed(ext) || this.isCompressed(newExt)) {
                    this.image = null;
                    this.faIcon = 'file-archive';
            } else if (file) {
                this.image = this.ImagePath.getFile(file, this.type);
            }
        }

        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        if (this.uploadable) {
            let element = this.element.nativeElement;

            if (!document.querySelectorAll('.Upload') && document.querySelectorAll('.FilesManager').length === 1) {
                element = document.querySelector('body');
                document.body.appendChild(element.querySelector('.FilesUploadOverlay'));
                this.overlayMoved = true;

            }
            new window.Dragster(element);

            element.addEventListener('drop', this.handleDrop);
            element.addEventListener('dragenter', this.handleDragEnter);
            element.addEventListener('dragover', this.handleDragOver);
            element.addEventListener('dragster:enter', this.showUploadOverlay);
            element.addEventListener('dragster:leave', this.hideUploadOverlay);

            this.overlayElement = element.querySelector('.FilesUploadOverlay');
        }
    }

    ngOnDestroy() {
        if (this.uploadable) {
            const element = this.element.nativeElement;

            element.removeEventListener('drop', this.handleDrop);
            element.removeEventListener('dragenter', this.handleDragEnter);
            element.removeEventListener('dragover', this.handleDragOver);
            element.removeEventListener('dragster:enter', this.showUploadOverlay);
            element.removeEventListener('dragster:leave', this.hideUploadOverlay);


            if (this.overlayMoved) {
                const overlayElement = document.querySelector('.FilesUploadOverlay');

                overlayElement?.parentNode?.removeChild(overlayElement);

            }
        }
    }

    handleDragEnter = (event: any) => { event.preventDefault(); this.showUploadOverlay() };

    handleDragOver = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        return false;
    };

    handleDrop = (event: DragEvent) => {
        this.hideUploadOverlay();
        event.stopPropagation();
        event.preventDefault();

        const files = event.dataTransfer.files;
        this.onFileUpload.emit(files);
    };

    showUploadOverlay = () => { this.overlayElement.style.setProperty('display', 'block'); };
    hideUploadOverlay = () => this.overlayElement.style.setProperty('display', 'none');

    preview = (event: MouseEvent) => {
        event.stopPropagation();
        this.FilePreview.show(this.file);
    };

    getOverlayLink() {
        if (!this.file) return null;

        if (this.file.newId) {
            return this.ImagePath.getFile(this.file, 'png');
        }

        if (this.file[this.nameAttr]) {
            return this.ImagePath.getFile(this.file[this.nameAttr], 'icon');
        } else {
            return 'assets/images/pdf.png';
        }
    }

}
