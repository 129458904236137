import {
    Injectable,
    Injector,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    ApplicationRef
} from '@angular/core';

import { FilterService } from '@proman/services/filter.service';
import { OutsideClickDetectorService } from '@proman/services/outside-click-detector.service';
import { InlineListComponent } from './inline-list.component';

interface InlineListOptionsInterface {
    dataPromise?: Promise<object[]>;
    isLoading?: boolean;
    items?: any[];
    groupedData?: any;
    data?: object[];
    event: Event|MouseEvent|any;
    showCreateNew?: boolean;
    onCreateNew?: (item?: object) => any;
    onSelect?: (item?: object) => any;
    createNewLabel?: string;
    noResultsLabel?: string;
    isSearch?: boolean;
    removeOnSelect?: boolean;
    closeOnSelect?: boolean;
    closeOnEmpty?: boolean;
}

@Injectable()
export class InlineListService {
    componentRef: any;
    domElem: any;
    listener: any;
    settings: InlineListOptionsInterface;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private OutsideClickDetectorService: OutsideClickDetectorService,
        private Filter: FilterService,
    ) {
        this.settings = {
            showCreateNew: false,
            createNewLabel: this.Filter.translate('create_new'),
            noResultsLabel: this.Filter.translate('no_results'),
            onCreateNew: () => {},
            onSelect: () => {},
            event: <any>null,
            data: <any>null,
            isSearch: true,
            removeOnSelect: false,
            closeOnSelect: true,
            closeOnEmpty: true
        };
    }

    parseGroupedData(data: any) {
        return data.reduce((data: any, group: any) => {
            let tmpGroup = { name: group.name, items: <any>[] };

            for (let item of group.data) {
                tmpGroup.items.push(item);

            }

            data.push(tmpGroup);

            return data;
        }, []);
    }

    parseData(data: any) {
        return [{ items: data }];
    }

    setup() {
        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(InlineListComponent)
            .create(this.injector);

        this.appRef.attachView(this.componentRef.hostView);

        this.domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        document.body.appendChild(this.domElem);

        this.addListeners();
    }

    show(options: InlineListOptionsInterface) {
        this.setup();

        if (options.dataPromise) {
            options.isLoading = true;

            options.dataPromise
                .then((response: any[]) => {
                    this.componentRef.instance.data.items = this.parseData(response);

                    this.componentRef.instance.data.isLoading = false;
                });

        } else {
            options.items = options.groupedData ? this.parseGroupedData(options.groupedData) : this.parseData(options.data);

        }

        this.componentRef.instance.data = Object.assign({}, this.settings, options);
        this.componentRef.instance.data.close = this.close;
    }

    close = () => {
        this.listener.cancel();
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.domElem.removeEventListener('keydown', this.handleElKeydown);
    };

    handleElKeydown = (event: any) => {
        event.stopPropagation();

        if (event.keyCode === 27) this.close();

    };

    addListeners() {
        this.listener = this.OutsideClickDetectorService.addListener(this.domElem, this.close);
        this.domElem.addEventListener('keydown', this.handleElKeydown);
    }
}
