import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { UI_TABLE_SAVED_FILTERS, UiPreferencesService } from '../../services/ui-preferences.service';
import { Entity } from '../../services/entity.service';
import { debounce, deepCopy, omit } from '../../utils';
import { Store } from '@ngrx/store';
import { CurrUser } from '../../interfaces/object-interfaces';
import { getCurrUser } from '../../store/curr-user';
import { TABLE_MODULE_CONFIGURATION } from '../constants';
import { TableModuleConfiguration } from '../types';

@Injectable({ providedIn: 'root' })
export class TableCacheService {
    updateDebounce: any;
    currUser: CurrUser;
    key: string;

    constructor(
        @Inject(TABLE_MODULE_CONFIGURATION) private configuration: TableModuleConfiguration,
        private UiPrefs: UiPreferencesService,
        private Location: Location,
        private store: Store,
        private Entity: Entity,
    ) {
        this.updateDebounce = debounce(() => this.saveSettingsRequest());
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
        this.key = this.configuration.storagePrefix;
    }

    get(tableId: any = this.getLocationUrl()): any|null {
        let data = this.UiPrefs.get(this.key);

        return data && data[tableId] || null;
    };

    set(value: any, tableId: any = this.getLocationUrl()) {
        let data = deepCopy(this.UiPrefs.get(this.key) || {});

        const newVal = Object.assign({}, data[tableId], value);
        const isSame = JSON.stringify(omit(data[tableId], ['_lastSave'])) === JSON.stringify(omit(newVal, ['_lastSave']));

        data[tableId] = newVal;

        if (data[tableId].isFilteringDisabled) data[tableId].filters = {};
        this.UiPrefs.set(this.key, data);

        if (!isSame) {
            this.updateDebounce();
        }
    }

    getLocationUrl() {
        return this.Location.path();
    }

    reset(tableId: string = this.getLocationUrl()): void {
        let data = deepCopy(this.UiPrefs.get(this.key) || {});

        delete data[tableId];

        this.UiPrefs.set(this.key, data);
    }

    saveSettings() {
        this.updateDebounce.cancel();

        this.updateDebounce();

    }

    saveSettingsRequest() {
        const currPerson = this.currUser;

        if (currPerson) {
            let request: { id: number; tableSettings: any } = {
                id: currPerson.person.id,
                tableSettings: {
                    filters: JSON.stringify(this.UiPrefs.get(UI_TABLE_SAVED_FILTERS) || {}),
                    settings: JSON.stringify(this.UiPrefs.get(this.key) || {}),
                }
                // @persontablesettingsrework
                // settings: this.UiPrefs.get(KEY) || {}
            };

            return this.Entity.get('person')
                .update(request);

        }

    }

}
