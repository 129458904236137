import { NgModule } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [],
  declarations: [TooltipDirective],
  exports: [TooltipDirective],
})

export class TooltipDirectiveModule {}
