import { AbstractControl, ValidatorFn } from '@angular/forms';

export function RangeValidator(range: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        let formattedValue = +control.value;

        if (!isNaN(range.min) && !isNaN(range.max)) {

            if (formattedValue >= range.min && formattedValue <= range.max) {
                return null;

            } else {
                return { isValidRange: `${range.min} - ${range.max}` };

            }

        } else if (!isNaN(range.min)) {

            if (formattedValue >= range.min) {
                return null;

            } else {
                return { isValidRange: `'min: '${range.min}` };

            }

        } else if (!isNaN(range.max)) {

            if (formattedValue <= range.max) {
                return null;

            } else {
                return { isValidRange: `'max: '${range.max}` };

            }

        }

        return null;
    }
}
