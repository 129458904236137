import { Pipe, PipeTransform } from '@angular/core';

const URL_REGEX =
  new RegExp(
    '((https?://)(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?' + //user@
    '(([0-9]{1,3}\.){3}[0-9]{1,3}' + // IP- 199.194.52.184
    '|' + // allows either IP or domain
    '([0-9a-z_!~*\'()-]+\.)*' + // tertiary domain(s)- www.
    '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.' + // second level domain
    '[a-z]{2,6})' + // first level domain- .com or .museum
    '(:[0-9]{1,4})?' + // port number- :80
    '((/?)|(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?))',
    "gi");

const URL_REGEX2 = new RegExp(/((https?:\/\/)(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-z_!~*'()-]+.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].[a-z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z]+)+\/?)(\d*)?)/);
const URL_REGEX3 = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+~#?&\/=]*)/gim)
// same as regex3 but allows for point int url but not in the end
const URL_REGEX4 = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+~#?&\/=]*)([.-a-zA-Z0-9()@:%_\+~#?&\/=]*)[^(.|\s)]/gim);
@Pipe({ name: 'proUrlClickable' })

export class ClickableUrlPipe implements PipeTransform {
    regexPattern: RegExp = URL_REGEX4;

    transform(value: string): string {
        if (value.match(this.regexPattern)) {
          return value.replace(this.regexPattern, "<a href='$&' target='_blank'>$&</a>");
        }

        return value;
    }
}
