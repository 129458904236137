import { NgModule } from '@angular/core';
import { ImageErrorDirective } from './image-error.directive';
import { PromanContrastingColorDirective } from './proman-contrasting-color.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { InitDirective } from './init.directive';
import { ScrollLimitDirective } from './scroll-limit.directive';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { ShiftScrollHorizontalDirective } from './shift-scroll-horizontal.directive';
import { OnResizeDirective } from './on-resize.directive';
import { GlobalOverlayModule } from '../../overlay/global-overlay.module';
import { TooltipDirectiveModule } from '../../tooltip/tooltip-directive.module';
import { For2Directive } from './for-2.directive';
import { ElementOnLoadDirective } from './element-on-load.directive';
import { DelayedIfDirective } from '@proman/shared/directives/delay-render.directive';

const DIRECTIVES = [
    ClickStopPropagationDirective,
    InitDirective,
    ScrollLimitDirective,
    ScrollIntoViewDirective,
    ShiftScrollHorizontalDirective,
    OnResizeDirective,
    // ForTimeoutDirective,
    ElementOnLoadDirective,
    DelayedIfDirective,
];

@NgModule({
    imports: [
      GlobalOverlayModule,
      TooltipDirectiveModule,
        PromanContrastingColorDirective,
        ImageErrorDirective,
        For2Directive,

    ],
    declarations: DIRECTIVES,
    providers: DIRECTIVES,
    exports: [
      DIRECTIVES,
      TooltipDirectiveModule,
        ImageErrorDirective,
        For2Directive,

    ],
})

export class SharedDirectivesModule {

}
