import { ViewportRuler } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { CustomAttacherPositionStrategy } from './attacher-position-strategy';
import { CustomAttacherOrigin } from './models/origin';

/**
 * Simple version of Material OverlayPositionBuilder to connect attacher to the cusomized position strategy.
 */
@Injectable()
export class CustomAttacherPositionStrategyBuilder {
    constructor(private _viewportRuler: ViewportRuler) {}

    /**
     * Creates a flexible position strategy.
     * @param origin Origin relative to which to position the overlay.
     */
    flexibleConnectedTo(
        origin: CustomAttacherOrigin
    ): CustomAttacherPositionStrategy {
        return new CustomAttacherPositionStrategy(origin, this._viewportRuler);
    }
}
