import { Directionality } from '@angular/cdk/bidi';
import {
    OverlayConfig,
    OverlayKeyboardDispatcher,
    OverlayOutsideClickDispatcher,
    OverlayRef,
    ScrollStrategyOptions,
    ViewportRuler
} from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT, Location } from '@angular/common';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ElementRef,
    Inject,
    Injectable,
    Injector,
    NgZone
} from '@angular/core';
import { CustomAttacherPositionStrategyBuilder } from './attacher-position-strategy-builder';

const CONTAINER_CLASS = 'custom-attacher-container';
/** Next overlay unique ID. */
let nextUniqueId = 0;

/** Style the container to be on the top. */
export const INITIAL_CONTAINER_STYLE = {
    posiiton: 'fixed',
    zIndex: '999',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
};

/**
 * An overlay (follow similar pattern of material overlay) which can be attached to any element other than document.
 */
@Injectable()
export class CustomAttacher {
    private _containerElement?: HTMLElement;
    private _originElement?: ElementRef;
    private _appRef: ApplicationRef;

    constructor(
        /** Scrolling strategies that can be used when creating an overlay. */
        public scrollStrategies: ScrollStrategyOptions,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _keyboardDispatcher: OverlayKeyboardDispatcher,
        private _injector: Injector,
        private _ngZone: NgZone,
        @Inject(DOCUMENT) private _document: any,
        private _directionality: Directionality,
        private _location: Location,
        private _outsideClickDispatcher: OverlayOutsideClickDispatcher,
        private _viewportRuler: ViewportRuler,
        private _platform: Platform
    ) {}

    /**
     * Create an attacher.
     * returns as OverlayRef.
     * @param originElement: Element to attache the attacher to.
     * @param config: Overlay configuration for the attacher.
     */
    create(originElement: ElementRef, config?: OverlayConfig) {
        this._originElement = originElement;
        const host = this._createHostElement();
        const pane = this._createPaneElement(host);
        const portalOutlet = this._createPortalOutlet(pane);
        const overlayConfig = new OverlayConfig(config);

        overlayConfig.direction =
            overlayConfig.direction || this._directionality.value;

        return new OverlayRef(
            portalOutlet,
            host,
            pane,
            overlayConfig,
            this._ngZone,
            this._keyboardDispatcher,
            this._document,
            this._location,
            this._outsideClickDispatcher
        );
    }

    /**
     * Gets a position builder that can be used, via fluent API,
     * to construct and configure a position strategy.
     * @returns An overlay position builder for the attacher.
     */
    position(): CustomAttacherPositionStrategyBuilder {
        return new CustomAttacherPositionStrategyBuilder(this._viewportRuler);
    }

    /**
     * Create a 'div' with 'CONTAINER_CLASS' and append it after the host element as a follow sibling.
     */
    private _getContainerElement() {
        if (!this._containerElement) {
            this._createContainer();
        }
        return this._containerElement;
    }

    /**
     * Creates the host element that wraps around an attacher
     * and can be used for advanced positioning.
     * @returns Newly-create host element.
     */
    private _createHostElement(): HTMLElement {
        const host = this._document.createElement('div');
        this._getContainerElement().appendChild(host);
        return host;
    }

    /**
     * Creates the DOM element for an overlay and appends it to the attacher container.
     * @returns Newly-created pane element
     */
    private _createPaneElement(host: HTMLElement): HTMLElement {
        const pane = this._document.createElement('div');

        pane.id = `custom-attacher-panel-${nextUniqueId++}`;
        pane.classList.add('custom-attacher-panel');
        host.appendChild(pane);

        return pane;
    }

    /**
     * Create a DomPortalOutlet into which the overlay content can be loaded.
     * @param pane The DOM element to turn into a portal outlet.
     * @returns A portal outlet for the given DOM element.
     */
    private _createPortalOutlet(pane: HTMLElement): DomPortalOutlet {
        // We have to resolve the ApplicationRef later in order to allow people
        // to use overlay-based providers during app initialization.
        if (!this._appRef) {
            this._appRef = this._injector.get<ApplicationRef>(ApplicationRef);
        }

        return new DomPortalOutlet(
            pane,
            this._componentFactoryResolver,
            this._appRef,
            this._injector,
            this._document
        );
    }

    private _createContainer() {
        const container = this._document.createElement('div');
        container.classList.add(CONTAINER_CLASS);

        // Style container
        container.style.position = INITIAL_CONTAINER_STYLE.posiiton;
        container.style.zIndex = INITIAL_CONTAINER_STYLE.zIndex;
        container.style.left = INITIAL_CONTAINER_STYLE.left;
        container.style.top = INITIAL_CONTAINER_STYLE.top;
        container.style.width = INITIAL_CONTAINER_STYLE.width;
        container.style.height = INITIAL_CONTAINER_STYLE.height;

        const originElement = this._originElement.nativeElement;
        if (originElement.nextElementSibling) {
            originElement.parentNode.insertBefore(container, originElement.nextElementSibling);
        } else {
            originElement.parentNode.appendChild(container);
        }

        this._containerElement = container;
    }
}
