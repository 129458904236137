import { Injectable } from '@angular/core';
import {
    deepCopy,
    findById,
    findByProperty,
    formatNumber,
    getIndexById,
    hasSubstring,
    isDefined,
    omit,
} from '../../utils';
import { QueryExpressionService } from '../../services/query-expression.service';
import { TableCacheService } from './table-cache.service';
import { statsMethods } from '../utils/table-config';
import { Subject } from '@proman/rxjs-common';
import { CurrUser, TableConfig, TableField, TableFilter } from '../../interfaces/object-interfaces';
import { ACL } from '../../services/acl.service';
import moment from 'moment';
import { FilterService } from '../../services/filter.service';
import { CurrenciesService } from '../../services/currencies.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '../../store/curr-user';
import { getDecimalPlaces } from '../../store/system-options';
import { filter, take } from '@proman/rxjs-common';

export const ROW_LIMIT_OPTIONS = [50, 100, 200, 300, 500, 1000, 2000, 5000, 10000];
export const DEFAULT_TABLE_PAYLOAD: any = { data: [], total: 0 };

@Injectable({ providedIn: 'root' })
export class TableHelperService {
    timeStamp: Subject<number> = new Subject<number>();
    filterParams: any;
    columnsParams: any;
    currUser: CurrUser;
    decimalPlacesDefault: number = 0;

    constructor(
        private ACL: ACL,
        private store: Store,
        private QueryExpression: QueryExpressionService,
        private TableCache: TableCacheService,
        private Filter: FilterService,
        private Currencies: CurrenciesService,
    ) {
        this.store.select(getDecimalPlaces)
            .pipe(
                filter((decimal) => isDefined(decimal)),
                take(1)
            )
            .subscribe((decimal) => {
                this.decimalPlacesDefault = decimal;
            });

        this.store.select(getCurrUser)
            .subscribe((currUser) => {
                this.currUser = currUser;
            });

    }

    static getField(key: string, fields: TableField[]) {
        for (let iter in fields) {

            if (fields[iter].key === key) {
                return fields[iter];

            }

        }

    }

    formatResponse = function(response: any) {
        let formattedResponse: any = {};

        if (response && response.status && response.data && response.config) {
            response = response.data;

        }

        if (response && !response.data && !response.total) {
            formattedResponse = {
                data: response,
                total: response.length
            };

            response = formattedResponse;
        }

        return response;

    };

    setDefaultStats(field: any, cached?: any) {
        field.config.statsConfig = { average: false, sum: false };

        for (let stat of field.stats) {
            let statsMethod = findByProperty(statsMethods, 'name', stat);

            if (statsMethod) {
                field.config.statsConfig[stat] = true;

            }

        }

        if (cached) {
            field.config.statsConfig = Object.assign(field.config.statsConfig, cached);

        }

    }

    initFilterValues(cachedFilterValues: any, fields: any, lastSave: any, params: any) {
        let cachedValue;
        let noDefaultValue;
        let hasDefaultValue;
        let userValueCaching;
        let lastSaveNotExpired;
        let key;
        let field: any;

        const filterOptions = (options: any) => {
            let cachedFieldVal = cachedFilterValues[field.key];      // fix for conversion from selected options array to string

            if (typeof cachedFieldVal === 'string') {
                let values: any = cachedFieldVal.split('|');

                values = values.map((value: any) => {
                    if (value[0] === '=') value = value.substr(1);

                    return value;
                });

                cachedFieldVal = values.map((value: string) => ({ id: value }));

            }

            return options.filter((option: any) => {
                return cachedFilterValues[field.key] && cachedFilterValues[field.key].indexOf && getIndexById(cachedFieldVal, option.id) > -1;
            });
        };

        for (field of fields) { // do not put 'let field of fields' because 'field' used in filterOptions above

            if (!field.filter) continue; // filter not enabled

            key = field.filter.type === 'autocomplete' ? field.key + '.id' : field.key;

            // cached value exists
            cachedValue = cachedFilterValues && isDefined(cachedFilterValues[key]);

            // field has no default value
            noDefaultValue = !isDefined(field.filter.value);

            // and user value caching is enabled
            userValueCaching = field.filter.userValueExpiry;

            // and last save has not expired
            lastSaveNotExpired = moment().subtract(field.filter.userValueExpiry, 'ms') < moment(lastSave);

            hasDefaultValue = isDefined(field.filter.value) && userValueCaching && (lastSave && lastSaveNotExpired || !lastSave || !lastSaveNotExpired);

            if (cachedValue && noDefaultValue || hasDefaultValue) {

                // then use cached value

                if (cachedFilterValues && Object.keys(cachedFilterValues).length) {

                    if (field.filter.type === 'dropdown_multi') {
                        field.filter.value = this.QueryExpression.orStrict(filterOptions(field.filter.options).map((item: any) => item.id));

                    } else if (field.filter.type === 'dropdown') {
                        let result = findById<any>(field.filter.options, { id: cachedFilterValues[key] });

                        field.filter.value = result && result.id || null;

                    } else if (field.formatter === 'money') {
                        field.filter.value = cachedFilterValues[key];
                        key = `${key}.amount`;

                    }  else if (field.filter.type === 'search') {
                        if (!params.search) params.search = {};

                        field.filter.value = cachedFilterValues[key];

                        for (let searchKey of field.filter.keys) {
                            params.search[searchKey] = cachedFilterValues[key];
                        }

                        continue;

                    } else {
                        field.filter.value = (userValueCaching && !lastSaveNotExpired) ? field.filter.value : cachedFilterValues[key];

                    }

                }

                params[key] = field.filter.value;

            }

        }

    };

    getNumberOutput(value: any, decimalPlaces: number) {
        let output = value.toFixed(decimalPlaces);

        if (decimalPlaces) output = output.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');

        return formatNumber(output, decimalPlaces);
    };

    formatOutput(value: any) {

        if (Number.isNaN(value)) {
            value = this.getDefaultOut();

        }

        return value;
    };

    getDefaultOut = () => '-';

    getDefaultDecimalPlaces = () => this.decimalPlacesDefault;

    getPriceOutput = (value: any, currency: any, decimalPlaces: any) => {
        let amountFloat = parseFloat(value);
        let amount = null;
        let currencyOut = '';

        if (!isNaN(amountFloat)) {
            amount = this.getNumberOutput(amountFloat, decimalPlaces);

        }

        if (currency) {
            currencyOut = this.Currencies.dataMappedByName[currency].shorthand;

        }

        return [amount, currencyOut].join(' ');
    };

    getDefaultQueryParams(extraParameters: any) {
        let filters: any = null;
        return {
            limit: ROW_LIMIT_OPTIONS[0],
            currentPage: 0,
            sort: (extraParameters && extraParameters.sort) || null,
            filters
        };
    };

    serializeFilterValues(fields: TableField[]) {
        return fields.reduce((accumulator: any, field: any) => {
            let key = field.key;
            let filter = field.filter;
            let value;
            let type;

            if (!filter || !filter.value) {
                return accumulator;

            }

            value = filter.value;
            type = filter.type;

            if (type === 'autocomplete') {
                key += '.id';

                value.forEach((item: any) => {

                    if (!accumulator[key]) {
                        accumulator[key] = [];

                    }

                    accumulator[key].push(item);
                });

            } else if (type === 'dropdown') {
                if (value) accumulator[key] = value;

            } else {
                accumulator[key] = value;

            }

            return accumulator;
        }, {});
    };

    initQueryParameters = (params: any, fields: TableField[], customFilters: TableFilter[], _params: any = {}, config?: TableConfig) => {
        let cachedFilter;

        let cachedFilters = params.customFilters;

        if (customFilters) {

            for (let filter of customFilters) {

                if (typeof cachedFilters === 'object') {
                    cachedFilter = cachedFilters[filter.key];

                }

                if (cachedFilter) {

                    if (Array.isArray(cachedFilter) && cachedFilter[0] === filter.value[0]) {
                        filter.isSelected = true;

                    } else if (cachedFilter === filter.value) {
                        filter.isSelected = true;

                    }

                } else if (!cachedFilters && filter.isDefault) {
                    filter.isSelected = true;

                }

                if (filter.isSelected) _params[filter.key] = filter.value;

            }

        }

        this.initFilterValues(params.filters, fields, params._lastSave, _params);

    };

    getFilterValues(fields: any, definedCustomFilters: any) {
        let fieldsLength = fields.length;
        let values: any = {};
        let customFilters = this.getCustomFilters(definedCustomFilters);
        let field: any;

        for (let iterA = 0; iterA < fieldsLength; iterA++) {
            field = fields[iterA];

            if (!field.filter || !field.filter.value && typeof field.filter.value !== 'boolean') {
                continue;

            } else if (field.filter.type === 'autocomplete') {
                let key = field.filter.key || field.key;
                key += '.id';

                values[key] = [];

                for (let iterB = 0; iterB < field.filter.value.length; iterB++) {

                    if (field.filter.value[iterB] && field.filter.value[iterB].id) {
                        values[key].push(field.filter.value[iterB].id);

                    }

                }

                continue;

            } else if (field.filter.type === 'dropdown') {
                let key = field.filter.key || field.key;

                if (!isNaN(field.filter.value.id) || typeof field.filter.value.id === 'boolean') {
                    values[key] = field.filter.value.id;

                } else {
                    values[key] = this.QueryExpression.eqStrict(field.filter.value.id);

                }

                continue;

            }

            if (field.filter && field.filter.props) {
                values.search = {};
                field.filter.props.forEach((prop: any) => {
                    values.search[prop] = field.filter.value;
                });

            } else {
                values[field.key] = field.filter.value;

            }

        }

        if (Object.keys(customFilters).length) {
            values = Object.assign({}, values, customFilters);

        }

        return this.decorateSearchKeys(values, fields);
    };

    getCustomFilters(customFilters: any) {
        let filters = {};

        if (customFilters && customFilters.length) {

            for (let filter of customFilters) {

                if (filter.isSelected) {

                    if (typeof filter.value === 'object') {

                        for (let key in filter.value) {
                            filters[key] = filter.value[key];

                        }

                    } else {
                        filters[filter.key] = filter.value;

                    }

                }

            }

        }

        return filters;
    };

    decorateSearchKeys(search: any, fields: any) {
        let value;
        let field;
        let decorated = {};

        for (let key in search) {
            value = search[key];
            field = TableHelperService.getField(key, fields);

            if (field && field.formatter === 'money') {
                decorated[key + '.amount'] = value;

            } else {
                decorated[key] = value;

            }

        }

        return decorated;
    };

    decorateSortKeys(sort: any, fields: any) {
        let key;
        let value;
        let field;
        let decorated = {};

        for (key in sort) {
            value = sort[key];
            field = TableHelperService.getField(key, fields);

            if (field && field.formatter === 'money') {
                decorated[key + '.amount'] = value;

            } else {
                decorated[key] = value;

            }

        }

        return decorated;
    };

    inferFilterType(fields: any) {

        for (let field of fields) {

            if (typeof field.filter === 'undefined') {

                if (field.formatter === 'dateTime') {
                    field.filter = { type: 'date' };

                } else if (field.formatter === 'money') {
                    field.filter = { type: 'numeric' };

                } else if (field.formatter === 'image') {
                    field.filter = null;

                } else {
                    field.filter = { type: 'string' };

                }

            }

        }

    };

    initFooterCalc(fields: any, sums: any, avgs: any) {
        let value = false;

        for (let field of fields) {

            if (field.config && field.config.statsConfig) {
                sums[field.key] = field.formatter === 'money' ? {} : 0;
                avgs[field.key] = 0;

                value = true;

            }

        }

        return value;
    };

    getQueryParams(params: any) {
        let limit = parseInt(params.limit);
        let currentPage = parseInt(params.currentPage);

        if (isNaN(limit)) limit = 50;
        if (isNaN(currentPage)) currentPage = 0;

        return {
            limit,
            currentPage,
            offset: limit * currentPage,
            search: params.search,
            sort: params.sort || {}
        };
    };

    getSettings(extraParameters: any, url?: any) {
        let cachedParams = this.TableCache.get(url);

        return deepCopy(cachedParams || this.getDefaultQueryParams(extraParameters));
    };

    getRowLimits = () => ROW_LIMIT_OPTIONS;

    initFields(configFields: any, tableId: any, returnArray: boolean = false) {
        let cached = this.TableCache.get(tableId);
        let fields = [];

        if (cached && cached.fields) {

            for (let tmpField of cached.fields) {
                let key = (typeof tmpField === 'object') ? tmpField.key : tmpField;
                let field = findByProperty<TableField>(configFields, 'key', key);

                if (!field) continue;

                field.config = {};

                if (field.formatter === 'dateTime')  field.showTime = tmpField.showTime;

                if (tmpField && tmpField.config && tmpField.config.showFullResult) {
                    field.config.showFullResult = true;
                }

                if (Array.isArray(field.stats)) {
                    this.setDefaultStats(field, tmpField.statsConfig);

                }

                fields.push(field);
            }

        } else {
            fields = configFields;

            for (let field of fields) {
                field.config = {};

                if (Array.isArray(field.stats)) {
                    this.setDefaultStats(field);

                }

            }

        }

        fields = fields.filter((item: TableField) => isDefined(item.acl) ? this.ACL.check(item.acl) : true);

        return returnArray ? [fields, !!(cached && cached.fields)] : fields;
    };

    filterDataSourceTable(data: any, fields: any) {
        function filterDataSourceTableComparatorFilter(item: any, filterValue: any) {
            let comparator = filterValue.substring(0, 1);
            let isComparator = (comparator === '>' || comparator === '<');
            let ans;

            if (isComparator) {
                isComparator = comparator === '>';
                comparator = filterValue.substring(1);

                ans = isComparator ? (item > comparator) : (item < comparator);

            } else {
                ans = item === parseFloat(filterValue);

            }

            return ans;

        }

        let filterFields = fields.filter((field: any) => field.filter && field.filter.value);
        let filteredData;
        let filterFieldType;
        let filterKey;
        let filterItem;
        let filterValue;

        filteredData = data?.filter((item: any) => {
            let filterThrough = true;

            for (let arrayElement of filterFields) {

                if (!filterThrough) {
                    return false;

                }

                filterFieldType = arrayElement.formatter;
                filterKey = arrayElement.key;
                filterItem = item[filterKey];
                filterValue = arrayElement.filter.value;

                if (filterKey.includes('.')) {
                  let keys = filterKey.split('.');
                  let value = item;
                  keys.forEach( (key: string) => {
                    value = value[key]
                  })
                  filterItem = value;
                }

                switch (filterFieldType) {

                    case 'money':
                        filterThrough = filterDataSourceTableComparatorFilter(filterItem.amount, filterValue);

                        break;

                    case 'numeric':
                        filterThrough = filterDataSourceTableComparatorFilter(filterItem, filterValue);

                        break;

                    case 'dateTime':
                        let dateIter;
                        let dateValue;
                        filterValue = filterValue.split('&');

                        for (dateIter = 0; dateIter < filterValue.length; dateIter++) {
                            dateValue = moment(filterValue[dateIter].substring(2)).format();

                            if (filterValue[dateIter].substring(0, 1) === '>') {

                                if (moment(filterItem) < moment(dateValue)) {
                                    filterThrough = false;
                                    break;

                                }

                            } else {

                                if (moment(filterItem) > moment(dateValue)) {
                                    filterThrough = false;
                                    break;

                                }

                            }

                        }

                        break;

                    default:
                        filterThrough = true;

                        if (filterValue) {

                            if (filterItem) {
                                let filterValues = filterValue.split('|');
                                let found = false;
                                let negative;

                                for (let filter of filterValues) {
                                    if (filter.startsWith('!')) {
                                        negative = hasSubstring(filterItem, filter.substr(1));
                                        break;

                                    } else if (hasSubstring(filterItem, filter)) {
                                        found = true;
                                        break;
                                    }

                                }

                                filterThrough = isDefined(negative) ? !negative : found;

                            } else {
                                filterThrough = false;

                            }

                        }

                        // filterThrough = filterValue ? filterItem && hasSubstring(filterItem, filterValue) : true;

                }
            }

            return filterThrough;
        });

        return filteredData;
    };

    refresh = () => {
        this.timeStamp.next(new Date().getTime());
    };

    setFilterParams = (_params: any, columns: any[]) => {
        const params = omit(_params, ['limit', 'offset', 'paginate']);

        delete params.limit;
        delete params.currentPage;
        delete params._lastSave;

        this.filterParams = params;
        this.columnsParams = columns;
    };

    checkSpecialisationFilters = (params: any, tableId: string, applyFilters: boolean = true): any => {
        if (!tableId) return;
        let result: any = null;

        const employee = this.currUser.person;

        if (this.currUser.isEmployee && employee.specialisations) {
            employee.specialisations.forEach((spec) => {
                let filters = spec.tableFilters;

                if (filters) {
                    let filter =  findByProperty<any>(filters, 'table', tableId);
                    if (filter) {

                        try {
                            const value: { [key: string]: any } = JSON.parse(filter.filter);

                            if (!result) result = {};
                            for (let key of Object.keys(value)) {
                                result[key] = result[key] ? `${result[key]}|${value[key]}` : value[key];
                            }

                        } catch (e) {
                            console.warn('Wrong json format table filter:', filter.filter)
                        }

                    }

                }

            });
        }

        if (applyFilters) Object.assign(params, result);

        return result;

    };

    removeSpecialisationFilters = (params: any, tableId: string) => {
        if (!tableId) return;

        const filters = this.checkSpecialisationFilters(params, tableId, false);

        if (filters) {
            Object.keys(filters)
                .forEach((key: string) => {
                    if (params[key] === filters[key]) delete params[key];
                });

        }

    };

    handleDynamicFields(columns: any[], dynamicFields: any[]) {
        dynamicFields.forEach((dField: any) => {
            let newColumn: TableField = {
                name: (dField.name.length > 10 ? `${dField.name.substr(0, 10)}..` : dField.name),
                tooltip: dField.name,
                key:  `df_${dField.alias}`, maxLength: 30
            };

            if (dField.type === 'integer' || dField.type === 'float') {
                newColumn.formatter = 'numeric';
                newColumn.stats = ['sum'];

            }

            if (dField.type === 'boolean') {
                newColumn.formatter = 'iconBoolean';
                newColumn.filter = {
                    type: 'dropdown',
                    options: [{ name: this.Filter.translate('yes'), id: 'true' }, { name: this.Filter.translate('no'), id: 'false|null' }],
                    key: 'id'
                }

            }

            if (dField.alias) columns.push(newColumn);

        });
    }

    handleExtendedFilter = (settings: any, filter: any) => {
        let filterFields = omit(filter, ['sort', 'select', 'join', 'partialJoin', 'limit', 'translate']);

        settings = { ...settings, filters: Object.assign(settings.filters || {}, filterFields) };

    };

}
