import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { debounce } from '../utils';
import { GlobalOverlayService } from './global-overlay.service';
import { GlobalOverlayAppendingService } from './global-overlay-appending.service';

export type GlobalOverlayType = 'button';

@Directive({
    selector: '[proOverlay]'
})
export class GlobalOverlayDirective implements OnInit, OnDestroy, OnChanges {
    @Input('proOverlay') data: any;
    @Input('proOverlayType') type: GlobalOverlayType = 'button';
    @Input('proOverlayDebounce') debounce: any = 500;
    @Input('proOverlayDelay') delay: any = 0;
    @Input('proOverlayForceHide') forceHide: boolean;
    @Input('proOverlayTemplate') template: TemplateRef<any>;

    _inited: boolean;
    visible: boolean = false;
    toBeShown: boolean = false;

    isIgnored: boolean;

    debounceShow: any;

    constructor(
      private cd: ChangeDetectorRef,
      private GlobalOverlay: GlobalOverlayService,
      private Appending: GlobalOverlayAppendingService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && this._inited) this.GlobalOverlay.setData(changes.data.currentValue);

        if (this.isIgnored && changes.data.currentValue && changes.data.currentValue.data) {
            this.isIgnored = false;
            this.ngOnInit();
        }

        if (changes && changes.forceHide && changes.forceHide.currentValue) {
            this.disableOverlay();
        }
    }

    @HostListener('mouseover', ['$event'])
    public setOverlayData($event: MouseEvent) {
        if (this.isIgnored) return;

        $event.stopPropagation();

        this.GlobalOverlay.template.next(this.template);
        this.GlobalOverlay.setData(this.data);

        this.debounceShow($event);

        this.toBeShown = true;

        this.cd.markForCheck();
    }

    @HostListener('mouseleave')
    public disableOverlay() {
        if (this.isIgnored) return;

        if (!this.visible || this.toBeShown) {
            this.debounceShow.cancel();
            this.GlobalOverlay.hide();

        } else {
            this.GlobalOverlay.hide();

            this.visible = false;

        }

        this.toBeShown = false;
    }

    ngOnInit() {
        if (!this.data.data || this.GlobalOverlay.isMobile) {
            return this.isIgnored = true;

        }

        this.debounceShow = debounce(($event: MouseEvent) => {
            this.GlobalOverlay.show($event);

            this.visible = true;

        }, this.debounce);

        this._inited = true;

        document.addEventListener('click', this.handleDocumentClick);

    }

    handleDocumentClick = () => {
        if (this.visible) {
            this.GlobalOverlay.hide();
        }
    };

    ngOnDestroy() {
        if (this.toBeShown) this.debounceShow.cancel();
        if (this.visible) this.GlobalOverlay.hide();

        document.removeEventListener('click', this.handleDocumentClick);

    }

}
