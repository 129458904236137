import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[proShiftScroll]'
})

export class ShiftScrollHorizontalDirective implements OnInit, OnDestroy {
    private element: Element;

    constructor(el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        window.addEventListener('keydown', this.handleHorizontalMovement);
    }

    ngOnDestroy() {
        window.removeEventListener('keydown', this.handleHorizontalMovement);
    }

    scrollLeft() {
        if (this.element.scrollTo) this.element.scrollTo(this.element.scrollLeft - 50, this.element.scrollTop);
    }

    scrollRight() {
        if (this.element.scrollTo) this.element.scrollTo((this.element.scrollLeft + 50), this.element.scrollTop);
    }

    @HostListener('mousedown', ['$event'])
    handleHorizontalMovement = (event: KeyboardEvent) => {
        const keyCode = event.keyCode;

        if ([37, 39].indexOf(keyCode) > -1 && !document.querySelector('input:focus')) { // leftArrow, rightArrow && input not focused
            if (keyCode === 37) this.scrollLeft();
            if (keyCode === 39) this.scrollRight();

        }

    };

    @HostListener('wheel', ['$event'])
    handleScroll = (event: WheelEvent) => {

        if (event.shiftKey) {
            event.preventDefault();

            if (event.deltaY < 0) this.scrollLeft();
            if (event.deltaY > 0) this.scrollRight();

        }

    };

}
