import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'proFilterFunction' })

export class FilterFunctionPipe implements PipeTransform {
    transform(items: any[], filter: (item: any, index?: number) => boolean): any[] {

        if (!items || !filter) {
            return items;
        }

        return items.filter(filter);

    }
}
