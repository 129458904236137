import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proSmartDateTime' })

export class SmartDatePipe implements PipeTransform {

    constructor(
        private Filter: FilterService,
    ) {

    }

    transform(start: any, end: any) {
        return this.Filter.smartDateRange(start, end);
    }
}
