import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';

@Component({
    selector: 'pm-btn-list',
    template: `
      <pro-editable-container [label]="config.label">
          <div fxLayout="row wrap">
              <pro-btn *ngFor="let option of options"
                      [label]="getOptionName(option)"
                      [theme]="value?.id === option.id && 'accent'"
                      [background]="'#' + option.color"
                      (onClick)="handleClick(option)"
                      [disabled]="disabled"
                      style="padding: 4px"
              ></pro-btn>
          </div>
      </pro-editable-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BtnListComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: {
        label?: string;
        displayKey?: string;
        entity?: EntityNameType;
        entityParams?: any;
    } = {};
    @Input() options: any[];
    @Input() disabled: boolean;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private Entity: Entity,
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.setupOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config && !changes.config.isFirstChange()) {
            this.setupOptions();
        }
    }

    async setupOptions() {
        if (this.config.entity) {
            this.options = await this.Entity.get(this.config.entity)
                .search(this.config.entityParams || {});

            this.cd.markForCheck();

        }
    }

    getOptionName = (item: any) => {
        return item[this.config.displayKey || 'name'];
    };

    handleClick(option: any) {
        this.onChange.emit(option);
    }

}
