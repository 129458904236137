import { NgModule } from '@angular/core';

import { TranslatePipe } from './pipes/translate.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ThumbPathPipe } from './pipes/thumb-path.pipe';
import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { CurrencyShorthandPipe } from './pipes/currency-shorthand.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DatePipe } from './pipes/date.pipe';
import { SmartDatePipe } from './pipes/smart-date.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { PricePipe } from './pipes/price.pipe';
import { EventNamePipe } from './pipes/event-name.pipe';
import { ZeroPadPipe } from './pipes/zero-pad.pipe';
import { TreePaddingPipe } from './pipes/tree-padding.pipe';
import { QuantFormatPipe } from './pipes/quant-format.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { FilterFunctionPipe } from './pipes/filter-function.pipe';
import { WeightPipe } from './pipes/weight.pipe';
import { ClickableUrlPipe } from './pipes/clickable-url.pipe';

const PIPES = [
    FilterPipe,
    FilterFunctionPipe,
    ThumbPathPipe,
    HtmlSanitizerPipe,
    MaxLengthPipe,
    CurrencyShorthandPipe,
    DateTimePipe,
    DatePipe,
    SmartDatePipe,
    SortPipe,
    KeysPipe,
    PricePipe,
    EventNamePipe,
    ZeroPadPipe,
    TreePaddingPipe,
    QuantFormatPipe,
    MarkdownPipe,
    DecimalPipe,
    DurationPipe,
    UrlSanitizerPipe,
    ReversePipe,
    WeightPipe,
    ClickableUrlPipe,
];

@NgModule({
    imports: [
        TranslatePipe,
    ],
    declarations: PIPES,
    providers: PIPES,
    exports: [
        ...PIPES,
        TranslatePipe,
    ]
})

export class PipesModule {

}
