import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { OPERATORS_TYPES } from '@proman/utils/expressions_config';

@Component({
    selector: 'pro-expression-case',
    template: `
        <div>
            <div class="List">
                <div class="List-row"
                     *ngFor="let expressionValue of expression.values; let $index = index; let  $first = first; let $last = last;">
                    <pro-checkbox *ngIf="!$first && $last"
                                 [config]="{ label: 'default_value' }"
                                 [value]="expressionValue.isDefault"
                                 (onChange)="toggleDefault(expressionValue, $event)"></pro-checkbox>
                    <div *ngIf="!expressionValue.isDefault" fxLayout="column">
                        <div fxLayout="row"
                             fxLayoutAlign="start center">
                            <h4>
                                <span>
                                    {{ 'when' | translate }}
                                </span>
                                <span *ngFor="let condition of expressionValue.condition">
                                    <span *ngIf="condition.config?.type === 'parameter'">{{ condition.value?.name }}</span>
                                    <span *ngIf="condition.config?.type !== 'parameter'">{{ condition.value }}</span>
                                </span>
                            </h4>
                            <div fxFlex></div>
                            <pro-btn *ngIf="expression.values.length > 1"
                                    (onClick)="deleteCondition(expression.values, $index)"
                                    icon="trash"
                                    theme="warn"></pro-btn>
                        </div>
                        <div fxLayout="row"
                             fxLayoutAlign="start center">
                            <div *ngFor="let condition of expressionValue.condition; let $childChildIndex = index; let $childChildLast = last">
                                <div fxLayout="row"
                                     fxLayoutAlign="start center">
                                    <pro-btn *ngIf="condition.value && !condition.isEditMode"
                                            (onClick)="edit(condition)"
                                            icon="edit"
                                            theme="primary"></pro-btn>
                                    <pro-btn *ngIf="condition.value && condition.isEditMode"
                                            (onClick)="confirmEdit(condition)"
                                            icon="check-circle"
                                            theme="primary"></pro-btn>
                                    <pro-btn *ngIf="condition.value && condition.isEditMode"
                                            (onClick)="deleteConditionItem(expressionValue.condition, $childChildIndex)"
                                            icon="trash"
                                            theme="warn"></pro-btn>
                                    <pro-select *ngIf="!condition.value || condition.isEditMode"
                                            [ngClass]="{ 'width-75' : condition.config?.type === 'numeric' }"
                                            [value]="condition.config"
                                            [config]="{ label: 'type', isNone: true }"
                                            [options]="types"
                                            (onChange)="resetValue(condition, $event)"></pro-select>
                                    <pro-text-simple *ngIf="condition.config?.type === 'numeric'"
                                                     [value]="condition.value"
                                                     [config]="{ label: 'value' }"
                                                     (onChange)="set(condition, $event)"></pro-text-simple>
                                    <pro-select *ngIf="condition.config?.type === 'parameter'"
                                            [value]="condition.value"
                                            [config]="{ label: 'value' }"
                                            (onChange)="set(condition, $event)"
                                            [options]="parameters"></pro-select>
                                    <pro-select *ngIf="condition.config?.type === 'operator'"
                                            [value]="condition.value"
                                            [config]="{ label: 'operator' }"
                                            (onChange)="set(condition, $event.id)"
                                            [options]="operatorsTypes"></pro-select>
                                    <pro-btn *ngIf="$childChildLast && condition.value"
                                            (onClick)="addNewCondition($index)"
                                            icon="plus"
                                            theme="accent"></pro-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>
                        <span *ngIf="!expressionValue.isDefault">
                            {{ 'then' | translate }}
                        </span>
                                <span *ngIf="expressionValue.isDefault">
                            {{ 'otherwise' | translate }}
                        </span>
                        <span *ngFor="let conditionOperation of expressionValue.conditionOperation">
                            <span *ngIf="conditionOperation.config?.type === 'parameter'">{{ conditionOperation.value?.name }}</span>
                            <span *ngIf="conditionOperation.config?.type !== 'parameter'">{{ conditionOperation.value }}</span>
                        </span>
                    </h4>
                    <div fxLayout="row wrap"
                         fxLayoutAlign="start center">
                        <div *ngFor="let conditionOperation of expressionValue.conditionOperation; let $childChildIndex = index; let $childChildLast = last;">
                            <div fxLayout="row"
                                 fxLayoutAlign="start center">
                                <pro-btn *ngIf="conditionOperation.value && !conditionOperation.isEditMode"
                                        (onClick)="edit(conditionOperation)"
                                        icon="edit"
                                        theme="primary"></pro-btn>
                                <pro-btn *ngIf="conditionOperation.value && conditionOperation.isEditMode"
                                        (onClick)="confirmEdit(conditionOperation)"
                                        icon="check-circle"
                                        theme="primary"></pro-btn>
                                <pro-btn *ngIf="conditionOperation.value && conditionOperation.isEditMode"
                                        (onClick)="deleteConditionItem(expressionValue.conditionOperation, $childChildIndex)"
                                        icon="trash"
                                        theme="warn"></pro-btn>
                                <pro-select *ngIf="!conditionOperation.value || conditionOperation.isEditMode"
                                        [ngClass]="{ 'width-75' : conditionOperation.config && conditionOperation.config.type === 'numeric' }"
                                        [value]="conditionOperation.config"
                                        [config]="{ label: 'type' }"
                                        [options]="types"
                                        (onChange)="resetValue(conditionOperation, $event)"></pro-select>
                                <pro-text-simple *ngIf="conditionOperation.config?.type === 'numeric'"
                                                 [value]="conditionOperation.value"
                                                 [config]="{}"
                                                 (onChange)="set(conditionOperation, $event)"></pro-text-simple>
                                <pro-select *ngIf="conditionOperation.config?.type === 'parameter'"
                                        [value]="conditionOperation.value"
                                        [config]="{ label: 'value' }"
                                        [options]="parameters"
                                        (onChange)="set(conditionOperation, $event)"></pro-select>
                                <pro-select *ngIf="conditionOperation.config?.type === 'operator'"
                                        [value]="conditionOperation.value"
                                        [config]="{ label: 'operator' }"
                                        [options]="operatorsTypes"
                                        (onChange)="set(conditionOperation, $event.id)"></pro-select>
                                <pro-btn *ngIf="$childChildLast && conditionOperation.value"
                                        (onClick)="addNewConditionOperation($index)"
                                        icon="plus"
                                        theme="accent"></pro-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <small>
                {{ previewValue }}
            </small>
            <div fxLayout="row">
                <pro-btn (onClick)="addCondition()"
                         [label]="'add_condition' | translate"
                         theme="accent"
                         [disabled]="!canAdd()"></pro-btn>
                <pro-btn (onClick)="addExpression()"
                         [label]="'add_expression' | translate"
                         [disabled]="!canAdd()"></pro-btn>
            </div>
        </div>
    `,
    styles: [`
        .width-75 { width: 75% }
        pro-select { min-width: 90px; }
    `]
})

export class ExpressionCaseComponent implements OnInit {
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    operatorsTypes: any;
    expression: any;
    parameters: any;
    types: any;
    previewValue: any;

    isConditionCreateMode: boolean;
    isAddExpressionMode: boolean;
    isAddNewVariableMode: boolean;

    constructor(
        private Entity: Entity,
        private filter: FilterService,
    ) {

    }

    ngOnInit() {
        this.isConditionCreateMode = true;
        this.isAddExpressionMode = false;
        this.isAddNewVariableMode = true;

        this.expression = {
            values: [{
                condition: [{ /*config: {}*/ }],
                conditionOperation: [{ /*config: {}*/ }]
            }]
        };

        this.types = [
            {
                name: this.filter.translate('numeric'),
                type: 'numeric'
            },
            {
                name: this.filter.translate('parameter'),
                type: 'parameter'
            },
            {
                name: this.filter.translate('operator'),
                type: 'operator'
            }
        ];

        this.operatorsTypes = OPERATORS_TYPES.map((item: string) => {
            return {
                id: item,
                name: item
            }
        });

        this.Entity.get('parameter')
            .search({})
            .then((response: any ) => this.parameters = response);
    }

    formatExpression() {
        const expressionValues = this.expression.values;
        let output = 'case';

        for (const expression of expressionValues) {
            const expressionCondition = expression.condition;
            const expressionConditionOperation = expression.conditionOperation;

            if (expressionCondition && expressionCondition.length) {
                if (!expression.isDefault) {
                    output += ' when';
                }

                for (const expressionConditionValue of expressionCondition) {
                    const value = expressionConditionValue.value;
                    const config = expressionConditionValue.config;

                    if (expressionConditionValue && config && value) {
                        if (config && config.type === 'numeric' || config.type === 'operator') {
                            output += ' ' + expressionConditionValue.value;
                        } else if (config.type === 'parameter') {
                            output += ' p(' + value.id + ')';
                        }
                    }
                }
            }

            if (expressionConditionOperation && expressionConditionOperation.length) {
                output += (expression.isDefault ? ' else' : ' then');

                for (const expressionConditionOperationValue of expressionConditionOperation) {
                    const value = expressionConditionOperationValue.value;
                    const config = expressionConditionOperationValue.config;

                    if (expressionConditionOperationValue && value) {
                        if ((config.type === 'numeric' || config.type === 'operator')) {
                            output += ' ' + expressionConditionOperationValue.value;
                        } else if (config.type === 'parameter' && value) {
                            output += ' p(' + expressionConditionOperationValue.value.id + ')';
                        }
                    }
                }
            }
        }

        output += ' end';

        return output;
    }

    set = (item: any, value: any) => {
        item.value = value;

        this.update();
    };

    toggleDefault = (item: any, value: any) => {
        item.isDefault = value;
    };

    canAdd = () => {
        const expression = this.expression;

        return expression.values[0].condition.length > 2 && expression.values[0].conditionOperation[0].value;
    };

    addNewCondition(parentIndex: number) {
        this.expression.values[parentIndex].condition.push({ /*config: {}*/ });
    }

    addNewConditionOperation(parentIndex: number) {
        this.expression.values[parentIndex].conditionOperation.push({ /*config: {}*/ });
    }

    addCondition() {
        this.expression.values.push({
            condition: [{ /*config: {}*/ }],
            conditionOperation: [{ /*config: {}*/ }]
        });

        this.update();
    }

    edit(item: any) {
        item.isEditMode = true;

        this.update();
    }

    deleteCondition(items: any, index: number) {
        items.splice(index, 1);

        this.update();
    }

    deleteConditionItem(items: any, index: number) {
        items.splice(index, 1);

        this.update();
    }

    confirmEdit(item: any) {
        delete item.isEditMode;

        this.update();
    }

    resetValue(item: any, value: any) {
        delete item.value;

        item.config = value;

        this.update();
    }

    addExpression() {
        const output = this.formatExpression();

        this.onChange.emit(output);
    }

    update() {
        this.previewValue = this.formatExpression();
    }

}
