import { Injectable } from '@angular/core';
import { PromanFile } from '../interfaces/entity-interfaces';
import { Entity } from './entity.service';
import { FileEntityInterface, PromanFileIconType } from '../resources/file';
import { Store } from '@ngrx/store';
import { getPublicSystemOptions } from "@proman/store/system-options";

enum ImageFilePathSuffix {
    Icon = '/icon.jpg',
    Ocr = '/preview.ocr',
    Preview = '/preview.jpg',
    Jpeg = '/preview.jpeg',
    View = '/view',
    Download = '/download',
    Raw = '/raw',
    Stream = '/stream',
}

@Injectable({ providedIn: 'root' })
export class ImagePathService {
    fileEntity: FileEntityInterface;
    mediaUrl: string;
    constructor(
        private Entity: Entity,
        private store: Store,
    ) {
        this.fileEntity = this.Entity.get('file');
        this.store.select(getPublicSystemOptions).subscribe((value: any) => this.mediaUrl = value.media_server + '/');
    }

    getFile(file: PromanFile, type: PromanFileIconType = 'png'): string|null {
        if (!file || (!file.newId && !file.path) ) {
            return null;
        }

        let suffix;

        // tslint:disable-next-line:no-unused-expression
        switch (type) {
            case 'raw':
                suffix = ImageFilePathSuffix.Raw;
                break;
            case 'icon':
                suffix = ImageFilePathSuffix.Icon;
                break;
            case 'png':
                suffix = ImageFilePathSuffix.Preview;
                break;
            case 'pdf':
                suffix = ImageFilePathSuffix.View;
                break;
            case 'jpeg':
                suffix = ImageFilePathSuffix.Jpeg;
                break;
            case 'ocr':
                suffix = ImageFilePathSuffix.Ocr;
                break;
            case 'main':
                suffix = ImageFilePathSuffix.Download;
                break;
            case 'stream':
                suffix = ImageFilePathSuffix.Stream;
                break;
            default:
                suffix = null;
        }

        if (!file.newId) {
          return `${this.mediaUrl}${file.path}${suffix}`;
        }
        return `${this.mediaUrl}${file.newId}${suffix}`;
    }

}
