import { Component, Input } from '@angular/core';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { NgIf } from '@angular/common';

@Component({
    selector: 'pro-label',
    standalone: true,
    imports: [
        PipesModule,
        NgIf,
    ],
    template: `
        <h3 class="Label">
            <ng-content></ng-content>
            <ng-container *ngIf="value"> {{ value | translate }} </ng-container>
        </h3>
    `
})

export class LabelComponent {
    @Input() value: string;

}
