import { Injectable } from '@angular/core';
import { ACL } from '@proman/services/acl.service';
import { deepCopy, isArray, isDefinedNotNull, isObject, prepareRequest } from '@proman/utils';

import { Entity, EntityInterface, EntityNameType } from '@proman/services/entity.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import * as lodash from 'lodash';

interface RequestInterface {
    id: number;
    version?: number;
}

export interface ModelItemInterface<T = any> {
    create: (data?: any) => Promise<any>;
    update: (property: keyof T, value: any, isId?: boolean) => Promise<any>;
    updateAssociation: (property: string, value: any) => Promise<any>;
    updateItem:  (item: keyof T, property: string, value: any) => void;
    addAssociation: (property: string, value: any) => Promise<any>;
    removeAssociation: (property: string, value: any) => Promise<any>;
    addFile: (data: PromanFile[], key?: string) => Promise<any>;
    removeFile: (data: { file: PromanFile; index: number }, key?: string) => Promise<any>;
    addHandler: (property: string, callback: (data?: any) => void) => void;
    permissions: { [key: string]: boolean };
}

class ModelHelper {
    handlers: any = {};
    originalEntity: any;

    constructor(
        private entity: any,
        private Entity: any,
        public permissions: any,
        private versioned: any,
    ) {
        if (this.entity) this.originalEntity = deepCopy(this.entity);
    }

    getRequest() {
        let request: RequestInterface = { id: this.entity.id };

        if (this.versioned) {
            request.version = this.entity.version;

        }

        return request;
    }

    create() {
        let data = prepareRequest(this.entity);

        return this.Entity
            .create(data);

    }

    updateItem(item: any, property: string, value: any) {
        let data = { id: item.id, [property]: value };

        let oldValue = isObject(item[property]) && Object.keys(item[property]).length ? Object.assign({}, item[property]) : item[property];

        return this.Entity
            .update(data)
            .then((response: any) => {
                item[property] = value;
                this.success(property, value)(response);
            })
            .catch(() => item[property] = oldValue);
    }

    update(property: any, value: any, isId: boolean = false) {
        let oldValue = isObject(this.entity[property]) && Object.keys(this.entity[property]).length ? Object.assign({}, this.entity[property]) : this.entity[property];
        this.entity[property] = value;

        if (this.entity.id) {
            let data = this.getRequest();

            data[property] = isId ? (value && value.id || null) : value;

            return this.Entity
                .update(data)
                .then(this.success(property, value))
                .catch(() => this.entity[property] = oldValue);

        } else {
            this.success(property, value)(null);

        }

    }

    updateAssociation(property: any, value: any) {
        return this.update(property, value, true);
    }

    success(property: any, value: any) {

        return (response: any) => {

            if (this.versioned) {
                this.entity.version = response.version;
            }

            this.executeHandlers(property, value);
            // this.Pace.trigger();
        };
    }

    executeHandlers(property: string, value: any) {

        if (this.handlers[property]) {

            for (let handler of this.handlers[property]) {
                handler(value);
            }

        }

        if (this.handlers['*']) {
            for (let handler of this.handlers['*']) {
                handler(value);
            }
        }

    }

    addHandler(property: string, callback: (data?: any) => void) {

        if (!this.handlers[property]) this.handlers[property] = [];

        this.handlers[property].push(callback);
    }

    addAssociation(property: any, value: any) {
        const data = this.getRequest();

        data[property] = value?.id;

        return this.Entity.addAssociation(data);
    }

    removeAssociation(property: any, value: any) {
        const data = this.getRequest();

        data[property] = value.id;

        return this.Entity.removeAssociation(data);
    }

    addFile(data: PromanFile[], key: string = 'files') {
        const uploadedFiles: PromanFile[] = lodash.differenceBy(data, this.originalEntity[key], 'id');
        const files: PromanFile[] = [];

        const uploadedIds = uploadedFiles.filter((i) => isDefinedNotNull(i)).map((i) => i.id);
        // data.forEach((file: any) => {
        //     if (isDefinedNotNull(file)) {
        //         if (!file.error) {
        //             files.push(file);
        //             uploadedFiles.push(file.id);
        //         }
        //     }
        // });

        if (uploadedFiles.length) {

            if (this.entity.id) {
                return this.Entity
                    .addAssociation({ id: this.entity.id, [key]: uploadedIds })
                    .then(() => {
                        if (this.originalEntity && this.originalEntity[key]) {
                            if (isArray(this.originalEntity[key])) {
                                this.originalEntity[key].push(uploadedFiles[0]);
                            } else {
                                this.originalEntity[key] = uploadedFiles[0];
                            }
                        }
                    })

            } else {
                this.entity[key] = this.entity[key] ? this.entity[key].concat(files) : files;
            }
        }
    }

    removeFile(data: { file: PromanFile; index: number }, key: string = 'files') {

        if (this.entity.id) {
            return this.Entity
                .removeAssociation({ id: this.entity.id, [key]: data.file.id })
                .then(() => isArray(this.entity[key]) ? this.entity[key].splice(data.index, 1) : this.entity[key] = null);
        } else {
            this.entity.files.splice(data.index, 1);
        }
    }
}

/**
 * `Model` service
 *
 */
@Injectable()
export class ModelService {

    constructor(private acl: ACL, private Entity: Entity) {

    }

    getPermissions(Entity: any) {
        let endpoint = Entity.config.endpoint;
        let permissions: { [key: string]: boolean } = {};
        let parsedKey;

        for (let key in this.acl.permissions) {

            if (key.startsWith(endpoint)) {
                parsedKey = key.replace(endpoint + '.', '');
                permissions[parsedKey] = this.acl.permissions[key];

            }

        }

        return permissions;
    }

    get(entity: any, _Entity: EntityNameType|EntityInterface, versioned?: boolean): any {
        let Entity: any = typeof _Entity === 'string' ?
            this.Entity.get({ name: _Entity }) :
            _Entity;
        let permissions = this.getPermissions(Entity);

        return new ModelHelper(entity, Entity, permissions, versioned);
    }

}
