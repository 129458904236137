import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../../../services/filter.service';

@Pipe({ name: 'proDateTime' })

export class DateTimePipe implements PipeTransform {

    constructor(
        private Filter: FilterService,
    ) {

    }

    transform(value: any) {
        return this.Filter.dateTime(value);
    }
}
