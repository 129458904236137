import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { GlobalOverlayComponent } from './global-overlay.component';

@Injectable({ providedIn: 'root' })
export class GlobalOverlayAppendingService {

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.appendElement();
  }

  appendElement() {
    const componentRef: ComponentRef<unknown> = this.componentFactoryResolver
      .resolveComponentFactory(GlobalOverlayComponent)
      .create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

}
