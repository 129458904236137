import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from 'ngx-flexible-layout';
import { DocsIdValues } from '@proman/store/docs-id/docs-id.reducer';

const DEFAULT_POS = [-9999, -9999];

@Injectable({ providedIn: 'root' })
export class OverlayService {
    overlayPos: number[] = DEFAULT_POS;
    overlayShow: Subject<number> = new Subject<number>();
    data: BehaviorSubject<any> = new BehaviorSubject<any>({});

    docValues: Subject<DocsIdValues> = new Subject<DocsIdValues>();
    docShow: Subject<boolean> = new Subject<boolean>();
    docActive: Subject<boolean> = new Subject<boolean>();

    watcher: Subscription;
    isMobile: boolean;

    constructor(
        media: MediaObserver,
    ) {
        this.watcher = media.asObservable().subscribe((change: MediaChange[]) => {
            this.isMobile = change.some((change: MediaChange) => change.mqAlias === 'xs');
        });
    }

    // -- COMMON METHODS

    getPos(el: ElementRef, i: number) {
        const getPxString = (value: number) => `${value}px`;

        if (!el.nativeElement) return ;

        let _el: number[] = [ +el.nativeElement.getBoundingClientRect().width, +el.nativeElement.getBoundingClientRect().height ];
        let _window: number[] = [window.innerWidth, window.innerHeight];
        let event: number[] =  this.overlayPos;

        return getPxString((_window[i] < (event[i] + _el[i] + 10)) ? _window[i] - _el[i] - 30 : event[i] + 10);

    }

    // -- OVERLAY

    setData(data: any) {
        this.data.next(data);
    }

    show($event: any) {
        this.overlayPos = [$event.clientX, $event.clientY];
        this.overlayShow.next($event.clientX + $event.clientY);
    }

    hide() {
        this.overlayPos = DEFAULT_POS;
        this.overlayShow.next(0);
    }

    // -- DOCS ID OVERLAY

    docsSetKey(value: DocsIdValues) {
        this.docValues.next(value);
    }

    docsShow($event: MouseEvent) {
        this.overlayPos = [$event.clientX, $event.clientY];
        this.docShow.next(true);
    }

    docsHide() {
        this.overlayPos = DEFAULT_POS;
        this.docShow.next(false);
    }

    docsSetActive(value: boolean) {
        this.docActive.next(value);
    }

}
