import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'pro-entity-table-color',
    template: `
        <div class="EntityTableColor"
             #element>
        </div>
    `
})

export class EntityTableColorComponent implements OnInit {
    @Input() item: any;
    @Input() config: any;
    @ViewChild('element', { static: true }) element: ElementRef;

    ngOnInit() {
        let element = this.element.nativeElement;

        let value: string = this.config.getValue ? this.config.getValue(this.item) : (this.item[this.config.key] || 'fff');

        element.style.backgroundColor = '#' + value;

        if ( !(this.item[this.config.key] && this.config.getValue) ) {
            element.classList.add('no-color');

        }

    }
}
