import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[pmPadding]'
})

export class PaddingDirective {
    constructor(el: ElementRef, renderer: Renderer2) {
        renderer.addClass(el.nativeElement, 'PaddingLayout');
    }
}
