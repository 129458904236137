import { AbstractControl } from '@angular/forms';

export function NumberValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = (!isNaN(parseFloat(value)) && isFinite(value) || !value);

    if (!isValid) return { isValidNumber: true };

    return null;
}
