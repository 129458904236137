import { OverlayRef, PositionStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { INITIAL_CONTAINER_STYLE } from './attacher';
import { Point } from './models/origin';
import { Size } from './models/origin';
import { CustomAttacherOrigin } from './models/origin';
import { CustomAttacherPositionToOrigin } from './models/position-to-origin';
import getBoundingClientRect from '@popperjs/core/lib/dom-utils/getBoundingClientRect';

const BOUNDING_BOX_CLASS = 'custom-attacher-bounding-box';
const INITIAL_BOUNDING_STYLE = {
    position: 'absolute',
    top: '',
    left: '',
    bottom: '',
    right: ''
} as CSSStyleDeclaration;

/**
 * A posiiton strategy to position attacher to its origin element.
 */
export class CustomAttacherPositionStrategy implements PositionStrategy {
    private _origin?: CustomAttacherOrigin;

    /** The overlay to which this strategy is attached. */
    private _overlayRef: OverlayRef;

    /** Whether the strategy has been disposed of already. */
    private _isDisposed: boolean;

    /**
     * The created bounding box div element contains the host element.
     */
    private _boundingBox: HTMLElement | null;

    /** Subscription to viewport size changes. */
    private _resizeSubscription = Subscription.EMPTY;

    private _positionToOrigin: CustomAttacherPositionToOrigin;

    constructor(
        private origin: CustomAttacherOrigin,
        private _viewportRuler: ViewportRuler
    ) {
        this.setOrigin(origin);
    }

    /**
     * Attaches this position strategy to an attacher.
     */
    attach(overlayRef: OverlayRef): void {
        overlayRef.hostElement.classList.add(BOUNDING_BOX_CLASS);
        // Bring the attacher container back to top.
        overlayRef.hostElement.parentElement.style.zIndex =
            INITIAL_CONTAINER_STYLE.zIndex;

        this._overlayRef = overlayRef;
        this._boundingBox = overlayRef.hostElement;
        this._isDisposed = false;
        this._resizeSubscription.unsubscribe();
        this._resizeSubscription = this._viewportRuler.change().subscribe(() => {
            this.apply();
        });
    }

    /**
     * Set the position of attacher to the origin.
     */
    positionToOrigin(position: CustomAttacherPositionToOrigin) {
        this._positionToOrigin = position;
        return this;
    }

    /**
     * Positiones attacher based on its origin element through applying
     * css styles (left, right ,top) on the `BOUNDING_BOX_CLASS`.
     */
    apply() {
        this._resetBoundingBoxStyle();
        const originPosition = this._originPosition;

        switch (this._positionToOrigin) {
            case CustomAttacherPositionToOrigin.topLeft:
                this._updatePosition(
                    this._overlayRef.getDirection() === 'rtl'
                        ? {
                            top: `${originPosition.y}px`,
                            right: `${originPosition.x}px`,
                            left: 'auto'
                        }
                        : {
                            top: `${originPosition.y}px`,
                            left: `${originPosition.x}px`,
                            right: 'auto'
                        }
                );
                break;
            case CustomAttacherPositionToOrigin.topRight:
                this._updatePosition(
                    this._overlayRef.getDirection() === 'rtl'
                        ? {
                            top: `${originPosition.y}px`,
                            right: `${originPosition.x + originPosition.width}px`,
                            left: 'auto'
                        }
                        : {
                            top: `${originPosition.y}px`,
                            left: `${originPosition.x + originPosition.width}px`,
                            right: 'auto'
                        }
                );
                break;
            case CustomAttacherPositionToOrigin.bottomLeft:
                this._updatePosition(
                    this._overlayRef.getDirection() === 'rtl'
                        ? {
                            top: `${originPosition.y + originPosition.height}px`,
                            right: `${originPosition.x}px`,
                            left: 'auto'
                        }
                        : {
                            top: `${originPosition.y + originPosition.height}px`,
                            left: `${originPosition.x}px`,
                            right: 'auto'
                        }
                );
                break;
            case CustomAttacherPositionToOrigin.bottomRight:
                this._updatePosition(
                    this._overlayRef.getDirection() === 'rtl'
                        ? {
                            top: `${originPosition.y + originPosition.height}px`,
                            right: `${originPosition.x + originPosition.width}px`,
                            left: 'auto'
                        }
                        : {
                            top: `${originPosition.y + originPosition.height}px`,
                            left: `${originPosition.x + originPosition.width}px`,
                            right: 'auto'
                        }
                );
                break;
        }
    }

    /**
     * Detaches the panel.
     */
    detach(): void {
        this._resizeSubscription.unsubscribe();
    }

    /** Cleanup after the element gets destroyed. */
    dispose() {
        if (this._isDisposed) {
            return;
        }

        // We can't use `_resetBoundingBoxStyles` here, because it resets
        // some properties to zero, rather than removing them.
        if (this._boundingBox) {
            this._resetBoundingBoxStyle();
        }

        if (this._overlayRef) {
            this._overlayRef.hostElement.classList.remove(BOUNDING_BOX_CLASS);
            this._overlayRef.hostElement.parentElement.style.zIndex = '-1';
        }

        this.detach();
        this._overlayRef = this._boundingBox = null!;
        this._isDisposed = true;
    }

    /**
     * Sets origin to position attacher to.
     */
    setOrigin(origin: CustomAttacherOrigin) {
        this._origin = origin;
        return this;
    }

    private get _originPosition(): Point & Size {
        // type is ElementRef
        console.log('_origin', this._origin);
        if ('nativeElement' in this._origin) {console.log('1');
            const {
                offsetLeft,
                offsetTop,
                offsetWidth,
                offsetHeight
            } = this._origin.nativeElement;
            const rect = getBoundingClientRect(this._origin.nativeElement);
            const top = rect.top;
            const height = rect.height;
            let y;

            const popupElement = this._overlayRef.hostElement;
            const innerRect = getBoundingClientRect(popupElement);

            if ((top + innerRect.height) > window.innerHeight && innerRect.height < window.innerHeight) {
                y = window.innerHeight - innerRect.height
            } else {
             y = rect.top;
            }
            return {
                x: offsetLeft,
                // y: offsetTop,
                y,
                width: offsetWidth,
                height: offsetHeight
            };
        } else if ('offsetParent' in this._origin) {
            // type is Element
            const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = this._origin as any;
            return {
                x: offsetLeft,
                y: offsetTop,
                width: offsetWidth,
                height: offsetHeight
            };
        } else {
            // type is Point & {width, height}
            return { ...this._origin } as Point & Size;
        }
    }

    private _updatePosition(positionStyles: Partial<PositionStyles>) {
        extendStyles(this._boundingBox.style, {
            ...{...positionStyles},
            ...{ zIndex: 9990} as any,
        } as CSSStyleDeclaration);
    }

    private _resetBoundingBoxStyle() {
        extendStyles(this._boundingBox.style, INITIAL_BOUNDING_STYLE);
    }
}

/** Styles value for the position. */
interface PositionStyles {
    top: string;
    left: string;
    bottom: string;
    right: string;
    zIndex?: string|number;
}

/** Shallow-extends a stylesheet object with another stylesheet object. */
function extendStyles(
    destination: CSSStyleDeclaration,
    source: CSSStyleDeclaration
): CSSStyleDeclaration {
    for (let key in source) {
        if (source.hasOwnProperty(key)) {
            destination[key] = source[key];
        }
    }

    return destination;
}
