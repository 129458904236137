import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { hasSubstring } from '@proman/utils';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pro-parameter-dropdown',
    template: `
        <div fxLayout="row">
            <pro-autoc [value]="tmpVal"
                      [config]="localConfig"
                      [control]="control"
                      [disabled]="disabled"
                      [options]="options"
                      (onSearch)="handleSearch($event)"
                      (onChange)="handleChange($event)"
                      fxFlex></pro-autoc>
            <pro-btn *ngIf="config.isFilterClearable && config.entityParams && !disabled && !config.hideFilter"
                    [icon]="localConfig.entityParams ? 'times' : 'undo'"
                    (click)="toggleFilter()"
                    [tooltip]="localConfig.entityParams ? 'clear_filter' : 'restore_filter' | translate" size="1"></pro-btn>
        </div>
    `
})
export class ParameterDropdownComponent implements OnInit {
    @Input() config: any;
    @Input() value: any;
    @Input() disabled: any;
    @Input() control: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    tmpVal: any;
    localConfig: any;
    options: any;
    optionsConfig: any;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
        private ParametersOptions: ParametersOptionsService,
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.setLocalConfig();

        if (this.value && !isNaN(parseInt(this.value))) {
            this.Entity.get(this.config.entity)
                .get({ id: this.value })
                .then((response: any) => {
                    this.tmpVal = response;
                    this.cd.markForCheck();
                });
        }
    }

    async setLocalConfig() {
        const Entity = this.Entity;

        async function handleParameterValues(params: any) {

            try {
                params = JSON.parse(params);
            } catch (e) {

            }

            if (params && Object.keys(params).length) {
                for (let key in params) {
                    let value = params[key];

                    if (!(typeof value === 'string')) break;

                    if (hasSubstring(value, 'p(') && hasSubstring(value, ')')) {
                        value = value.substr(value.indexOf('p(') + 2); // remove 'p(' from start
                        value = value.substr(0, value.indexOf(')')); // remove ')' from end

                        const id = parseInt(value);

                        if (!isNaN(id)) {
                            await Entity.get('parameter').get({ id, select: ['value'] })
                                .then((response: any) => params[key] = response.value)
                                .catch(() => { });
                        }

                    }

                }
            }

            return params;

        }

        this.localConfig = Object.assign({}, this.config);

        if (this.config.isFilterClearable) {
            this.localConfig.cache = false;

        }

        if (this.localConfig.entity === 'material' && this.ACL.check('material.view')) {
            this.localConfig.entityParams = await handleParameterValues(this.localConfig.entityParams);

            if (this.config.parameter?.parameter?.sort) this.localConfig.entityParams.sort = { name: 'asc' };

            if (this.config.images) {
                if (!this.localConfig.entityParams) this.localConfig.entityParams = {};

                this.localConfig.entityParams.join = ['files'];

                this.localConfig.transform = (item: any) => {
                    if (item.files && item.files[0]) {
                        item.icon = item.files[0];
                    }
                };

                this.localConfig.imageKey = 'icon';
            }

        }

        if (this.localConfig.entity === 'parameter_dropdown_option') {
            if (this.config.parameter?.parameter?.sort) this.localConfig.entityParams.sort = { position: 'asc' };
        }

        this.optionsConfig = { entity: this.localConfig.entity, entityParams: this.localConfig.entityParams };

        delete this.localConfig.entity;

    }

    handleChange(value: any) {
        this.onChange.emit(value && value.id || null);
    }

    toggleFilter() {

        if (this.localConfig.entityParams) {
            this.localConfig.entityParams = null;

        } else {
            this.setLocalConfig();

        }

    }

    handleSearch(query: string) {
        this.options = this.ParametersOptions.search(
            {
                entity: this.optionsConfig.entity,
                entityParams: Object.assign(
                    {},
                    { sort: { name: 'asc' } },
                    this.optionsConfig.entityParams,
                    { search: { name: query }},
                )
            }
        );
    }
}
