import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { isArray, isFile, isObject, mapId } from '@proman/utils';
import * as lodash from 'lodash';

@Component({
    selector: 'pm-entity-edit-dialog',
    template: `
        <form #editForm="ngForm"
              [formGroup]="form">
            <pro-dialog-title [title]="header"></pro-dialog-title>
            <div mat-dialog-content>
                <pro-parameter *ngFor="let parameter of parameters"
                              [parameter]="parameter"
                              [config]="parameter.config"
                              (onChange)="set(parameter, $event)"
                              [disabled]="parameter.disabled"
                ></pro-parameter>
            </div>
            <pro-dialog-actions></pro-dialog-actions>
        </form>
    `
})

export class EntityEditDialogComponent {
    parameters: any;
    object: any;
    form: UntypedFormGroup;
    controls: any = {};
    header: string = 'edit';
    glrInterface: EntityInterface;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        public dialogRef: MatLegacyDialogRef<EntityEditDialogComponent>
    ) {
        const mainField: any = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: {
                required: true,
                validators: { unique : { resource: this.data.entity, field: 'name' } }
            }
        };

        if (this.data.header) this.header = this.data.header;
        if (this.data.entity) this.object = Entity.get({ name: this.data.entity });

        this.glrInterface = this.Entity.get('general_ledger_record');

        this[this.data.entity] = {};

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) {
            this.parameters = [].concat(this.parameters, this.data.parameters);
        }

        for (const parameter of this.parameters) {

            if (parameter.config && parameter.config.control) {
                this.controls[parameter.key] = parameter.config.control;

            }

            if (parameter && parameter.config && !parameter.config.groupInput) {
                parameter.value = this.data.item[parameter.key]
                if (lodash.isFunction(parameter.config.value)) {
                    parameter.value = parameter.config.value();
                }
            } else {
                if (parameter && parameter.config) {
                    const paramConfig = parameter.config
                    parameter.value = {
                        [paramConfig.keyCat1]: this.data.item.recordDbit.accountCategory,
                        [paramConfig.keyCat2]: this.data.item.recordCrdt.accountCategory,
                        [paramConfig.keyType1]: this.data.item.recordDbit.type,
                        [paramConfig.keyType2]: this.data.item.recordCrdt.type,
                    }
                }
            }

        }

        this.form = new UntypedFormGroup(this.controls);
    }

    set(parameter: any, value: any) {
        let property = parameter.key;
        let item = this.data.item;
        let data = {id: item.id};
        let method = 'update';

        // for accounting
        if (parameter.type === 'accounting') {
            this.glrInterface.update({ id: item.recordDbit.id, accountCategory: value.accountCategoryDbit?.id, type: value.typeDbit});
            this.glrInterface.update({ id: item.recordCrdt.id, accountCategory: value.accountCategoryCrdt?.id, type: value.typeCrdt});
            return;
        }

        data[property] = value && value.id ? value.id : value;

        // for files
        if (isArray(value) && value.length && (isFile(value[0]) || property === 'files' || property === 'logo')) {
            data[property] = value.map(mapId);
            method = 'addAssociation';

            value.map(mapId).forEach((id: number) => {
                if (id < 0) {
                    method = 'removeAssociation';
                    data[property] = Math.abs(id);
                }
            });

        }

        // for entity associations
        if (parameter.config?.associations) {
            const arr = item[property];
            const newArr = value;

            if (item[property]?.length > value?.length) {
                method = 'removeAssociation';
                value = lodash.differenceBy(arr, value, 'id')[0];
            } else {
                method = 'addAssociation';
                value = lodash.differenceBy(value, arr, 'id')[0];
            }

            value = value.id;
            data[property] = value;
            item[property] = newArr;
        } else {
            item[property] = value;
        }

        if (this.data.params) Object.assign(data, this.data.params);

        if (this.object) {
            if (this.data.method) {
                this.object.postName(this.data.method)(data);
            } else {
                this.object[method](data);
            }
        }
    }

}
