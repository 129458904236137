export const statsMethods = [
    { name: 'sum' },
    { name: 'average' }
];

export const countableColumns = [
    'duration',
    'price',
    'integer',
    'money',
    'numeric'
];
