import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { isDefined } from '@proman/utils';

const KEYS = [
    'id',
    'name',
    'alias',
    'categories.id',
    'categories.name',
    'parent.id',
];

@Component({
    selector: 'pm-parameter-material-category-filter-setup',
    template: `
        <pro-dialog-title title="edit"></pro-dialog-title>
        <div mat-dialog-content *ngIf="_inited">

            <div fxLayout="row" fxLayoutAlign="start center">
                <pro-btn icon="plus"
                        theme="accent"
                        [tooltip]="'add' | translate"
                        (onClick)="handleAdd()"></pro-btn>
            </div>

            <div *ngFor="let item of filterModel; let $index = index;">

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <pro-select [value]="item.key"
                            [config]="{ label: 'key', key: 'id' }"
                            [options]="keyOptions"
                            (onChange)="handleKeyChange(item, $event)"
                            style="width: 200px"></pro-select>
                    <pro-text-simple [value]="item.value"
                                     [config]="{ label: 'value' }"
                                     (onChange)="handleValueChange(item, $event)"></pro-text-simple>
                    <pro-select [value]="item.parameter"
                            [config]="{ label: 'parameter' }"
                            [options]="parameterOptions"
                            (onChange)="handleParamChange(item, $event)"></pro-select>

                    <pro-btn *ngIf="$index"
                            icon="minus"
                            theme="warn"
                            [tooltip]="'remove_key' | translate"
                            (onClick)="removeKey(item.key)"></pro-btn>

                </div>
            </div>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class ParameterMaterialCategoryFilterSetupDialogComponent {
    filter: any;
    keyOptions: any;
    parameterOptions: any;
    filterModel: any;
    _inited: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private ParametersOptions: ParametersOptionsService,
        private Filter: FilterService,
        private dialogRef: MatLegacyDialogRef<ParameterMaterialCategoryFilterSetupDialogComponent>,

    ) {
        this.keyOptions = KEYS.map((item: string) => ({ id: item, name: this.Filter.translate(item) }));

        if (this.data.materialCategory.filter && this.data.materialCategory.filter.length) {
            try {
                this.filter = JSON.parse(this.data.materialCategory.filter);
            }
            catch (e) {
                this.filter = { name: '' };
            }

        } else {
            this.filter = { name: '' };

        }
    }

    async ngOnInit() {
        this._inited = false;
        this.filterModel = [];

        await this.ParametersOptions.search({ entity: 'parameter', entityParams: {} })
            .then((response: any) => this.parameterOptions = response);

        for (let key in this.filter) {
            this.filterModel.push({ key, value: this.filter[key], parameter: this.getParameter(this.filter[key]) });
        }

        setTimeout(() => {
            this._inited = true;
        });

    }

    confirm() {
        this.dialogRef.close(this.filter);
    }

    getParameter = (value: any): any => {

        if (value && typeof value === 'string' && value.startsWith('p(')) {
            let id = parseInt(value.substring(2));

            return this.parameterOptions.filter((item: any) => item.id === id)[0];
        }
    };

    handleParamChange = (item: any, value: any) => this.handleValueChange(item, `p(${value.id})`);

    handleValueChange = (item: any, value: string) => {
        this.filter[item.key] = value;

        this.ngOnInit();
    };

    handleKeyChange = (item: any, key: string) => {
        const oldKey = item.key;

        this.filter[key] = item.value;
        delete this.filter[oldKey];

        this.ngOnInit();
    };

    handleAdd = () => {
        let key: string;

        for (key of KEYS) {
            if (!isDefined(this.filter[key])) {
                break;
            }
        }

        if (key) {
            this.filter[key] = '';
        }

        this.ngOnInit();

    };

    removeKey = (key: string) => {
        delete this.filter[key];

        this.ngOnInit();
    };

}
