import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'pm-canvas-sign',
    template: `
        <div fxLayout="column" fxLayoutAlign="center center" #element>
            <canvas id="paint-canvas" [width]="width" height="400"></canvas>
            <pro-btn [label]="'save'"
                    [disabled]="isDisabled"
                    theme="accent"
                    (onClick)="save()"></pro-btn>
        </div>


    `,
    styles: [`

        * {
            box-sizing: border-box;
        }

        main {
            border: 1px solid #e0e0e0;
            margin: 0 auto;
            display: flex;
            flex-grow: 1;
        }

        #paint-canvas {
            cursor:crosshair;
            border: 2px solid #333;
            border-radius: 4px;
        }


    `]
})

export class CanvasSignComponent implements OnInit, AfterViewInit {
    @ViewChild('element', { static: true }) element: ElementRef;
    @Output() onSave: EventEmitter<string> = new EventEmitter<string>();

    width: number = 200;
    isDisabled: boolean = true;

    constructor() {
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => this.init(), 100);
    }

    init() {
        if (this.element.nativeElement && document.getElementById('paint-canvas')) {
            this.width = this.element.nativeElement.getBoundingClientRect().width;
            this.initCanvas();
        } else {
            setTimeout(() => this.init(), 50);
        }
    }

    initCanvas() {
        // Definitions
        const canvas = document.getElementById('paint-canvas') as HTMLCanvasElement;
        const context = canvas.getContext('2d');
        let boundings = canvas.getBoundingClientRect();

        // Specifications
        let mouseX = 0;
        let mouseY = 0;
        context.strokeStyle = 'black'; // initial brush color
        context.lineWidth = 1; // initial brush width
        let isDrawing = false;

        // Mouse Down Event
        canvas.addEventListener('mousedown', (event) =>  {
            boundings = canvas.getBoundingClientRect();
            setMouseCoordinates(event);
            isDrawing = true;

            // Start Drawing
            context.beginPath();
            context.moveTo(mouseX, mouseY);
        });

        // Mouse Down Event
        canvas.addEventListener('touchstart', (event) =>  {
            event.preventDefault();
            this.handleTouchStart();
            boundings = canvas.getBoundingClientRect();
            setTouchCoordinates(event);
            isDrawing = true;

            // Start Drawing
            context.beginPath();
            context.moveTo(mouseX, mouseY);
        });

        // Mouse Move Event
        canvas.addEventListener('mousemove', (event) => {

            setMouseCoordinates(event);

            if (isDrawing) {
                context.lineTo(mouseX, mouseY);
                context.stroke();
            }
        });
        canvas.addEventListener('touchmove', (event) => {
            event.preventDefault();
            setTouchCoordinates(event);

            if (isDrawing) {
                context.lineTo(mouseX, mouseY);
                context.stroke();
            }
        });

        // Mouse Up Event
        canvas.addEventListener('mouseup', (event) =>  {

            setMouseCoordinates(event);
            isDrawing = false;
            this.isDisabled = false;
        });
        canvas.addEventListener('touchend', (event) =>  {
          event.preventDefault();
          this.handleTouchEnd();
            isDrawing = false;
            this.isDisabled = false;
        });

        // Handle Mouse Coordinates
        const setMouseCoordinates = (event: MouseEvent) => {
            mouseX = event.clientX - boundings.left;
            mouseY = event.clientY - boundings.top;
        }
        // Handle Mouse Coordinates
        const setTouchCoordinates = (event: TouchEvent) => {
            mouseX = event.touches[0].clientX - boundings.left;
            mouseY = event.touches[0].clientY - boundings.top;
        }
    }

    save() {
        // const imageName = prompt('Please enter image name');
        const canvasDataURL = (document.getElementById('paint-canvas') as HTMLCanvasElement).toDataURL();
        // const a = document.createElement('a');
        // a.href = canvasDataURL;
        // a.download = imageName || 'drawing';
        // a.click();
        this.onSave.emit(canvasDataURL);
    }

    handleTouchStart() {
      const body = document.querySelector('body');

      body.classList.add('DisableScroll');
    }

    handleTouchEnd() {
      const body = document.querySelector('body');

      body.classList.remove('DisableScroll');
    }

}
