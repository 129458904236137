import { Pipe, PipeTransform } from '@angular/core';
import { isDefinedNotNull } from '@proman/utils';
import { PromanTranslationService } from '@proman/services/proman-translation.service';

@Pipe({
    name: 'translate',
    standalone: true,
})

export class TranslatePipe implements PipeTransform {

    constructor(
        private PromanTranslation: PromanTranslationService
    ) {

    }

    transform(translationId: any, params?: any) {
        if (!isDefinedNotNull(translationId)) return '';
        if (typeof translationId === 'number') translationId = translationId.toString();

        return this.PromanTranslation.translate(translationId, params);
    }
}
