import {
    Component,
    SimpleChanges,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ElementRef,
    ViewChild,
    ChangeDetectorRef, OnInit
} from '@angular/core';

@Component({
    selector: 'pm-list',
    template: `
        <div fxLayout="column"
             class="Editable List"
             [ngClass]="{ 'Editable-Disabled': disabled }">
            <div class="Editable-Label">
                {{ config.label | translate }}
                <ng-container *ngIf="config.parameter?.parameter?.required">*</ng-container>
            </div>
            <div #itemsContainer
                 fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 *ngIf="!disabled">
                <div class="List-Item Editable-Item"
                     *ngFor="let item of items; let $index = index;">
                    <div fxLayout="row"
                         fxLayoutAlign="start center">
                        <pm-txt [value]="item.value" [config]="{ label: '' }" (onChange)="update($index, $event)"></pm-txt>
                        <pro-btn (click)="remove($index)"
                                icon="times"
                                [tooltip]="'delete'| translate"
                                theme="warn"></pro-btn>
                    </div>
                </div>
                <div class="List-NewItem"
                     fxLayout="row"
                     fxLayoutAlign="start center">
                    <pro-btn (onClick)="add()"
                            icon="plus"
                            [tooltip]="'add'| translate"
                            theme="accent"></pro-btn>
                </div>
            </div>
            <div fxLayout="row wrap"
                 fxLayoutAlign="start center"
                 class="Editable-Item"
                 *ngIf="disabled">
                <ng-container *ngFor="let item of items;let $last = last">
                    <span>
                        {{ item.value }} <ng-container *ngIf="!$last">,&nbsp;</ng-container>
                    </span>
                </ng-container>
            </div>
        </div>
    `
})

export class ListComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() config: any;
    @Input() disabled: any;
    @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('itemsContainer') itemsContainer: ElementRef;

    items: any[];

    constructor(
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.value) {
            this.setItems(changes.value.currentValue);

        }

        this.cd.markForCheck();

    }

    ngOnInit() {
        this.setItems(this.value);
    }

    setItems = (items: any) => {
        this.items = [].concat(items);
    };

    update(index: any, value: any) {
        this.items[index].value = value;

        this.onUpdate.emit({ item: this.items[index], items: this.items });
    }

    add() {
        let item = { value: '' };

        this.items.push(item);
        // this.onAdd.emit({ item, items: this.items });

        setTimeout(() => {
            let items = this.itemsContainer.nativeElement.querySelectorAll('.List-Item');

            items[items.length - 1].querySelector('input').focus();
        });
    }

    remove(index: any) {
        let removedItem: any = JSON.parse(JSON.stringify(this.items[index]));

        this.items.splice(index, 1);
        this.onRemove.emit(Object.assign({ item: removedItem }, { index, items: this.items }));
    }
}
