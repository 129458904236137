import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { isDefined } from '@proman/utils';
import { Expression } from '@proman/interfaces/entity-interfaces';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ExpressionSelectDialogComponent } from './expression-select-dialog.component';
import { ExpressionEditDialogComponent } from './expression-edit.dialog.component';
import { ConfirmActionDialogComponent } from '@proman/shared-dialogs/dialogs/confirm-action-dialog.component';

@Component({
    selector: 'pro-expression-expression',
    template: `
        @if (!hidden) {
            <div fxLayout="column" [attr.data-expression]="config.label">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    @if (!disabled || disabled && value) {
                        <div class="Editable-Label" style="font-weight: 200; font-size: 17px;" >{{ config.label | translate }}</div>
                    }

                    <div fxLayout="row" fxLayoutAlign="start center">

                        @if (!disabled) {
                            @if (!!function) {
                                <pro-editable-container [label]=""><span>{{ function?.name }}</span></pro-editable-container>
                            }
                            
                            @if (!function && !value && !!fallbackFunction) {
                                <pro-editable-container [label]=""><span>{{ fallbackFunction?.name }}</span></pro-editable-container>
                            }

                            <pro-btn (onClick)="search()"
                                     [size]="'1x'"
                                     icon="search"
                                     [tooltip]="'search' | translate"
                                     theme="accent"></pro-btn>
                            <pro-btn [size]="'1x'"
                                     (onClick)="edit()"
                                     icon="edit"
                                     theme="accent"
                                     [tooltip]="'edit' | translate"></pro-btn>
                            
                            @if ((value || function)) {
                                <pro-btn [size]="'1x'"
                                         (onClick)="restore()"
                                         [icon]="!!fallbackFunction ? 'undo' : 'times'"
                                         theme="warn"
                                         [tooltip]="!!fallbackFunction ? 'back_to_function' : 'back_to_inherited' | translate"></pro-btn>
                            }
                        }
                    </div>
                </div>
                <div fxLayout="column">
                    <ng-container>
                        <pro-expression-humanize [value]="expressionValue()"></pro-expression-humanize>
                    </ng-container>
                </div>
            </div>
        }
    `,
    styles: [`
        pro-editable-container span {
            color: #666;
        }
    `]
})

export class EditableExpressionComponent implements OnInit {
    @Input() value: string|null;
    @Input() function: Expression;
    @Input() item: any;
    @Input() config: any = {};
    @Input() disabled: boolean;
    @Input() hidden: boolean;
    @Input() fallbackFunction: Expression;
    @Input() fallbackValue: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    info: string = '';
    isTitle: boolean;

    constructor(private Dialog: MatLegacyDialog) {}

    ngOnInit() {}

    updateModel = (value: any) => {
        this.onChange.emit(value);
    };

    search() {
        this.Dialog.open(ExpressionSelectDialogComponent).afterClosed().subscribe((result) => {
            if (result) {
                this.updateModel(result);
                this.function = result;
                this.value = null;
            }
        })
    }

    edit() {
        this.Dialog.open(ExpressionEditDialogComponent, { data: { expression: this.expressionValue() }, disableClose: true, width: '80vw' }).afterClosed().subscribe((result) => {
            if (isDefined(result)) {
                this.value = result;
                this.function = null;
                this.updateModel(result);
            }
        })
    }

    restore() {
      this.Dialog.open(ConfirmActionDialogComponent, { data: { question: 'this_will_delete_the_expression' } } ).afterClosed().subscribe((result) => {
          if (result) {
              this.function = null;
              this.value = null;
              this.updateModel(null);
          }
      })
    }

    expressionValue = () => {
        if (this.function) return this.function.body;
        if (this.value) return this.value;
        if (this.fallbackFunction) return this.fallbackFunction.body;
        if (this.fallbackValue) return this.fallbackValue;
        return '';
    }
}
