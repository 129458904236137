import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from '@proman/rxjs-common';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class ObservablesService {
    layout: any;
    pace: Subject<number> = new Subject<number>();
    userPhotoTimestamp: Subject<number> = new Subject<number>();
    dialogTimeStamp: Subject<number> = new Subject<number>();
    windowActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isPortrait$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isPosOrganiser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private BreakPointObserver: BreakpointObserver,
    ) {
        this.layout = {
            value: false,
            subject: new Subject<number>()
        };

        window.addEventListener('resize', this.handleWindowResize);

        this.handleWindowResize();

    }

    layoutToggle(value: boolean = !this.layout.value) {
        this.layout.value = value;
        this.layout.subject.next(new Date().getTime());
    }

    handleWindowResize = () => {
        this.isPortrait$.next(window.innerHeight > window.innerWidth);
    };

}
