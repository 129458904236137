import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableCellInstance } from '@proman/table/components/table-cell.component';

@Component({
  selector: 'pm-table-cell-overlay',
  template: `
        <div [proOverlay]="fieldValue" [proOverlayTemplate]="template">
            <fa *ngIf="data" [name]="config.config.icon || 'search-plus'"></fa>
        </div>

        <ng-template #productionParameters>
          <ng-container *ngIf="data">
            <pm-parameter-view *ngFor="let productionParameter of data.parameters"
                               [parameter]="productionParameter"
                               [config]="{ label: productionParameter.parameter.name }"></pm-parameter-view>
          </ng-container>
        </ng-template>



        <ng-template #eventLogMetadata>
          <ng-container *ngIf="data">
              <div *ngFor="let item of data.metadata | proKeys">
                <b>{{ item.key }}</b>: {{ item.value }}
              </div>
          </ng-container>
        </ng-template>
    `
})

export class TableCellOverlayComponent extends TableCellInstance implements OnInit, AfterViewInit {
  @ViewChild('productionParameters') productionParameters: TemplateRef<any>;
  @ViewChild('eventLogMetadata') eventLogMetadata: TemplateRef<any>;
  item: any;
  config: any;
  data: any;
  type: string;
  fieldValue: { type: string; data: any };

  template: TemplateRef<any>;
  templateConfig: { [key: string]: TemplateRef<any> } = {};

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.fieldValue = this.config.getValue(this.item);
    this.data = this.fieldValue.data;
    this.type = this.fieldValue.type;
  }

  ngAfterViewInit() {
    this.templateConfig['productionParameters'] = this.productionParameters;
    this.templateConfig['eventLogMetadata'] = this.eventLogMetadata;
    this.template = this.templateConfig[this.type];

    this.cd.markForCheck();
  }

}
