import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'proFilter' })

export class FilterPipe implements PipeTransform {
    transform(items: any, filter: any, key: string = 'name'): any {

        if (!items || !filter) {
            return items;

        }

        filter = filter.replace(/\\/g, '');

        return items.filter((item: any) => item[key]?.match(new RegExp(filter, 'i')));

    }
}
