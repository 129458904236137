import { Injectable } from '@angular/core';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { cloneDeep } from '@proman/utils';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { ParameterEntityInterface } from '@proman/resources/parameter';

@Injectable()
export class ParametersService {
    parameters: SelectOption[] = resourcesConfig.parameter.params.types;
    contexts: string[] = resourcesConfig.parameter.params.contexts;
    parameterEntity: ParameterEntityInterface;

    constructor(
        private Entity: Entity,
        private Filter: FilterService,
    ) {
        this.parameterEntity = this.Entity.get('parameter');
    }

    getTypes = () => this.parameters.map((item) => ({ id: item.id, name: this.Filter.translate(item.name) }));

    getContexts = () => this.contexts.map((item) => ({ id: item, name: this.Filter.translate(item) }));

    parseValue(data: any) {
        let out: any;

        try {
            out = JSON.parse(data);

        } catch (e) {
            out = {};

        }

        return out;
    }

    empty = (parameter: any): any => {
        function empty(value: any) {
            return typeof value === 'undefined' || value === null || value === '';
        }

        let overriden: any;
        let output;
        let Parameter = this.parameterEntity;

        if (parameter.parameter) {
            // checking parameter override
            overriden = cloneDeep(parameter.parameter);

            overriden.value = parameter.value;

            output = this.empty(overriden);

        } else {

            if (parameter.required) {

                switch (parameter.type) {

                    case Parameter.STRING:
                    case Parameter.TEXT:
                    case Parameter.BOOLEAN:
                    case Parameter.NUMBER:
                    case Parameter.DROPDOWN:
                    case Parameter.DATETIME:
                    case Parameter.MATERIAL:
                    case Parameter.PRICE:
                    case Parameter.DURATION:
                        output = empty(parameter.value);

                        break;

                    case Parameter.PARAMETER_GROUP:
                        output = false;

                        break;

                    case Parameter.WORKGROUP:
                        output = empty(this.parseValue(parameter.value).workplaceId);

                        break;

                    case Parameter.SUBCONTRACTORS:
                        output = empty(parameter.value);

                        break;

                    case Parameter.MATERIAL_CATEGORY:
                        output = empty(parameter.value);

                        break;

                    case Parameter.LIST:
                        output = (this.parseValue(parameter.value) || []).length === 0;

                        break;

                    default:
                        output = true;

                }

            } else {
                output = false;

            }

        }

        return output;
    };

    // parameter is complex when creating or editing parameter requires separate template logic
    isComplex = (type: string) => {
        let parameterEntity: any = this.parameterEntity;

        switch (type) {

            case parameterEntity.STRING:
            case parameterEntity.BOOLEAN:
            case parameterEntity.LIST:
            case parameterEntity.DATETIME:
            case parameterEntity.MATERIAL:
            case parameterEntity.PRICE:
            case parameterEntity.DURATION:

                return false;

            case parameterEntity.PARAMETER_GROUP:
            case parameterEntity.WORKGROUP:
            case parameterEntity.MATERIAL_CATEGORY:
            case parameterEntity.NUMBER:
            case parameterEntity.DROPDOWN:
            case parameterEntity.SUBCONTRACTORS:

                return true;

            default:

                return true;

        }

    };

    getMaterialCategoryId = (parameter: any) => {
        return this.parseValue(parameter.value).categoryId;
    };

}
