import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

const EMOJI = [
        ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '☺️', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪'],
        ['🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣', '😖', '😫', '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬', '🤯'],
        ['😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤'],
        ['😪', '😵', '🤐', '🥴', '🤢', '🤮', '🤧', '😷', '🤒', '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '☠️️', '👽', '👾', '🤖', '🎃', '😺'],
        ['😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️'],
        ['👍', '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦵', '🦿', '🦶', '👂', '🦻', '👃', '🧠', '🦷', '❤️'],
        ['🧡', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎', '🔴', '🟠', '🟡', '🟢', '🔵', '🟣', '⚫', '⚪', '🟤', '🔺', '🔻', '🔸', '🔹', '🔶', '🔷', '🔳', '🔲', '▪️'],
        ['▫️', '◾️', '◽️', '◼️', '◻️', '🟥', '🟧', '🟨', '🟩', '🟦', '🟪', '⬛', '⬜', '🟫', '🏳', '🏴', '🏁', '🚩', '🏳', '‍🌈', '🏴', '☠️', '🦴', '👀', '👁', '👅'],
        ['👄', '💋', '🩸', '⌚', '📱', '📲', '💻', '⌨️', '🖥', '🖨', '🖱', '🖲', '🕹', '🗜', '💽', '💾', '💿', '📀', '📼', '📷', '📸', '📹', '🎥', '📽', '🎞', '📞'],
        ['☎️', '📟', '📠', '📺', '📻', '🎙', '🎚', '🎛', '🧭', '⏱', '⏲', '⏰', '🕰', '⌛', '⏳', '📡', '🔋', '🔌', '💡', '🔦', '🕯', '🪔', '🧯', '🛢', '💸', '💵'],
        ['💴', '💶', '💷', '💰', '💳', '💎', '⚖️', '🧰', '🔧', '🔨', '⚒', '🛠', '⛏', '🔩', '⚙️', '🧱', '⛓', '🧲', '🔫', '💣', '🧨', '🪓', '🔪', '🗡', '⚔️', '🛡'],
        ['🚬', '⚰️', '⚱️', '🏺', '🔮', '📿', '🧿', '💈', '⚗️', '🔭', '🔬', '🕳', '🩹', '🩺', '💊', '💉', '🩸', '🧬', '🦠', '🧫', '🧪', '🌡', '🧹', '🧺', '🧻', '🚽'],
        ['🚰', '🚿', '🛁', '🛀', '🧼', '🪒', '🧽', '🧴', '🛎', '🔑', '🗝', '🚪', '🪑', '🛋', '🛏', '🛌', '🧸', '🖼', '🛍', '🛒', '🎁', '🎈', '🎏', '🎀', '🎊', '🎉'],
        ['🎎', '🏮', '🎐', '🧧', '✉️', '📩', '📨', '📧', '💌', '📥', '📤', '📦', '🏷', '📪', '📫', '📬', '📭', '📮', '📯', '📜', '📃', '📄', '📑', '🧾', '📊', '📈'],
        ['📉', '🗒', '🗓', '📆', '📅', '🗑', '📇', '🗃', '🗳', '🗄', '📋', '📁', '📂', '🗂', '🗞', '📰', '📓', '📔', '📒', '📕', '📗', '📘', '📙', '📚', '📖', '🔖'],
        ['🧷', '🔗', '📎', '🖇', '📐', '📏', '🧮', '📌', '📍', '✂️', '🖊', '🖋', '✒️', '🖌', '🖍', '📝', '✏️', '🔍', '🔎', '🔏', '🔐', '🔒', '🔓', '🐶', '🐱', '🐭'],
        ['🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯', '🦁', '🐮', '🐷', '🐽', '🐸', '🐵', '🙈', '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥', '🦆', '🦅', '🦉'],
        ['🦇', '🐺', '🐗', '🐴', '🦄', '🐝', '🐛', '🦋', '🐌', '🐞', '🐜', '🦟', '🦗', '🕷', '🕸', '🦂', '🐢', '🐍', '🦎', '🦖', '🦕', '🐙', '🦑', '🦐', '🦞', '🦀'],
        ['🐡', '🐠', '🐟', '🐬', '🐳', '🐋', '🦈', '🐊', '🐅', '🐆', '🦓', '🦍', '🦧', '🐘', '🦛', '🦏', '🐪', '🐫', '🦒', '🦘', '🐃', '🐂', '🐄', '🐎', '🐖', '🐏'],
        ['🐑', '🦙', '🐐', '🦌', '🐕', '🐩', '🦮', '🐈', '🐓', '🦃', '🦚', '🦜', '🦢', '🦩', '🕊', '🐇', '🦝', '🦨', '🦡', '🦦', '🦥', '🐁', '🐀', '🐿', '🦔', '🐾'],
        ['🐉', '🐲', '🌵', '🎄', '🌲', '🌳', '🌴', '🌱', '🌿', '☘️', '🍀', '🎍', '🎋', '🍃', '🍂', '🍁', '🍄', '🐚', '🌾', '💐', '🌷', '🌹', '🥀', '🌺', '🌸', '🌼'],
        ['🌻', '🌞', '🌝', '🌛', '🌜', '🌚', '🌕', '🌖', '🌗', '🌘', '🌑', '🌒', '🌓', '🌔', '🌙', '🌎', '🌍', '🌏', '🪐', '💫', '⭐', '🌟', '✨', '⚡️', '☄️', '💥'],
        ['🔥', '🌪', '🌈', '☀️', '🌤', '⛅', '🌥', '☁️', '🌦', '🌧', '⛈', '🌩', '🌨', '❄️', '☃️', '⛄', '🌬', '💨', '💧', '💦', '☔️', '☂️', '🌊', '🧳', '🌂', '☂️'],
        ['🧵', '🧶', '👓', '🕶', '🥽', '🥼', '🦺', '👔', '👕', '👖', '🧣', '🧤', '🧥', '🧦', '👗', '👘', '🥻', '🩱', '🩲', '🩳', '👙', '👚', '👛', '👜', '👝', '🎒'],
        ['👞', '👟', '🥾', '🥿', '👠', '👡', '🩰', '👢', '👑', '👒', '🎩', '🎓', '🧢', '⛑', '💄', '💍', '💼', '⚽', '🏀', '🏈', '⚾', '🥎', '🎾', '🏐', '🏉', '🥏'],
        ['🎱', '🪀', '🏓', '🏸', '🏒', '🏑', '🥍', '🏏', '🥅', '⛳', '🪁', '🏹', '🎣', '🤿', '🥊', '🥋', '🎽', '🛹', '🛷', '⛸', '🥌', '🎿', '⛷', '🏂', '🪂', '🏋'],
        ['🏆', '🥇', '🥈', '🥉', '🏅', '🎖', '🏵', '🎗', '🎫', '🎟', '🎪', '🤹', '🎭', '🩰', '🎨', '🎬', '🎤', '🎧', '🎼', '🎹', '🥁', '🎷', '🎺', '🎸', '🪕', '🎻'],
        ['🎲', '♟', '🎯', '🎳', '🎮', '🎰', '🧩', '🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝', '🍅', '🍆', '🥑'],
        ['🥦', '🥬', '🥒', '🌶', '🌽', '🥕', '🧄', '🧅', '🥔', '🍠', '🥐', '🥯', '🍞', '🥖', '🥨', '🧀', '🥚', '🍳', '🧈', '🥞', '🧇', '🥓', '🥩', '🍗', '🍖', '🦴'],
        ['🌭', '🍔', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🌯', '🥗', '🥘', '🥫', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱', '🥟', '🦪', '🍤', '🍙', '🍚', '🍘', '🍥', '🥠'],
        ['🥮', '🍢', '🍡', '🍧', '🍨', '🍦', '🥧', '🧁', '🍰', '🎂', '🍮', '🍭', '🍬', '🍫', '🍿', '🍩', '🍪', '🌰', '🥜', '🍯', '🥛', '🍼', '☕️', '🍵', '🧃', '🥤'],
        ['🍶', '🍺', '🍻', '🥂', '🍷', '🥃', '🍸', '🍹', '🧉', '🍾', '🧊', '🥄', '🍴', '🍽', '🥣', '🥡', '🥢', '🧂']
];

@Component({
    selector: 'pm-emoji',
    template: `        
        <div class="Emoji Padding"
             [ngClass]="{ 'Editable': !(noPadding || config?.noPadding) }"
             fxLayout="row"
             fxLayoutAlign="start center">
            <div class="Emoji-input"
                 [ngStyle]="{ 'margin-right': '10px' }"
                 (click)="setEmoji($event)">{{ value }}</div>
            <div class="Editable-Label" *ngIf="!noLabel">
                {{ (config?.label || 'color') | translate }}
            </div>
        </div>
        
        <div class="EmojiPickerOverlay" *ngIf="isVisible" (click)="isVisible = false;" #container>
            <div class="EmojiPickerContainer" [ngStyle]="{ left: left, top: top }" (click)="$event.stopPropagation();">
                <div fxLayout="row" *ngFor="let emojiRow of emoji">
                    <div class="EmojiPickerItem"
                         *ngFor="let emoji of emojiRow"
                         (click)="handleClick(emoji)"
                    >{{ emoji }}</div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .Emoji {
            padding-left: 4px;
        }
        .Emoji-input {
            width: 20px;
            height:20px;
            border-radius: 5px;
            border: 1px solid #464646;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }

        .EmojiPickerContainer {
            background: rgb(255,255,255);
            padding: 5px;
            border-radius: 5px;
        }

        .EmojiPickerOverlay {
            overflow: scroll;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*1000 is dialog overlay index*/
            z-index: 1001; 
            background: rgba(0, 0, 0, 0.25);
        }
        
        .EmojiPickerContainer {
            position: absolute;
            z-index: 9999;
        }
        
        .EmojiPickerItem {
            width: 30px;
            height: 30px;
            border-radius: 2px;
            margin: 2px;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
        }
        
    `]
})

export class EmojiComponent implements OnInit {
    @Input() value: any;
    @Input() noPadding: boolean;
    @Input() noLabel: boolean;
    @Input() disabled: boolean;
    @Input() config: any = {};
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('container') container: ElementRef;
    emoji: any = EMOJI;
    isVisible: boolean = false;
    left: string;
    top: string;

    constructor() {

    }

    ngOnInit() {
        // this.value = this.value ? '#' + this.value : '#ffffff';
    }

    setEmoji($event: MouseEvent) {
        if ($event.shiftKey) {
            this.value = null;
            this.onChange.emit(null);
        } else {
            if (!this.onChange.observers.length || this.disabled) return;

            this.isVisible = true;

            let x: number = $event.clientX;
            let y: number = $event.clientY;

            const windowWidth: number = window.innerWidth;
            const windowHeight: number = window.innerHeight;

            if ((x + 250) > windowWidth) {
                x = windowWidth - 250;
            }

            if ((y + 250) > windowHeight) {
                y = windowHeight - 250;
            }

            this.left = `${x}px`;
            this.top = `${y}px`;

            setTimeout(() => {
                const body = document.querySelector('body');
                const overlay = document.querySelector('.EmojiPickerOverlay');

                if (body && overlay) body.appendChild(overlay);

            });
        }
    }

    handleClick(emoji: any) {
        this.value = emoji;
        this.onChange.emit(emoji);
        this.isVisible = false;
    }

}
